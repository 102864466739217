import { useTheme } from "@emotion/react";
import { CSSProperties } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";

export default function ItemBadge({
  isAction,
  value,
  customClass,
}: {
  value: string;
  isAction?: boolean;
  customClass?: string;
}) {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  return (
    <div className={`badge ${customClass}`}>
      <span>{value}</span>
      {isAction && (
        <BsPencilSquare
          size={16}
          style={{ cursor: "pointer" }}
          fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
        />
      )}
    </div>
  );
}
