import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(() => ({
  select: {
    '& .theme-select-custom__control': {
      transition: 'none',
    },

    '& .theme-select-custom__menu': {
      borderRadius: '10px !important',
      overflow: 'hidden',
    },

    '& .theme-select-custom__menu-list': {
      borderRadius: '0px 0px 10px 10px',

      '& .theme-select-custom__menu-notice': {
        color: 'red',
      },
    },

    '& .MuiOutlinedInput-root': {
      boxSizing: 'border-box',
      borderRadius: '10px !important',
      overflow: 'hidden',
      background: 'var(--input-background)',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
      outline: 'none !important',
    },

    '& .MuiInputBase-root': {
      minWidth: '100% !important',
    },

    '& .theme-select-custom__option': {
      // borderBottom: '1px solid var(--color-border)',
    },
  },
}));

export default styles;
