import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import Trading from "src/features/Trading/Trading";
import * as S from "./style";
import OrderbookTrade from "src/features/OrderbookTrade/OrderbookTrade";

const tabList = [
  { label: "Chart", value: "0" },
  { label: "Order book", value: "1" },
  { label: "Market trades", value: "2" },
];

const DashboardMobile = () => {
  const [value, setValue] = React.useState<string>("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <S.Container>
      <Box sx={{ borderBottom: 1, borderColor: "#E9E9EB", marginBottom: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="lab API tabs example"
        >
          {tabList.map((tab, index) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </Tabs>
      </Box>
      {value === "0" && (
        <div className="dashboard">
          <Trading />
        </div>
      )}
      {value !== "0" && <OrderbookTrade tabCurrent={value} />}
    </S.Container>
  );
};

export default DashboardMobile;
