import { DateRange } from "@material-ui/pickers";
import { Pagination } from "@mui/material";
import classNames from "classnames/bind";
import moment from "moment";
import React, { useEffect, useRef, useState, useCallback } from "react";
// import { noData, noDataDark, usdmProfile, usdmProfileDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import DatePicker from "src/features/Orders/DatePickerCustom/DatePicker";
import { Instrument } from "src/services/instrument";
import { formatTimeDay, formatTimeHour } from "src/helpers/stringFormatter";
import { getTransactionHistory } from "src/services/transaction-history";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import FilterMobile from "../FilterMobile";
import OrderTitle from "../OrderTitle";
import { SelectFilterAssetOrder } from "../SelectOrderAsset/index";
import { SelectFilterTypeOrder } from "../SelectOrderType/index";
import { typeSelectTrans } from "../constant";
import styles from "./index.module.scss";
import { useWindowSize } from "src/hooks/useScreen";
import { formatOrderEnum } from "src/helpers/numberFormatter";
import Loading from "src/components/Loading";
import { THEME_MODE } from "src/interfaces/theme";
import { TransactionType } from "src/features/PositionAndHistory/constant";
import TooltipNumber from "src/components/cores/Tooltip";
import { TypeTrade } from "src/constants/common.constants";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import { useTranslation } from "react-i18next";
import TextNumber from "src/components/cores/TextNumber";
import { BsFillInboxesFill } from "react-icons/bs";

const cx = classNames.bind(styles);

type typeDataTable = {
  amount: string;
  asset: null | string;
  symbol: string;
  time: string | number | null | undefined;
  type: string;
};

type AssetType = {
  name: string;
  value: string;
};
const RECORDS_PER_PAGE = 6;

const TransactionHistoryPage: React.FC = () => {
  const { t } = useTranslation("common");
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const dispatch = useAppDispatch();
  const currentInstrument: Instrument | undefined = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const currentUser = useAppSelector((state) => state.account.account);
  const { transactionHistory, loading } = useAppSelector(
    (state) => state.transactionHistory
  );
  const [tabActive, setTabActive] = useState(0);
  const [dataTable, setDataTable] = useState<typeDataTable[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setIsReset] = useState<number>(0);
  const [valueTypeFilter, setValueTypeFilter] = useState<string>("");
  const [valueAssetFilter, setValueAssetFilter] = useState<string>("");
  const size = useWindowSize();
  const [totalItem, setTotalItem] = useState<number>(1);
  const account = useSelector((state: RootState) => state.account.account);
  const [tab, setTab] = useState(TypeTrade.USD_M);
  const [listAssets, setListAssets] = useState<AssetType[]>([]);

  const handleListAsset = useCallback(() => {
    const assetFilter = account?.filter((item: any) => {
      const asset = item?.asset?.toUpperCase();
      const coin = asset !== "USDT" && asset !== "USD";
      return tab === TypeTrade.USD_M ? !coin : coin;
    });
    const defaultAsset = {
      name: "order.all",
      value: "ALL",
    };
    const listAsset: AssetType[] = [
      defaultAsset,
      ...assetFilter.map((item) => ({
        name: item?.asset?.toUpperCase(),
        value: item?.asset?.toUpperCase(),
      })),
    ];
    setListAssets(listAsset);
    setIsReset(1);
  }, [tab, account]);

  useEffect(() => {
    handleListAsset();
  }, [handleListAsset]);

  // const convert = (str: any) => {
  //   const date = new Date(str);
  //   const month = ('0' + (date.getMonth() + 1)).slice(-2);
  //   const day = ('0' + date.getDate()).slice(-2);
  //   return [date.getFullYear(), month, day].join('-');
  // };

  // const DataTable = [
  //   {
  //     time: '2023-02-02T10:55:49.000Z',
  //     type: 'TRADINGFEE',
  //     amount: '1.00000000',
  //     symbol: null,
  //     asset: 'USDT',
  //   },
  //   {
  //     time: '2023-02-02T10:55:49.000Z',
  //     type: 'TRADINGFEE',
  //     amount: '1.00000000',
  //     symbol: null,
  //     asset: 'USDT',
  //   },
  // ];

  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const [isChangeSelectData, setIsChangeSelectData] = useState<boolean>(false);
  const selectedDateRef = useRef<DateRange<Date>>([null, null]);

  const checkUserSelectDatePicker = () => {
    if (
      moment(selectedDateRef.current[0]).format("YYYY-MM-DD") !==
        moment(selectedDate[0]).format("YYYY-MM-DD") ||
      moment(selectedDateRef.current[1]).format("YYYY-MM-DD") !==
        moment(selectedDate[1]).format("YYYY-MM-DD")
    ) {
      if (selectedDate[0] && selectedDate[1]) {
        setIsChangeSelectData(true);
      } else {
        setIsChangeSelectData(false);
      }
    } else {
      setIsChangeSelectData(false);
    }
  };

  const handleOpenDatePicker = () => {
    selectedDateRef.current = selectedDate;
  };

  const handleCloseDatePicker = () => {
    checkUserSelectDatePicker();
  };

  useEffect(() => {
    checkUserSelectDatePicker();
  }, [selectedDate]);

  const handleChangeDate = (value: DateRange<Date>) => {
    setSelectedDate(value);
  };

  useEffect(() => {
    if (selectedDate) {
      setCurrentPage(1);
      setIsChangeSelectData(true);
    }
  }, [selectedDate, tab]);

  const handleFilter = () => {
    setIsChangeSelectData(true);
    setCurrentPage(1);
    setIsReset(0);
  };
  const getDataTransactionHistory = () => {
    if (currentInstrument?.symbol && currentUser[0]?.id) {
      if (isOpenDatePicker) return;
      if (!isOpenDatePicker && !isChangeSelectData) return;

      dispatch(
        getTransactionHistory({
          page: currentPage,
          size: RECORDS_PER_PAGE,
          endTime: selectedDate[1]
            ? moment(selectedDate[1]).endOf("day").utc().toDate().getTime()
            : moment().endOf("day").utc().toDate().getTime(),
          startTime: selectedDate[0]
            ? moment(selectedDate[0]).startOf("day").utc().toDate().getTime()
            : moment()
                .subtract(7, "days")
                .startOf("day")
                .utc()
                .toDate()
                .getTime(),
          asset:
            valueAssetFilter !== "ALL" && !isReset ? valueAssetFilter : null,
          type: valueTypeFilter !== "ALL" && !isReset ? valueTypeFilter : null,
          contractType: tab,
        })
      );
    }
  };

  const searchFilterMobile = () => {
    setCurrentPage(1);
    setIsReset(0);
    setIsChangeSelectData(true);
    getDataTransactionHistory();
  };

  useEffect(() => {
    getDataTransactionHistory();
    // if (currentInstrument?.symbol && currentUser[0]?.id) {
    //   if (isOpenDatePicker) return;
    //   if (!isOpenDatePicker && !isChangeSelectData) return;

    //   dispatch(
    //     getTransactionHistory({
    //       page: currentPage,
    //       size: RECORDS_PER_PAGE,
    //       endTime: selectedDate[1]
    //         ? moment(selectedDate[1]).endOf('day').utc().toDate().getTime()
    //         : moment().endOf('day').utc().toDate().getTime(),
    //       startTime: selectedDate[0]
    //         ? moment(selectedDate[0]).startOf('day').utc().toDate().getTime()
    //         : moment().subtract(7, 'days').startOf('day').utc().toDate().getTime(),
    //       asset: valueAssetFilter !== 'ALL' && !isReset ? valueAssetFilter : null,
    //       type: valueTypeFilter !== 'ALL' && !isReset ? valueTypeFilter : null,
    //       contractType: tab,
    //     }),
    //   );
    // }
  }, [
    isOpenDatePicker,
    isChangeSelectData,
    currentInstrument?.symbol,
    currentUser[0]?.id,
    currentPage,
    // selectedDate,
    valueAssetFilter,
    valueTypeFilter,
    dispatch,
    // isReset,
    tab,
  ]);

  useEffect(() => {
    setDataTable(transactionHistory?.data?.list);
  }, [transactionHistory]);

  useEffect(() => {
    if (transactionHistory.data?.list) {
      setTotalItem(
        Math.ceil(transactionHistory.data?.count / RECORDS_PER_PAGE)
      );
    }
  }, [transactionHistory.data?.list, currentUser[0]?.id]);

  useEffect(() => {
    history.pushState(tab, "");
  }, [tab]);

  const renderType = (transaction: typeDataTable) => {
    if (
      transaction.type === TransactionType.DEPOSIT ||
      transaction.type === TransactionType.WITHDRAWAL
    ) {
      return t("order.transfer");
    }
    if (transaction.type === TransactionType.REALIZED_PNL) {
      return t("order.realized_pnl");
    }

    return t(formatOrderText(transaction.type));
  };

  const renderTime = (order: any) => {
    if (!order.time) return "--";
    return (
      <div className="time">
        <div className="day">{formatTimeDay(order.time)}</div>
        <div className={cx("describe")}>{formatTimeHour(order.time)}</div>
      </div>
    );
  };

  const renderAmount = (transaction: typeDataTable) => {
    if (transaction.type === TransactionType.WITHDRAWAL) {
      // return <TooltipNumber defaultValue={`-${transaction.amount}`} characters={20} decimal={8} />;
      return (
        <TextNumber defaultValue={`-${transaction.amount}`} isCoinM={false} />
      );
    }

    // return <TooltipNumber defaultValue={transaction.amount} characters={20} decimal={8} />;
    return <TextNumber defaultValue={transaction.amount} isCoinM={false} />;
  };

  const renderSymbol = (transaction: typeDataTable) => {
    if (
      transaction.type === TransactionType.DEPOSIT ||
      transaction.type === TransactionType.WITHDRAWAL
    ) {
      return transaction.asset;
    }

    return getSymbolName(transaction.symbol);
  };

  return (
    <div className={cx("orders-wrapper")}>
      <div className={cx("open-order-details")}>
        {/* {TAB_DETAILS.map((item, i) => (
          <div
            key={i}
            className={cx('tab-details', tabActive === i && 'tab-details-active')}
            onClick={() => setTabActive(i)}
          > 
            {item}
          </div>
        ))} */}
        <div className={cx("tabs-area")}>
          <div
            onClick={() => {
              setTab(TypeTrade.USD_M);
              setIsReset(1);
            }}
            className={cx(
              "tab-details",
              tab === TypeTrade.USD_M && "tab-details-active"
            )}
          >
            USDⓈ-M
          </div>
          <div
            onClick={() => {
              setTab(TypeTrade.COIN_M);
              setIsReset(1);
            }}
            className={cx(
              "tab-details",
              tab === TypeTrade.COIN_M && "tab-details-active"
            )}
          >
            COIN-M
          </div>
        </div>

        {tabActive === 0 && (
          <>
            <div className={cx("filter-area")}>
              <div
                className={cx(
                  size[0] <= 1440 ? "filter-row-responsive" : "filter-row"
                )}
              >
                <SelectFilterAssetOrder
                  isReset={isReset}
                  handleFilter={handleFilter}
                  dataList={listAssets}
                  placeHolder={t("order.asset")}
                  customSelect={cx("width")}
                  className={cx("select-asset")}
                  setValueAssetFilter={setValueAssetFilter}
                  width={200}
                />
                <SelectFilterTypeOrder
                  isReset={isReset}
                  handleFilter={handleFilter}
                  dataList={typeSelectTrans}
                  placeHolder={t("order.type")}
                  customSelect={cx("width")}
                  className={cx("select-type")}
                  setValueTypeFilter={setValueTypeFilter}
                  label={"Transaction History"}
                  width={200}
                />
              </div>
              <div
                className={cx(
                  size[0] <= 1440 ? "filter-date-responsive" : "filter-date"
                )}
              >
                <DatePicker
                  isReset={isReset}
                  setIsReset={setIsReset}
                  handleChangeDate={handleChangeDate}
                  setIsOpenDatePicker={setIsOpenDatePicker}
                  handleOpenDate={handleOpenDatePicker}
                  handleCloseDate={handleCloseDatePicker}
                />
              </div>

              <div className={cx("filter-reset")}>
                <button
                  className={cx("btn-reset")}
                  onClick={() => {
                    setIsReset(1);
                    setCurrentPage(1);
                  }}
                >
                  {t("order.reset")}
                </button>
              </div>
            </div>
            <div className={cx("filter-area-mobile")}>
              <SelectFilterAssetOrder
                isReset={isReset}
                handleFilter={handleFilter}
                dataList={listAssets}
                placeHolder={t("order.asset")}
                customSelect={cx("width")}
                className={cx("select-asset")}
                setValueAssetFilter={setValueAssetFilter}
                width={200}
              />
              <FilterMobile
                onReset={() => {
                  setIsReset(2);
                }}
                onSearch={searchFilterMobile}
              >
                <div className={cx("filter-mobile-content")}>
                  <SelectFilterAssetOrder
                    isReset={isReset}
                    handleFilter={handleFilter}
                    dataList={listAssets}
                    placeHolder={t("order.asset")}
                    customSelect={cx("width")}
                    className={cx("select-asset")}
                    setValueAssetFilter={setValueAssetFilter}
                    width={200}
                  />
                  <SelectFilterTypeOrder
                    isReset={isReset}
                    handleFilter={handleFilter}
                    dataList={typeSelectTrans}
                    placeHolder={t("order.type")}
                    customSelect={cx("width")}
                    className={cx("select-type")}
                    setValueTypeFilter={setValueTypeFilter}
                    label={"Transaction History"}
                    width={200}
                  />
                  <DatePicker
                    isReset={isReset}
                    setIsReset={setIsReset}
                    handleChangeDate={handleChangeDate}
                    setIsOpenDatePicker={setIsOpenDatePicker}
                    handleOpenDate={handleOpenDatePicker}
                    handleCloseDate={handleCloseDatePicker}
                  />
                </div>
              </FilterMobile>
            </div>
            {
              <div style={{ position: "relative" }}>
                <div className={cx("table-content")}>
                  <div
                    className={cx("no-data")}
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "40%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {!loading && dataTable.length === 0 && (
                      <>
                        <BsFillInboxesFill
                          fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                          style={{ zoom: 5 }}
                        />
                        <div className={cx("no-data-desc")}>
                          {t("order.no_data")}
                        </div>
                      </>
                    )}

                    {loading && <Loading />}
                  </div>

                  <table className={cx("dex_table")}>
                    <thead>
                      <tr className={cx("table-header")}>
                        <th>{t("order.time")}</th>
                        <th>{t("order.type")}</th>
                        <th>{t("order.amount")}</th>
                        <th>{t("order.asset")}</th>
                        <th>{t("order.symbol")}</th>
                      </tr>
                    </thead>
                    <tbody
                      className={cx("dex_table__scrollbar", "overflow-tbody")}
                      style={{ maxHeight: 210 }}
                    >
                      {loading && <div style={{ height: 340 }}></div>}
                      {!loading && dataTable.length === 0 && (
                        <div style={{ height: 340 }}></div>
                      )}

                      {!loading && dataTable.length !== 0 && (
                        <>
                          {dataTable.map((data, index) => (
                            <tr className={cx("row-content")} key={index}>
                              <td className={cx("text-value")}>
                                {/* {data.time ? formatTime(data.time) : "-"} */}
                                {renderTime(data)}
                              </td>
                              <td className={cx("text-value")}>
                                {data.type ? renderType(data) : "-"}
                              </td>
                              <td className={cx("text-value")}>
                                {renderAmount(data)}
                              </td>
                              <td className={cx("text-value")}>
                                {data.asset ? data.asset : "-"}
                              </td>
                              <td className={cx("text-value")}>
                                {renderSymbol(data)}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={cx("table-content-mobile")}>
                  <div
                    className={cx("no-data")}
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "40%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {!loading && dataTable.length === 0 && (
                      <>
                        <BsFillInboxesFill
                          fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                          style={{ zoom: 2 }}
                        />
                        <div className={cx("no-data-desc")}>
                          {t("order.no_data")}
                        </div>
                      </>
                    )}

                    {loading && <Loading />}
                  </div>
                  {dataTable.map((data: any, index: number) => (
                    <div className={cx("content-item-mobile")} key={index}>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>{t("order.time")}</div>
                        <div className={cx("text-value")}>
                          {renderTime(data)}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>{t("order.type")}</div>
                        <div className={cx("text-value")}>
                          {data.type ? renderType(data) : "-"}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>{t("order.amount")}</div>
                        <div className={cx("text-value")}>
                          {renderAmount(data)}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>{t("order.asset")}</div>
                        <div className={cx("text-value")}>
                          {data.asset ? data.asset : "-"}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>{t("order.symbol")}</div>
                        <div className={cx("text-value")}>
                          {renderSymbol(data)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {!loading && totalItem > 1 && (
                  <div className={cx("pagination")}>
                    <Pagination
                      page={currentPage}
                      onChange={(e, page) => setCurrentPage(page)}
                      count={totalItem}
                      variant="outlined"
                      shape="rounded"
                      className={cx("pagination-custom")}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          color: "var(--color-value-1) !important",
                        },
                        "& .Mui-selected": {
                          backgroundColor:
                            "var(--color-background-pagination) !important",
                          border:
                            "1px solid var(--color-outline-pagination) !important",
                          color: "var(--color-text-pagination) !important",
                          borderRadius: "5px !important",
                        },
                        "& .MuiPagination-ul": {
                          flexWrap: "nowrap",
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};

export default TransactionHistoryPage;
