import React, { useCallback, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "src/components/cores/SelectToggle/index.module.scss";
import Icon from "src/assets/icon/icon.png";
// import { dropIcon, iconDropDark, linkIcon } from "src/assets/icon";
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import useOnClickOutside from "src/hooks/useClickOutside";
import { BsCaretDownFill, BsArrowRight } from "react-icons/bs";

const cx = classnames.bind(styles);

export type TOption = {
  label: string;
  value?: string | number;
  icon?: string;
  onAction?: () => void;
};

interface Props {
  title?: string;
  iconTitle?: string;
  options?: TOption[];
  isIcon?: boolean;
}

const SelectBoxToggle: React.FC<Props> = ({
  title,
  iconTitle,
  options,
  isIcon,
}) => {
  const rfTitle = useRef<HTMLDivElement>(null);
  const rfUnderPanel = useRef<HTMLUListElement>(null);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [openSelect, setOpenSelect] = useState(false);
  const el = useRef(null);

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const handleOnTitleMouseEntered = useCallback(() => {
    // if (!rfTitle.current) return;
    // if (!rfUnderPanel.current) return;
    // if (
    //   window.innerWidth - rfTitle.current.getBoundingClientRect().right <
    //   rfUnderPanel.current.getBoundingClientRect().width + 20
    // ) {
    //   rfUnderPanel.current.style.right = '0px';
    // }
  }, []);

  return (
    <div
      onClick={() => setOpenSelect(!openSelect)}
      className={cx("select-wrapper")}
      ref={el}
    >
      <ul>
        <li onMouseEnter={handleOnTitleMouseEntered}>
          {isIcon ? (
            <div className={cx("select-title")} ref={rfTitle}>
              <img src={iconTitle} />
            </div>
          ) : (
            <div className={cx("select-title")} ref={rfTitle}>
              {title}
              <BsCaretDownFill
                fill={theme === THEME_MODE.LIGHT ? "back" : "white"}
                style={{ zoom: 0.75 }}
              />
            </div>
          )}

          {openSelect && (
            <ul className={cx("option")} ref={rfUnderPanel}>
              {options?.map((option, index) => (
                <div
                  key={index}
                  className={cx("option-value")}
                  onClick={option.onAction}
                >
                  {option.icon && (
                    <img
                      style={{ marginLeft: "15px" }}
                      width={25}
                      height={25}
                      src={option.icon}
                    />
                  )}
                  {option.label && (
                    <div className={cx("option-text")}>{option.label}</div>
                  )}
                  <span
                    className="icon icon-arrow7"
                    style={{ color: "#00ddb3" , margin:"0 12px"}}
                  />
                </div>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default SelectBoxToggle;
