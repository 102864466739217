import { PriceTypeLabel } from "../PositionAndHistory/dialog/EntierPositionDialog";

export enum OrderType {
  market = 'MARKET',
  limit = 'LIMIT',
  stopMarket = 'STOP_MARKET',
  stopLimit = 'STOP_LIMIT',
  trailingStop = 'TRAILING_STOP',
  postOnly = 'POST_ONLY',
  takeProfitLimit = 'TAKE_PROFIT_LIMIT',
  takeProfitMarket = 'TAKE_PROFIT_MARKET',
}

export enum StopLabel {
  stopMarket = 'order.stop_market',
  stopLimit = 'order.stop_limit',
  trailingStop = 'order.trailing_stop',
  postOnly = 'order.post_only',
  takeProfitLimit = 'Take Profit Limit',
  takeProfitMarket = 'Take Profit Market',
}

export const STOP_TYPE = [
  { value: OrderType.stopLimit, label: StopLabel.stopLimit, key: 'stop_limit' },
  { value: OrderType.stopMarket, label: StopLabel.stopMarket, key: 'stop_market' },
  { value: OrderType.trailingStop, label: StopLabel.trailingStop, key: 'trailing_stop' },
  { value: OrderType.postOnly, label: StopLabel.postOnly, key: 'post_only' },
  // { value: OrderType.takeProfitLimit, label: StopLabel.takeProfitLimit },
  // { value: OrderType.takeProfitMarket, label: StopLabel.takeProfitMarket },
];

export const SYMBOL_TYPE = [
  { value: 'BTC', label: 'BTC', key: 'BTC' },
  { value: 'USDT', label: 'USDT', key: 'USDT' },
];

export const OPTIONS_ACTION = [
  { value: 0, label: 'market 1' },
  { value: 1, label: 'market 2' },
];

export enum WorkingTypeLabel {
  last = 'Last',
  mark = 'Mark',
}

export enum WorkingTypeValue {
  last = 'LAST',
  mark = 'ORACLE',
}

export const WorkingType = [
  { value: WorkingTypeValue.mark, label: 'order.mark', key: 'mark' },
  { value: WorkingTypeValue.last, label: 'order.last', key: 'last' },
];

export const TIME_IN_FORCE = [
  { value: 'GTC', label: 'GTC' },
  { value: 'IOC', label: 'IOC' },
  { value: 'FOK', label: 'FOK' },
];

export const dataOptionInMarginInformationForm = [
  { value: 'USDT', label: 'USDT' },
  { value: 'USD', label: 'USD' },
];

export enum OrderSide {
  buy = 'BUY',
  sell = 'SELL',
}

export enum OrderOption {
  none,
  tpsl,
  reduceOnly,
}

export enum TriggerLabel {
  oracle = 'Oracle',
  last = 'Last',
  index = 'Index',
}

export enum TriggerValue {
  oracle = 'ORACLE',
  last = 'LAST',
  index = 'INDEX',
}

export const OPTIONS_TRIGGER = [
  { value: TriggerValue.oracle, label: TriggerLabel.oracle },
  { value: TriggerValue.last, label: TriggerLabel.last },
  { value: TriggerValue.index, label: TriggerLabel.index },
];

export const USDT = 'USDT';
export const USD = 'USD';
