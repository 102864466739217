import { useTranslation } from "react-i18next";
import { IOrder } from "src/interfaces/order";

export default function RenderReduceOnly({
  order,
  customText,
}: {
  order: IOrder;
  customText?: string;
}) {
  const { t } = useTranslation("common");
  return order.isReduceOnly ? (
    <span className={customText}>{t("order.yes")}</span>
  ) : (
    <span className={customText}>{t("order.no")}</span>
  );
}
