import { useTranslation } from "react-i18next";
import { OrderType } from "src/features/PositionAndHistory/constant";
import { IOrder } from "src/interfaces/order";
import Quantity from "./Quantity";
import { Instrument } from "src/services/instrument";
import { ITradingRuleCache } from "src/services/masterdataFuture";

export default function RenderAmount({
  order,
  instruments,
  tradingRulesCache,
  isCoinM,
}: {
  order: IOrder;
  instruments: Instrument[];
  tradingRulesCache: ITradingRuleCache[];
  isCoinM: boolean;
}) {
  const { t } = useTranslation("common");
  if (!order.parentOrderId) {
    if (
      order.tpSLType === OrderType.takeProfitMarket ||
      (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
    ) {
      return t("order.close_position");
    }
  }

  return (
    <Quantity
      order={order}
      instruments={instruments}
      tradingRulesCache={tradingRulesCache}
      isCoinM={isCoinM}
    />
  );
}
