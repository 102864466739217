import { useEffect, useState } from "react";

const handleTime = (time: number) => {
  return time >= 10 ? time : "0" + time;
};
const getReturnValues = (countDown: any) => {
  //   const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [handleTime(hours), handleTime(minutes), handleTime(seconds)];
};

const useCountdown = (targetDate: any) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return !targetDate || countDown < 0 || !countDown ? ['00', '00', '00'] : getReturnValues(countDown);
};

// const useNewCountdown = () => {
//   const newDate = new Date();
//   const hourNow = newDate.getHours();
//   const TimeTo: number[] = [23, 19, 15, 11, 7, 3];
//   const anHour = 3600000;

//   const [future, setFuture] = useState(newDate.getTime() + 6 * 60 * 1000);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       for (let i = 0; i < TimeTo.length; i++) {
//         // console.log("Walking east one i", i, TimeTo[i]);
//         if (newDate.getHours() === 23) {
//           setFuture(
//             Math.floor(newDate.getTime() / anHour) * anHour + anHour * 4
//           );
//         } else if (
//           TimeTo[i] > hourNow && TimeTo[i + 1]&&
//           hourNow >= TimeTo[i + 1]
//         ) {
//           setFuture(
//             Math.floor(newDate.getTime() / anHour) * anHour +
//               anHour * (TimeTo[i] - hourNow)
//           );
//         }
//       }
//     }, 100);

//     return () => clearInterval(interval);
//   }, []);

//   return getReturnValues(future - new Date().getTime());
// };

export { useCountdown };
