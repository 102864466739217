import React from "react";
// import ChartIndex from "src/features/TradingViewChart/Components/Chart/ChartIndex";
import ChartIndex from "../TradingViewChart/Components/Chart/components/ChartIndex";
import * as S from "./style";

const Trading: React.FC = () => {
  return <ChartIndex />;
};

export default Trading;
