import { BigNumber } from 'bignumber.js';
import { TriggerValue } from 'src/features/Market/Constant';
import { OrderType } from 'src/features/Market/Constant';
import { IOrder } from 'src/interfaces/order';
import { IAccount } from 'src/services/account';
import { Instrument } from 'src/services/instrument';
import { ITicker } from 'src/services/ticker';

export const getPrecision = (precision?: string | number | BigNumber): number | null => {
  if (precision) {
    const p = new BigNumber(Math.log10(Number(precision))).negated();
    return p?.isGreaterThanOrEqualTo(0) ? Number(p.toFixed()) : null;
  }
  return null;
};

// format
export const formatValidPrice = (price: string | number | BigNumber, instrument: Instrument): string => {
  if (new BigNumber(price).isNaN()) {
    return '';
  }
  return new BigNumber(price).toFixed(getPrecision(instrument?.tickSize) || 5);
};

export const formatValidPriceNegativeOptinal = (
  price: string | number | BigNumber,
  instrument: Instrument,
  allowNegative = false,
): string => {
  if (new BigNumber(price).isNaN()) {
    return '';
  }
  const result = allowNegative ? new BigNumber(price) : new BigNumber(price).abs();
  return result.toFixed(getPrecision(instrument?.tickSize) || 5);
};

export const formatValidAmount = (amount: string | number | BigNumber, instrument: Instrument): string => {
  if (new BigNumber(amount).isNaN()) {
    return '';
  }
  const contractSize = instrument?.contractSize;
  const lotSize = instrument?.lotSize;
  const minimumQuantity = Number(contractSize) * Number(lotSize);
  //   const precision = -Math.ceil(Math.log10(Number(minimumQuantity)));
  return new BigNumber(amount).toFixed(getPrecision(minimumQuantity) || 2);
};

export const ERROR = {
  NO_ERROR: '',
  COMMON: 'Error',
  QUANTITY_MIN: 'Your order size is smaller than the minimum size.',
  QUANTITY_MAX: 'Your order size is greater than the maximum size.',
  PRICE_MIN: 'Your order price is smaller than the minimum price.',
  PRICE_MAX: 'Your order price is greater than the maximum price.',
  TRAIL_VALUE_MIN: 'The absolute value of trail value is higher than the maximum price.',
  TRAIL_VALUE_MAX: 'The absolute value of trail value is higher than the maximum price.',
  TRIGGER_PRICE_MIN: 'Your trigger price is smaller than the minimum price.',
  TRIGGER_PRICE_MAX: 'Your trigger price is greater than the maximum price.',
  DISABLE_BUY_BUTTON: 'Disable Buy button',
  DISABLE_SELL_BUTTON: 'Disable Sell button',
  DISABLE_BOTH_BUTTON: 'Disable 2 button',
  NOT_ENOUNGH_MARGIN_BUY: 'You have insufficient margin to place this order.',
  NOT_ENOUNGH_MARGIN_SELL: 'You have insufficient margin to place this order.',
  CANCEL_ORDER: 'Limit price crosses liquidation price.',
  EMPTY_ORDERBOOK: 'Market order cannot be placed when there is no order in Order Book',
  NO_BUY_ORDER: 'Sell Market order cannot be placed when there is no buy order',
  NO_SELL_ORDER: 'Buy Market order cannot be placed when there is no sell order',
};

const validateQuantityMin = (quantity: string | number, instrument: Instrument) => {
  const minimumQuantity = Number(instrument.contractSize) * Number(instrument.lotSize);
  if (new BigNumber(quantity).gte(minimumQuantity)) {
    return true;
  }
  return false;
};

const validateQuantityMax = (quantity: string | number, instrument: Instrument) => {
  const maximumQuantity = Number(instrument.maxOrderQty);
  if (new BigNumber(quantity).lte(maximumQuantity)) {
    return true;
  }
  return false;
};

const validatePriceMin = (quantity: string | number, instrument: Instrument) => {
  if (new BigNumber(quantity).gte(instrument.tickSize)) {
    return true;
  }
  return false;
};

const validatePriceMax = (price: string | number, instrument: Instrument) => {
  if (new BigNumber(price).lte(instrument.maxPrice)) {
    return true;
  }
  return false;
};

export const getTriggerPrice = (ticker: ITicker | undefined, triggerOption: string): string | undefined => {
  switch (triggerOption) {
    case TriggerValue.oracle:
      return ticker?.oraclePrice;
    case TriggerValue.last:
      return ticker?.lastPrice;
    case TriggerValue.index:
      return ticker?.indexPrice;
    default:
      return ticker?.oraclePrice;
  }
};

const validateStopOrderTriggerPrice = (price: string, triggerPrice: string | undefined) => {
  if (new BigNumber(price).eq(triggerPrice || '')) {
    return ERROR.DISABLE_BOTH_BUTTON;
  }
  if (new BigNumber(price).gt(triggerPrice || '')) {
    return ERROR.DISABLE_SELL_BUTTON;
  } else if (new BigNumber(price).lt(triggerPrice || '')) {
    return ERROR.DISABLE_BUY_BUTTON;
  }
  return ERROR.NO_ERROR;
};

const validateTakeProfitOrderTriggerPrice = (price: string, triggerPrice: string | undefined) => {
  if (new BigNumber(price).eq(triggerPrice || '')) {
    return ERROR.DISABLE_BOTH_BUTTON;
  }
  if (new BigNumber(price).gt(triggerPrice || '')) {
    return ERROR.DISABLE_BUY_BUTTON;
  } else if (new BigNumber(price).lt(triggerPrice || '')) {
    return ERROR.DISABLE_SELL_BUTTON;
  }
  return ERROR.NO_ERROR;
};

const validateLimitOrder = (
  order: IOrder,
  instrument: Instrument,
  ticker: ITicker | undefined,
  account: IAccount | undefined,
) => {
  let message = ERROR.NO_ERROR;
  if (order.quantity && order.price) {
    if (!validateQuantityMin(order.quantity, instrument)) {
      return ERROR.QUANTITY_MIN;
    }
    if (!validateQuantityMax(order.quantity, instrument)) {
      return ERROR.QUANTITY_MAX;
    }
    if (!validatePriceMin(order.price, instrument)) {
      return ERROR.PRICE_MIN;
    }
    if (!validatePriceMax(order.price, instrument)) {
      return ERROR.PRICE_MAX;
    }
  } else {
    message = ERROR.DISABLE_BOTH_BUTTON;
  }
  return message;
};

const validateMarketOrder = (order: IOrder, instrument: Instrument) => {
  let message = ERROR.NO_ERROR;
  if (order.quantity) {
    if (!validateQuantityMin(order.quantity, instrument)) {
      message = ERROR.QUANTITY_MIN;
    }
    if (!validateQuantityMax(order.quantity, instrument)) {
      return ERROR.QUANTITY_MAX;
    }
  } else {
    message = ERROR.DISABLE_BOTH_BUTTON;
  }
  return message;
};

const validateMarketStopOrder = (order: IOrder, instrument: Instrument, triggerPrice: string | undefined) => {
  let message = ERROR.NO_ERROR;
  if (order.quantity && order.stopPrice) {
    if (!validateQuantityMin(order.quantity, instrument)) {
      return ERROR.QUANTITY_MIN;
    }
    if (!validateQuantityMax(order.quantity, instrument)) {
      return ERROR.QUANTITY_MAX;
    }
    if (!validatePriceMin(order.stopPrice, instrument)) {
      return ERROR.TRIGGER_PRICE_MIN;
    }
    if (!validatePriceMax(order.stopPrice, instrument)) {
      return ERROR.TRIGGER_PRICE_MAX;
    }
    const validateWithTriggerPriceMes = validateStopOrderTriggerPrice(order.stopPrice, triggerPrice);
    if (validateWithTriggerPriceMes !== ERROR.NO_ERROR) {
      return validateWithTriggerPriceMes;
    }
  } else {
    message = ERROR.DISABLE_BOTH_BUTTON;
  }
  return message;
};

const validateLimitStopOrder = (order: IOrder, instrument: Instrument, triggerPrice: string | undefined) => {
  let message = ERROR.NO_ERROR;
  if (order.quantity && order.stopPrice && order.price) {
    if (!validateQuantityMin(order.quantity, instrument)) {
      return ERROR.QUANTITY_MIN;
    }
    if (!validateQuantityMax(order.quantity, instrument)) {
      return ERROR.QUANTITY_MAX;
    }
    if (!validatePriceMin(order.price, instrument)) {
      return ERROR.PRICE_MIN;
    }
    if (!validatePriceMax(order.price, instrument)) {
      return ERROR.PRICE_MAX;
    }
    if (!validatePriceMin(order.stopPrice, instrument)) {
      return ERROR.TRIGGER_PRICE_MIN;
    }
    if (!validatePriceMax(order.stopPrice, instrument)) {
      return ERROR.TRIGGER_PRICE_MAX;
    }
    const validateWithTriggerPriceMes = validateStopOrderTriggerPrice(order.stopPrice, triggerPrice);
    if (validateWithTriggerPriceMes !== ERROR.NO_ERROR) {
      return validateWithTriggerPriceMes;
    }
  } else {
    message = ERROR.DISABLE_BOTH_BUTTON;
  }
  return message;
};

const validateTakeProfitLimitOrder = (order: IOrder, instrument: Instrument, triggerPrice: string | undefined) => {
  let message = ERROR.NO_ERROR;
  if (order.quantity && order.stopPrice && order.price) {
    if (!validateQuantityMin(order.quantity, instrument)) {
      return ERROR.QUANTITY_MIN;
    }
    if (!validateQuantityMax(order.quantity, instrument)) {
      return ERROR.QUANTITY_MAX;
    }
    if (!validatePriceMin(order.price, instrument)) {
      return ERROR.PRICE_MIN;
    }
    if (!validatePriceMin(order.stopPrice, instrument)) {
      return ERROR.TRIGGER_PRICE_MIN;
    }
    if (!validatePriceMax(order.price, instrument)) {
      return ERROR.PRICE_MAX;
    }
    if (!validatePriceMax(order.stopPrice, instrument)) {
      return ERROR.TRIGGER_PRICE_MAX;
    }
    const validateWithTriggerPriceMes = validateTakeProfitOrderTriggerPrice(order.stopPrice, triggerPrice);
    if (validateWithTriggerPriceMes !== ERROR.NO_ERROR) {
      return validateWithTriggerPriceMes;
    }
  } else {
    message = ERROR.DISABLE_BOTH_BUTTON;
  }
  return message;
};

const validateTakeProfitMarketOrder = (order: IOrder, instrument: Instrument, triggerPrice: string | undefined) => {
  let message = ERROR.NO_ERROR;
  if (order.quantity && order.stopPrice) {
    if (!validateQuantityMin(order.quantity, instrument)) {
      message = ERROR.QUANTITY_MIN;
    } else if (!validateQuantityMax(order.quantity, instrument)) {
      message = ERROR.QUANTITY_MAX;
    } else if (!validatePriceMax(order.stopPrice, instrument)) {
      message = ERROR.TRIGGER_PRICE_MAX;
    } else if (!validatePriceMin(order.stopPrice, instrument)) {
      message = ERROR.TRIGGER_PRICE_MIN;
    } else {
      message = validateTakeProfitOrderTriggerPrice(order.stopPrice, triggerPrice);
    }
  } else {
    message = ERROR.DISABLE_BOTH_BUTTON;
  }
  return message;
};

const validateTrailingStopOrder = (order: IOrder, instrument: Instrument) => {
  let message = ERROR.NO_ERROR;
  if (order.quantity && order.trailValue && Number(order.trailValue)) {
    if (!validateQuantityMin(order.quantity, instrument)) {
      message = ERROR.QUANTITY_MIN;
    } else if (!validateQuantityMax(order.quantity, instrument)) {
      message = ERROR.QUANTITY_MAX;
    } else if (!validatePriceMax(new BigNumber(order.trailValue).negated().toString(), instrument)) {
      message = ERROR.TRAIL_VALUE_MIN;
    } else if (!validatePriceMax(order.trailValue, instrument)) {
      message = ERROR.TRAIL_VALUE_MAX;
    } else if (new BigNumber(order.trailValue).gt(0)) {
      message = ERROR.DISABLE_SELL_BUTTON;
    } else if (new BigNumber(order.trailValue).lt(0)) {
      message = ERROR.DISABLE_BUY_BUTTON;
    }
  } else {
    message = ERROR.DISABLE_BOTH_BUTTON;
  }
  return message;
};

export const validateCreateOrder = (
  type: OrderType,
  order: IOrder,
  instrument: Instrument,
  triggerPrice?: string | undefined,
  ticker?: ITicker,
  account?: IAccount,
): string => {
  switch (type) {
    case OrderType.limit:
      return validateLimitOrder(order, instrument, ticker, account);
    case OrderType.market:
      return validateMarketOrder(order, instrument);
    case OrderType.stopLimit:
      return validateLimitStopOrder(order, instrument, triggerPrice);
    case OrderType.stopMarket:
      return validateMarketStopOrder(order, instrument, triggerPrice);
    case OrderType.takeProfitLimit:
      return validateTakeProfitLimitOrder(order, instrument, triggerPrice);
    case OrderType.takeProfitMarket:
      return validateTakeProfitMarketOrder(order, instrument, triggerPrice);
    case OrderType.trailingStop:
      return validateTrailingStopOrder(order, instrument);
    default:
      return ERROR.NO_ERROR;
  }
};

export const getVertexPrice = (trigger: TriggerValue, ticker: ITicker | undefined): string | undefined => {
  switch (trigger) {
    case TriggerValue.oracle:
      return ticker?.oraclePrice;
    case TriggerValue.last:
      return ticker?.lastPrice;
    case TriggerValue.index:
      return ticker?.indexPrice;
    default:
      return ticker?.oraclePrice;
  }
};
