import React, { useCallback, useState } from 'react';
import classNames from 'classnames/bind';
import styles from 'src/features/Market/components/Preference/PreferenceModal.module.scss';
// import { cancelIcon, confirmIcon, editIcon, percentIcon, percentIconDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import SwitchButton from 'src/components/cores/SwitchBox/SwitchButton';
import { noti } from 'src/hooks/useNoti';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import axiosInstance from 'src/helpers/config';
import { getPreference } from 'src/services/account';
import { THEME_MODE } from 'src/interfaces/theme';
import { useTranslation } from 'react-i18next';
import {
  BsFillPencilFill,
  BsPercent,
  BsFillXCircleFill,
  BsCheckCircleFill,
} from "react-icons/bs";

const cx = classNames.bind(styles);

type TField = 'stopLossTrigger' | 'fundingFeeTrigger' | 'fundingFeeTriggerValue';
const DECIMAL_REGEX = /^(\d{0,1})(\.\d{0,3})?$/;

const deFormatCommaNumber = (value: string | number) => {
  return value.toString().replaceAll(',', '');
};

export const Notification = () => {
  const { t } = useTranslation('common');
  const userSetting = useSelector((state: RootState) => state.account.userSetting);
  const [edit, setEdit] = useState(false);
  const [fundingFeeValue, setFundingFeeValue] = useState<string | number>(Number(userSetting.fundingFeeTriggerValue));
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const dispatch = useAppDispatch();

  const handlePostSetting = async (field: TField) => {
    setEdit(false);
    const valueSetting = Number(fundingFeeValue) === 0 ? 0.25 : Number(fundingFeeValue);
    setFundingFeeValue(valueSetting);
    try {
      const params = {
        [field]: field === 'fundingFeeTriggerValue' ? valueSetting : !userSetting[field],
      };
      await axiosInstance.post('/user-setting/preference', params);
      dispatch(getPreference());
      noti.success({
        title: `${t('tradingRule.noti_setting')}`,
        message: `${t('tradingRule.noti_adjusted_success')}`,
      });
    } catch (error) {
      console.log('====>',error)
    }
  };

  const handleOnChangeFundingFee = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const value = deFormatCommaNumber(event.target.value);
    if (value?.match(DECIMAL_REGEX) && Number(value) <= 5) {
      setFundingFeeValue(value as unknown as number);
    }
  }, []);

  const handleCancelFunding = () => {
    setEdit(!edit);
    setFundingFeeValue(Number(userSetting.fundingFeeTriggerValue));
  };

  return (
    <div className={cx("order-content")}>
      <div className={cx("option-order")}>
        <div className={cx("option-text")}>{t("tradingRule.tpsl_trigger")}</div>
        <div>
          <SwitchButton
            checked={userSetting.stopLossTrigger}
            onClick={() => handlePostSetting("stopLossTrigger")}
          />
        </div>
      </div>
      <div className={cx("note-noti")}>
        {t("tradingRule.notification_limit")}
      </div>
      <div className={cx("option-order")}>
        <div className={cx("option-text")}>
          {t("tradingRule.funding_fee_trigger")}
        </div>
        <div>
          <SwitchButton
            checked={userSetting.fundingFeeTrigger}
            onClick={() => {
              handlePostSetting("fundingFeeTrigger");
            }}
          />
        </div>
      </div>

      <div className={cx("note-funding")}>
        {t("tradingRule.notified_charged_reaches")}:{" "}
        <span className={cx("note-funding__item")}>
          <b className={cx("text-fee")}>{Number(fundingFeeValue)}%</b>
          <button
            onClick={() => setEdit(!edit)}
            className={cx("edit-btn")}
            disabled={!userSetting.fundingFeeTrigger}
          >
            <BsFillPencilFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              style={{ zoom: 1.2 }}
            />
          </button>
        </span>
      </div>
      {edit && (
        <>
          <div className={cx("funding-input")}>
            <input
              className={cx("input-value")}
              type="text"
              value={fundingFeeValue}
              onChange={handleOnChangeFundingFee}
            />

            <div className={cx("edit-icon")}>
              <BsPercent
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.1 , marginTop:"2px"}}
              />
              <BsCheckCircleFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.5, cursor: "pointer" }}
                onClick={() => handlePostSetting("fundingFeeTriggerValue")}
              />
              <BsFillXCircleFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.5, cursor: "pointer" }}
                onClick={handleCancelFunding}
              />
            </div>
          </div>
          <div className={cx("notice")}>
            {t("tradingRule.trigger_is_limited", { number: "0.001%-5%" })}{" "}
          </div>
        </>
      )}

      <div className={cx("note")}>{t("tradingRule.notification_noti")}</div>
    </div>
  );
};
