import TextNumber from "src/components/cores/TextNumber";
import { IOrder } from "src/interfaces/order";
import { Instrument } from "src/services/instrument";

export default function RenderQuantity({
  order,
  instruments,
  isCoinM,
}: {
  order: IOrder;
  instruments: Instrument[];
  isCoinM: boolean;
}) {
  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === order.symbol
  );
  // return (
  //   <TooltipNumber
  //     defaultValue={order?.quantity}
  //     characters={9}
  //     decimal={isCoinM ? 0 : 4}
  //     symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
  //   />
  // );

  return (
    <TextNumber
      defaultValue={order?.quantity ?? "0"}
      symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
      isCoinM={isCoinM}
    />
  );
}
