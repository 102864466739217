import TextNumber from "src/components/cores/TextNumber";
import { TransactionType } from "src/features/PositionAndHistory/constant";
import { ITransactionHistory } from ".";

export default function RenderAmount({
  transaction,
}: {
  transaction: ITransactionHistory;
}) {
  if (transaction.type === TransactionType.WITHDRAWAL) {
    // return <TooltipNumber defaultValue={`-${transaction.amount}`} characters={20} decimal={8} />;
    return (
      <TextNumber defaultValue={`-${transaction.amount}`} isCoinM={false} />
    );
  }

  // return <TooltipNumber defaultValue={transaction.amount} characters={20} decimal={8} />;
  return <TextNumber defaultValue={transaction.amount} isCoinM={false} />;
}
