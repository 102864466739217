import { useTranslation } from "react-i18next";
import { BsInboxesFill } from "react-icons/bs";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";
import * as S from "./style";

export default function NoData() {
  const { t } = useTranslation("common");
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  return (
    <S.Container>
      <BsInboxesFill
        fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
        style={{ zoom: 5 }}
      />
      <div className={"no-data-desc"}>{t("order.no_open_position")}</div>
    </S.Container>
  );
}
