import React, { useCallback, useEffect, useState } from 'react';
import { BootstrapDialogTitle } from '../DialogChooseMarginMode/DialogChooseMarginMode';
// import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import classnames from 'classnames/bind';
import styles from 'src/features/Market/components/ManageFunds/Transfer.module.scss';
import { Box, Dialog, styled } from '@material-ui/core';
// import {
//   transferIcon,
//   mainAccountIcon,
//   futureWalletIcon,
//   twoWayArrowIcon,
//   iconFromDarkMode,
//   iconToDarkMode,
//   iconCloseValueInput,
//   iconCloseDark,
//   iconArrowDownDark,
// } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { SelectCoin } from './SelectCoin';
import { CInput } from 'src/components/cores/Input';
import { isNumber, largeNumber } from 'src/helpers/numberFormatter';
import BigNumber from 'bignumber.js';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import axiosInstanceSpot from 'src/helpers/configAPISpot';
import axiosInstance from 'src/helpers/config';
import { getAccount, getBalanceSpot } from 'src/services/account';
import { THEME_MODE } from 'src/interfaces/theme';
import { getNumberRegex } from 'src/features/Market/components/OrderForm';
import { getPrecision } from 'src/features/Market/MarketHelper';
import { USD, USDT } from '../../Constant';
import { maxTransfer } from 'src/helpers/balanceCalculator';
import TooltipNumber from 'src/components/cores/Tooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/store';
import { useTranslation } from 'react-i18next';
// import { Instrument } from 'src/services/instrument';
import {
  BsArrowRepeat,
  BsArrowDown,
  BsFillWalletFill,
  BsCurrencyDollar,
} from "react-icons/bs";

export const SELECT_COIN = [
  {
    name: USDT,
    label: 'tether',
    key: USDT,
  },
  { name: USD, label: 'united_state_dollar', key: USD },
];

type AssetType = {
  name: string;
  label: string;
  key: string;
};

const cx = classnames.bind(styles);

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paperWidthSm': {
    borderRadius: '8px',
    minWidth: '343px',
  },
  '& .MuiTypography-root': {
    fontFamily: 'inherit',
  },
  '& .MuiDialogTitle-root': {
    marginBottom: '3px',
  },
}));

type PropsTransfer = {
  isOpen: boolean;
  onClose: any;
  defaultCoinSelect?: string;
};
// Max transfer = min (Wallet balance - Position Margin - Order Margin; Available balance)
export const Transfer: React.FC<PropsTransfer> = ({ isOpen, onClose, defaultCoinSelect }) => {
  const { t } = useTranslation('common');
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const balanceSpot: any = useAppSelector((state) => state.account.balanceSpot);
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);
  const account = useSelector((state: RootState) => state.account.account);
  // const currentInstrument: Instrument = useAppSelector((state) => state.instrument.currentInstrument);

  const [isFromFuture, setIsFromFuture] = useState(false);

  const [amount, setAmount] = useState('');

  const [coinSelect, setCoinSelect] = useState(SELECT_COIN[0]);
  const [messageErrorAmount, setMessageErrorAmount] = useState<string>('');
  const [disableConfirmBtn, setDisableConfirmBtn] = useState<boolean>(true);
  const [isMax, setIsMax] = useState(false);
  const [listAssets, setListAssets] = useState<AssetType[]>(SELECT_COIN);

  const handleListAsset = useCallback(() => {
    const assetFilter = account?.filter((item: any) => {
      const asset = item?.asset?.toUpperCase();
      const coin = asset !== USDT && asset !== USD;
      return isCoinM ? coin : !coin;
    });

    const listAsset = assetFilter.map((item) => ({
      name: item?.asset?.toUpperCase(),
      key: item?.asset?.toUpperCase(),
      label: 'Tether',
    }));

    const listAssetFinal = isCoinM ? listAsset : listAsset.reverse();

    setListAssets(listAssetFinal);
    setCoinSelect(listAssetFinal?.find((item) => item.key === defaultCoinSelect) || listAssetFinal[0]);
  }, [account, isCoinM]);

  useEffect(() => {
    handleListAsset();
  }, [handleListAsset]);

  const getMainBalance = (assetType: string) => {
    return balanceSpot[assetType]?.available_balance || balanceSpot?.usdt?.available_balance;
    // console.log("🚀 ~ getMainBalance ~ balanceSpot:", balanceSpot)
    // return 1
  };

  const ERROR = {
    ERROR_BALANCE_ACCOUNT: `${t('transfer.insufficient_error')}`,
    ERROR_SELECT_COIN: `${t('transfer.select_coin_error')}`,
  };

  const handleBalanceSelect = () => {
    const asset = coinSelect?.key;
    if (isFromFuture) {
      return maxTransfer(coinSelect?.key);
    } else {
      // return asset === USDT ? balanceSpot?.usdt.available_balance : balanceSpot?.usd.available_balance;
      return getMainBalance(asset?.toLowerCase());
    }
  };

  const dispatch = useAppDispatch();

  const handleSwitch = () => {
    setIsFromFuture(!isFromFuture);
    setCoinSelect(listAssets?.find((item) => item.key === coinSelect.key) || listAssets[0]);
    setAmount('');
    setMessageErrorAmount('');
  };

  const handleChangeMaxAmount = () => {
    setIsMax(true);
    setMessageErrorAmount('');
    setDisableConfirmBtn(false);
    const amount = new BigNumber(handleBalanceSelect()).toFixed(8, BigNumber.ROUND_DOWN);
    setAmount(amount);
    if (Number(handleBalanceSelect()) === 0) {
      setMessageErrorAmount(ERROR.ERROR_SELECT_COIN);
      setDisableConfirmBtn(true);
    }
  };

  const handleChangeCoin = () => {
    setAmount('');
    setMessageErrorAmount('');
  };

  const amountRegex = getNumberRegex(getPrecision(Number(3) * Number(1)) || 8);

  const handleChangeAmount = (value: string) => {
    setIsMax(false);
    if (!isNumber(value) && value) {
      return;
    }
    value = value?.trim().replace(/^0+\B/, '');
    if (value && Number(value) === 0) {
      setMessageErrorAmount(ERROR.ERROR_SELECT_COIN);
    } else if (new BigNumber(value).gt(handleBalanceSelect())) {
      setMessageErrorAmount(ERROR.ERROR_BALANCE_ACCOUNT);
      setDisableConfirmBtn(true);
    } else {
      setMessageErrorAmount('');
      setDisableConfirmBtn(false);
    }
    if (amountRegex.test(value)) {
      setAmount(value);
    }
    if (!value) {
      setAmount('');
    }
  };

  const handleConfirm = async () => {
    try {
      const amountTransfer = isMax ? handleBalanceSelect() : amount;

      setAmount('');
      if (!isFromFuture) {
        const params = {
          from: "main",
          to: "future",
          amount: largeNumber(amountTransfer),
          asset: coinSelect?.key?.toLowerCase(),
        };
        const res: any = await axiosInstanceSpot.post("/transfer", params);
        // await axiosInstanceSpot.post("/transfer", params);
        if(res.data){
          onClose();
          dispatch(getBalanceSpot());
          dispatch(getAccount());
        }
      } else {
        const params = {
          amount: largeNumber(amountTransfer),
          assetType: coinSelect.key,
        };
        // await axiosInstance.post("/account/withdraw", params);
        const res: any = await axiosInstance.post("/account/withdraw", params);
        if (res.code === 200) {
          onClose();
          dispatch(getBalanceSpot());
          dispatch(getAccount());
        }
      }
      setIsMax(false);
      // dispatch(getBalanceSpot());
      // dispatch(getAccount());
      // noti.success({
      //   title: 'Transfer successfully',
      // });
    } catch (error) {
      // noti.error({
      //   title: 'Transfer fail',
      // });
    }
  };

  // useEffect(() => {
  //   if (coinSelect.key === USDT) {
  //     setCoinSelect(SELECT_COIN[0]);
  //   } else {
  //     setCoinSelect(SELECT_COIN[1]);
  //   }
  // }, [balanceSpot, balanceFuture]);

  return (
    <BootstrapDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <div className={cx("dialog-wrapper")}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
          <div className={cx("title-dialog-transfer")}>
            {t("transfer.transfer")}
          </div>
        </BootstrapDialogTitle>

        <div className={cx("dialog-content")}>
          <div className={cx("text-desc")}>
            {t("transfer.internal_transfer")}
          </div>
          <div className={cx("transfer-box")}>
            <Box display="flex" alignItems="center">
              {isFromFuture ? (
                <BsCurrencyDollar
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  onClick={handleSwitch}
                  style={{ zoom: 1.25 }}
                />
              ) : (
                <BsFillWalletFill
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  onClick={handleSwitch}
                  style={{ zoom: 1.25 }}
                />
              )}

              <div className={cx("text-transfer")}>{t("transfer.from")}</div>
              <div className={cx("text-label")}>
                {isFromFuture
                  ? !isCoinM
                    ? `${t("transfer.future_wallet")}`
                    : `${t("transfer.coinM")}`
                  : `${t("transfer.main_account")}`}
              </div>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              className={cx("arrow-transfer")}
            >
              <BsArrowDown
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ marginLeft:"2.5px"}}
              />
              <BsArrowRepeat
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                onClick={handleSwitch}
                style={{ cursor: "pointer" }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              {!isFromFuture ? (
                <BsCurrencyDollar
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  onClick={handleSwitch}
                  style={{ zoom: 1.25 }}
                />
              ) : (
                <BsFillWalletFill
                  fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                  onClick={handleSwitch}
                  style={{ zoom: 1.25 }}
                />
              )}
              <div className={cx("text-transfer")}>{t("transfer.to")}</div>
              <div className={cx("text-label")}>
                {!isFromFuture
                  ? !isCoinM
                    ? `${t("transfer.future_wallet")}`
                    : `${t("transfer.coinM")}`
                  : `${t("transfer.main_account")}`}
              </div>
            </Box>
          </div>
          <div className={cx("title-dialog")}>{t("transfer.coin")}</div>
          <SelectCoin
            theme={theme}
            coinSelect={coinSelect}
            setCoinSelect={setCoinSelect}
            listAssets={[
              {
                name: USDT,
                label: "tether",
                key: USDT,
              },
              { name: USD, label: "united_state_dollar", key: USD },
            ]}
            handleChangeCoin={handleChangeCoin}
          />
          {/* <div className={cx('message-error')}>{messageErrorSelect}</div> */}

          <div className={cx("wrapper-amount")}>
            <Box display="flex" justifyContent="space-between">
              <div className={cx("title-dialog")}>{t("orderBook.amount")}</div>
              <div className={cx("coin-price")}>
                <TooltipNumber
                  defaultValue={handleBalanceSelect()}
                  characters={12}
                  decimal={8}
                />
                <div
                  className={cx("amount-desc")}
                  style={{ paddingLeft: "6px" }}
                >
                  {t("transfer.available")}
                </div>
              </div>
            </Box>
          </div>

          <div className={cx("wrapper-input")}>
            <div className={cx("input-group")}>
              <CInput
                classNamePrefixWrapper={cx("input")}
                classNamePrefix={cx("input-in")}
                type="text"
                placeholder=""
                size="sm"
                outline={false}
                valueHandSet={amount}
                onChange={handleChangeAmount}
              />
              {amount && (
                <div
                  className={cx("close-group")}
                  onClick={() => {
                    setAmount("");
                    setMessageErrorAmount("");
                    setDisableConfirmBtn(false);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={theme === THEME_MODE.LIGHT ? IconSVG : IconSVG}
                      alt="iconClose"
                    />
                  </div>
                </div>
              )}
              <div className={cx("max-btn")} onClick={handleChangeMaxAmount}>
                {t("transfer.max")}
              </div>
            </div>
          </div>
          <div className={cx("message-error")}>{messageErrorAmount}</div>
        </div>
        <DialogActions className={cx("button-modal")}>
          <button
            className={cx("button-confirm")}
            disabled={disableConfirmBtn || Number(amount) === 0}
            onClick={handleConfirm}
          >
            {t("trading.confirm")}
          </button>
        </DialogActions>
      </div>
    </BootstrapDialog>
  );
};
