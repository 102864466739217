import React, { useEffect, useState } from "react";
import classnames from "classnames/bind";
import styles from "src/features/Market/components/Calculator/Calculator.module.scss";
import { CInput } from "src/components/cores/Input";
import BigNumber from "bignumber.js";
import {
  countCharacter,
  formatNumber,
  handleNumber,
  isNumber,
} from "src/helpers/numberFormatter";
// import { iconDelete, iconDeleteDisable } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import useScreen from "src/hooks/useScreen";
import { getNumberRegex } from "./constants";
import TooltipText from "src/components/cores/TooltipText";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { TypeTrade } from "src/constants/common.constants";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";
import { useTranslation } from "react-i18next";
import { BsDashCircleFill } from "react-icons/bs";

const cx = classnames.bind(styles);

export const OpenPriceCalculator = (props: any) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [isLong, setIsLong] = useState(true);
  const [listPosition, setListPosition] = useState<any>([
    {
      entry_price: "",
      quantity: "",
    },
  ]);
  const [resultAvg, setResultAvg] = useState("");
  const [isDisable, setIsDisable] = useState(true);

  const { isMobile } = useScreen();
  const { t } = useTranslation("common");

  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);

  const handleAdd = () => {
    if (listPosition.length < 10) {
      setListPosition([
        ...listPosition,
        {
          entry_price: "",
          quantity: "",
        },
      ]);
    }
  };

  const handleDelete = (index_delete: number) => {
    if (listPosition.length > 1) {
      const listPositonNew = listPosition?.filter(
        (item: any, index: number) => index !== index_delete
      );
      setListPosition(listPositonNew);
    }
  };

  const handleCheckDisable = () => {
    const arrNotFill = listPosition?.filter(
      (item: any) => item.entry_price === "" || item.quantity === ""
    );
    setIsDisable(arrNotFill.length > 0);
  };

  // Avg Price = Sum [ Entry Price(i) * Quantity(i) ] / Sum [Quantity(i)]
  // Avg Price coin M = Sum [Quantity(i) /Sum [ Quantity(i) /Entry Price(i)  ] ]
  const handleCalculator = () => {
    let sum: any = 0,
      sumQuantity: any = 0;
    const isCoinM = props?.pair?.contractType === TypeTrade.COIN_M;

    for (let i = 0; i < listPosition.length; i++) {
      const calculatorRow = isCoinM
        ? new BigNumber(listPosition[i].quantity).div(
            listPosition[i].entry_price
          )
        : new BigNumber(listPosition[i].entry_price).times(
            listPosition[i].quantity
          );
      sum = new BigNumber(sum).plus(calculatorRow);
      sumQuantity = new BigNumber(sumQuantity).plus(listPosition[i].quantity);
    }
    const result = isCoinM ? sumQuantity.div(sum) : sum.div(sumQuantity);
    setResultAvg(formatNumber(result.toString(), 2, "-"));
  };

  useEffect(() => {
    listPosition.length > 0 && handleCheckDisable();
  }, [listPosition]);

  return (
    <div>
      <div className={cx(isMobile ? "wrapper-content-mb" : "wrapper-content")}>
        {/* Content Left */}
        <div
          className={cx(
            isMobile ? "wrapper-content-mb__left" : "wrapper-content__left"
          )}
        >
          <div
            className={cx(
              isMobile
                ? "wrapper-content-mb__left__type-trade"
                : "wrapper-content__left__type-trade"
            )}
          >
            <div
              onClick={() => setIsLong(true)}
              className={cx(isLong && "type-active")}
            >
              {t("calculator.long")}
            </div>
            <div
              onClick={() => setIsLong(false)}
              className={cx(!isLong && "type-active-short")}
            >
              {t("calculator.short")}
            </div>
          </div>
        </div>

        {/* Content Right */}

        {!isMobile && (
          <div
            className={cx(
              isMobile ? "wrapper-content-mb__right" : "wrapper-content__right"
            )}
            style={{ marginBottom: "46px" }}
          >
            <div className={cx("text-medium")}>{t("calculator.result")}</div>
            <div className={cx("result-content")}>
              <div className={cx("text-result")}>
                {t("calculator.avg_price")} ({props.pair?.quoteCurrency})
              </div>
              <TooltipText
                text={countCharacter(resultAvg) > 10 ? resultAvg : ""}
              >
                <div
                  className={cx(
                    isMobile ? "tooltip-value-mb" : "tooltip-value"
                  )}
                >
                  <div className={cx("text-value")}>
                    {handleNumber(resultAvg, 10) || "-"}
                  </div>
                </div>
              </TooltipText>
            </div>
          </div>
        )}

        {/* Button Calculate */}
      </div>

      <div className={cx("content-open")}>
        <div className={cx("wrapper-table")}>
          <div className={cx("table-mb")}>
            <div className={cx("open-price-row", "open-price-title")}>
              <div>{t("calculator.open")}</div>
              <div>
                {t("order.entry_price")} ({props.pair?.quoteCurrency})
              </div>
              <div>
                {t("order.quantity")} (
                {isCoinM ? "Cont" : props.pair?.rootSymbol})
              </div>
              <div>{t("calculator.action")}</div>
            </div>

            <div className={cx("content-table")}>
              {listPosition.length > 0 &&
                listPosition.map((item: any, index: number) => (
                  <div className={cx("open-price-row")} key={index}>
                    <div className={cx("index-open-input")}>{index + 1}</div>
                    <CInput
                      classNamePrefixWrapper={cx("input")}
                      classNamePrefix={cx("input-open-price")}
                      type="text"
                      placeholder=""
                      size="sm"
                      outline={false}
                      valueHandSet={item.entry_price}
                      onChange={(value: string): void => {
                        if (!isNumber(value) && value) {
                          return;
                        }
                        const regexNumber = getNumberRegex(10, 2);
                        if (regexNumber.test(value)) {
                          listPosition[index] = {
                            ...listPosition[index],
                            entry_price: value,
                          };
                          setListPosition([...listPosition]);
                        } else {
                          if (value === "") {
                            listPosition[index] = {
                              ...listPosition[index],
                              entry_price: "",
                            };
                            setListPosition([...listPosition]);
                          }
                        }
                      }}
                    />
                    <CInput
                      classNamePrefixWrapper={cx("input")}
                      classNamePrefix={cx("input-open-price")}
                      type="text"
                      placeholder=""
                      size="sm"
                      outline={false}
                      valueHandSet={item.quantity}
                      onChange={(value: string): void => {
                        if (
                          (!isNumber(value) && value) ||
                          (isCoinM && value.includes("."))
                        ) {
                          return;
                        }
                        const regexNumber = getNumberRegex(8, isCoinM ? 0 : 3);

                        if (regexNumber.test(value)) {
                          listPosition[index] = {
                            ...listPosition[index],
                            quantity: value,
                          };
                          setListPosition([...listPosition]);
                        } else {
                          if (value === "") {
                            listPosition[index] = {
                              ...listPosition[index],
                              quantity: "",
                            };
                            setListPosition([...listPosition]);
                          }
                        }
                      }}
                    />
                    <BsDashCircleFill
                      fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                      style={{ zoom: 1.75, cursor: "pointer" }}
                      onClick={() => handleDelete(index)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className={cx("add-postion")}
          onClick={handleAdd}
          style={{
            opacity: listPosition.length === 10 ? 0.5 : 1,
            fontSize: isMobile ? "14px" : "",
          }}
        >
          + {t("calculator.add_order")}
        </div>
        {isMobile && (
          <div
            className={cx(
              isMobile ? "wrapper-content-mb__right" : "wrapper-content__right"
            )}
            style={{ marginTop: "20px" }}
          >
            <div className={cx(isMobile ? "text-medium-mb" : "text-medium")}>
              {t("calculator.result")}
            </div>
            <div className={cx("result-content")}>
              <div className={cx(isMobile ? "text-result-mb" : "text-result")}>
                {t("calculator.avg_price")} ({props.pair?.quoteCurrency})
              </div>
              <div className={cx(isMobile ? "text-dark-mb" : "text-dark")}>
                {resultAvg || "-"}{" "}
              </div>
              <TooltipText
                text={countCharacter(resultAvg) > 10 ? resultAvg : ""}
              >
                <div
                  className={cx(
                    isMobile ? "tooltip-value-mb" : "tooltip-value"
                  )}
                >
                  <div className={cx("text-value")}>
                    {handleNumber(resultAvg, 10) || "-"}
                  </div>
                </div>
              </TooltipText>
            </div>
          </div>
        )}
        <button
          className={cx("button-calculate")}
          disabled={isDisable}
          onClick={handleCalculator}
        >
          {t("calculator.calculate")}
        </button>
      </div>
    </div>
  );
};
