import styled from "@emotion/styled";

export const Container = styled.div``;

export const BoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding-top: 8px;
  padding-left: 16px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    scrollbar-width: thin;
    height: 0px;
  }
  .tab-wrapper {
    display: flex;
    gap: 16px;
    height: 100%;
    padding-right: 16px;
    .tab {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.colorTab};
      font-size: 14px;
      white-space: nowrap;
      font-weight: 400;
      border-bottom: 1px solid transparent;
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
      @media only screen and (max-width: 400px) {
        font-size: 14px;
      }
    }
    .active {
      border-radius: 5px 5px 0 0;
      color: ${({ theme }) => theme.colors.colorTabActiveDate};
      border-bottom: 1px solid ${({ theme }) => theme.colors.colorTabActiveDate};
    }
  }
  .hidden-btn {
    display: flex !important;
    align-items: center !important;

    .container {
      display: block;
      position: relative;
      padding-left: 30px;
      cursor: pointer;
      font-size: 20px;
      margin-top: -2px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      height: 20px;
    }

    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      left: 0px;
      top: 2px;
      height: 16px;
      width: 16px;
      background-color: var(--checkmark);
      border-radius: 2px;
    }

    .container input:checked ~ .checkmark {
      background-color: var(--color-primary-1);
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      left: 5px;
      top: 1px;
      width: 4px;
      height: 10px;
      border: solid black;
      border-width: 0 2.5px 2.5px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .hidden-text {
      color: var(--color-title-active);
      font-size: 14px;
      white-space: nowrap;
      font-weight: 400;
      @media only screen and (max-width: 400px) {
        font-size: 14px;
      }
    }
  }
  .action-close-all {
    padding: 8px 14px;
    background-color: ${({ theme }) => theme.colors.actionCloseAll};
    border-radius: 63px;
    font-size: 12px;
    cursor: pointer;
    margin-right: 20px;
    min-width: 96px;
    white-space: pre;
    max-height: 24px;
    display: flex;
    align-items: center;
  }
`;
