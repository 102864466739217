import classnames from 'classnames/bind';
import React, { useState } from 'react';
import styles from 'src/components/cores/SwitchBox/Switch.module.scss';

const cx = classnames.bind(styles);

interface Props {
  checked: boolean;
  disabled?: boolean;
  onClick: () => void;
}

const SwitchButton: React.FC<Props> = ({ checked, disabled, onClick }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked);

  const handleOnClicked = () => {
    setIsChecked((prev) => !prev);
    onClick();
  };

  return (
    <div
      className={cx('wrapper', { wrapper__disabled: disabled, wrapper__enabled: isChecked })}
      onClick={handleOnClicked}
    >
      <div className={cx('wrapper__switch', { wrapper__switch__enable: isChecked })}></div>
    </div>
  );
};

export default SwitchButton;
