import classnames from "classnames/bind";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { BsFillStarFill } from "react-icons/bs";

// import {
//   iconStar,
//   iconStarActive,
//   noData,
//   iconSort,
//   noDataDark,
//   usds,
//   usdsDisable,
//   usdsDark,
//   usdsDisableDark,
//   iconSortUp,
//   iconSortDown,
//   iconSearchContractLight,
//   iconSearchContractDark,
//   sortDownDark,
//   sortUpDark,
//   sortWrapDark,
//   iconStarDark,
// } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import TooltipNumber from "src/components/cores/Tooltip";
import { TypeTrade } from "src/constants/common.constants";
import styles from "src/features/Market/components/ChooseMarketModal/ChooseMarketModal.module.scss";
import { clearMarketTrades } from "src/features/OrderbookTrade/redux/MarketTrade.slice";
import { removeOrderbook } from "src/features/OrderbookTrade/redux/Orderbook.slice";
import {
  formatPriceRoundDown,
  getValueClassName,
} from "src/helpers/numberFormatter";
import { getCookieStorage } from "src/helpers/storage";
import { THEME_MODE } from "src/interfaces/theme";
import { setFirstLoadFunding } from "src/services/funding";
import {
  getFavInstrument,
  Instrument,
  setCurrentInstrument,
  setFavIns,
  updateFavInstrument,
} from "src/services/instrument";
import { setFirstLoadOrder } from "src/services/order";
import { setFirstLoadFill } from "src/services/trade";
import { useAppSelector } from "src/store/hooks";
import { RootState } from "src/store/store";
import {
  BsChevronBarExpand,
  BsCaretDownFill,
  BsCaretUpFill,
  BsExclamationCircle,
} from "react-icons/bs";

const cx = classnames.bind(styles);

interface ChooseMarketModalProps {
  handleChooseOption: any;
  handleClose: () => void;
}

const DEFAULT_TICKER = {
  symbol: "",
  priceChange: "0.00",
  priceChangePercent: "0.00",
  lastPrice: "0.00",
  lastPriceChange: "0.00",
  highPrice: "0.00",
  lowPrice: "0.00",
  quoteVolume: "0.00",
  volume: "0.00",
  indexPrice: "0.00",
  oraclePrice: "0.00",
  fundingRate: "0.00",
  nextFunding: 0,
};

const TAB = {
  USD_M: "usdM",
  COIN_M: "coinM",
  FAV: "favourites",
};

export const getIconPriceChange = (value: string | undefined) => {
  if (value === undefined || value === null) {
    return "";
  }
  const number = parseFloat(value);
  if (number > 0) {
    return <div className={cx("increase")}>+</div>;
  } else if (number === 0) {
    return "";
  } else {
    return <div className={cx("decrease")}>-</div>;
  }
};

const ChooseMarketModal: React.FC<ChooseMarketModalProps> = ({
  handleChooseOption,
}) => {
  const dispatch = useDispatch();
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);
  const [keywordSearch, setKeywordSearch] = useState<any>("");
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const favInstruments = useAppSelector(
    (state) => state.instrument.favoriteInstruments
  );
  const favIns = useAppSelector((state) => state.instrument.favIns);
  const [listInstrusment, setListInstrument] =
    useState<Instrument[]>(instruments);
  const listInstrusmentRef = useRef<any>([]);
  const [tab, setTab] = useState(isCoinM ? TAB.COIN_M : TAB.USD_M);
  const tickers = useAppSelector((state) => state.ticker.tickers);
  const [filteredInstruments, setFilteredInstruments] = useState<any[]>([]);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation("common");

  // console.log("listInstrusment", listInstrusment);
  // console.log("instruments", instruments);

  const sortIcon = [
    // `${theme === THEME_MODE.LIGHT ? Icon : Icon}`,
    // `${theme === THEME_MODE.LIGHT ? Icon : Icon}`,
    // `${theme === THEME_MODE.LIGHT ? Icon : Icon}`,
    <BsChevronBarExpand
      fill={`${theme === THEME_MODE.LIGHT ? "black" : "gray"}`}
    />,
    <BsCaretDownFill
      fill={`${theme === THEME_MODE.LIGHT ? "black" : "gray"}`}
    />,
    <BsCaretUpFill fill={`${theme === THEME_MODE.LIGHT ? "black" : "gray"}`} />,
  ];

  const TITLE_TABLE = [
    {
      name: `${t("pair.symbol")}`,
      field: "symbol",
    },
    {
      name: `${t("pair.last_price")}`,
      field: "lastPrice",
    },
    {
      name: `${t("pair.24h_change")}`,
      field: "priceChangePercent",
    },
    {
      name: `${t("pair.24h_volume")}`,
      field: "quoteVolume",
    },
  ];

  const [currentImageIndexs, setCurrentImageIndexs] = useState([0, 0, 0, 1]);
  const [fieldSort, setFieldSort] = useState("quoteVolume");
  const account = useSelector((state: RootState) => state.account.account);

  const [isLogged, setIsLogged] = useState(false);
  const token = getCookieStorage("access_token");

  useEffect(() => {
    if (token && token !== "undefined") {
      setIsLogged((account && Object.keys(account).length > 0) || false);
    } else setIsLogged(false);
  }, [account, token]);

  const handleImageClick = (index: any) => {
    const newImageIndexs = [...currentImageIndexs];
    newImageIndexs.forEach((_, imageIndex) => {
      if (imageIndex === index) {
        newImageIndexs[index] =
          (currentImageIndexs[index] + 1) % sortIcon.length;
      } else {
        newImageIndexs[imageIndex] = 0;
      }
    });
    setCurrentImageIndexs(newImageIndexs);
  };

  const sortData = (field: string, numberSort: any, data?: any[]) => {
    const dataFilter = data ? data : filteredInstruments;
    if (field === "symbol") {
      //@ts-ignore
      dataFilter.sort((a, b) => {
        if (numberSort === 0 ? a[field] > b[field] : a[field] < b[field]) {
          return -1;
        }
      });
    } else {
      dataFilter.sort((a, b) => {
        return Number(a[field] || 0) > Number(b[field] || 0)
          ? (numberSort === 0 ? -1 : 1) * 1
          : (numberSort === 0 ? -1 : 1) * -1;
      });
    }
  };

  useEffect(() => {
    setCurrentImageIndexs([0, 0, 0, 1]);
    // sortData('lastPrice', 0);
    isLogged && dispatch(getFavInstrument());
  }, [isLogged, tab]);

  useEffect(() => {
    if (isLogged) {
      dispatch(setFavIns(favInstruments));
    }
  }, [isLogged, favInstruments]);

  useEffect(() => {
    const findCurrentImageIndexs = currentImageIndexs?.find(
      (imageIndex) => imageIndex !== 0
    );
    if (findCurrentImageIndexs) return;
    const instrumentClone = [...listInstrusmentRef.current];
    setListInstrument(instrumentClone);
  }, [currentImageIndexs]);

  // clone instrument to restore state when click sort 3 times / search
  const cloneInstrment = useCallback(() => {
    const instrumentClone: Instrument[] = [];

    instruments.map((item) => {
      const itemClone = { ...item };
      const favInstru = favIns?.find((e: any) => {
        return e.symbol === itemClone.symbol;
      });

      if (favInstru) {
        itemClone.isFavorite = true;
      } else itemClone.isFavorite = false;
      instrumentClone.push(itemClone);
    });

    const filterInstrument: Instrument[] =
      tab === TAB.FAV
        ? instrumentClone?.filter((item) => item.isFavorite === true)
        : instrumentClone;
    setListInstrument(
      filterInstrument?.filter((e) =>
        e.symbol.includes(keywordSearch.toUpperCase() || "")
      )
    );
    listInstrusmentRef.current = filterInstrument?.filter((e) =>
      e.symbol.includes(keywordSearch.toUpperCase() || "")
    );
  }, [instruments, favIns, keywordSearch, tab]);

  useEffect(() => {
    cloneInstrment();
    // sortData('lastPrice', 0);
  }, [cloneInstrment]);

  // map Instrument if any pair value is undefined
  const filteredInstr = useCallback(() => {
    const filtered: any[] = [];
    let lastFilter: any[] = [];
    listInstrusment.forEach((item) => {
      tickers.forEach((ticker) => {
        if (item.symbol === ticker.symbol) {
          const newObj = { ...item, ...ticker };
          filtered.push(newObj);
        }
      });
    });
    listInstrusment.forEach((item) => {
      const index = filtered.findIndex(
        (ticker) => ticker.symbol === item.symbol
      );
      if (index === -1) {
        const newObj = { ...DEFAULT_TICKER, ...item };
        lastFilter.push(newObj);
      } else {
        const newObj = { ...filtered[index], ...item };
        lastFilter.push(newObj);
      }
    });
    lastFilter = lastFilter?.filter((item) => {
      if (tab === TAB.COIN_M) {
        return item.contractType === TypeTrade.COIN_M;
      }
      if (tab === TAB.USD_M) {
        return item.contractType === TypeTrade.USD_M;
      }
      if (tab === TAB.FAV) {
        return item;
      }
    });
    setFilteredInstruments(lastFilter);
    const findCurrentImageIndexs = currentImageIndexs?.find(
      (imageIndex) => imageIndex !== 0
    );
    if (!findCurrentImageIndexs) {
      sortData("quoteVolume", 0, lastFilter);
    } else {
      sortData(fieldSort, findCurrentImageIndexs - 1, lastFilter);
    }
  }, [listInstrusment, tickers]);

  useEffect(() => {
    filteredInstr();
  }, [filteredInstr]);

  // update list favourite
  const handleUpdateFavInstrument = async (
    item: Instrument,
    isfav: boolean
  ) => {
    if (isfav === true) {
      dispatch(
        setFavIns([
          ...favIns,
          { symbol: item.symbol, isFavorite: !item.isFavorite },
        ])
      );
    } else {
      const index = favIns.findIndex((fav) => fav.symbol === item.symbol);
      if (index > -1) {
        const newArray = [...favIns];
        newArray.splice(index, 1);
        dispatch(setFavIns(newArray));
      }
    }
    // if login
    isLogged &&
      (await dispatch(
        updateFavInstrument({ symbol: item.symbol, isFavorite: isfav })
      ));
    isLogged && (await dispatch(getFavInstrument()));
  };

  return (
    <div className={cx("wrapper")}>
      <div className={cx("tab-wrapper")}>
        <div className={cx("tab-wrapper")}>
          <div
            onClick={() => setTab(TAB.FAV)}
            className={tab === TAB.FAV ? cx("tab", "active") : cx("tab")}
          >
            <div className="icon icon-star2" />
            <div>{t("pair.favorites")}</div>
          </div>
          <div
            onClick={() => setTab(TAB.USD_M)}
            className={tab === TAB.USD_M ? cx("tab", "active") : cx("tab")}
          >
            USDⓈ-M
          </div>
          <div
            onClick={() => setTab(TAB.COIN_M)}
            className={tab === TAB.COIN_M ? cx("tab", "active") : cx("tab")}
          >
            COIN-M
          </div>
        </div>
        <div className={cx("search-wrapper")}>
          <input
            className={cx("input-search")}
            type="text"
            placeholder={t("pair.search")}
            value={keywordSearch || ""}
            onChange={(event) => setKeywordSearch(event.target.value.trim())}
          />
          <div className={cx("search-icon")}>
            {/* <img src={theme === THEME_MODE.LIGHT ? Icon : Icon} /> */}
            <BsSearch
              fill={`${theme === THEME_MODE.LIGHT ? "black" : "gray"}`}
            />
          </div>
        </div>
      </div>
      <div className={cx("list-instr")}>
        <div className={cx("table-wrapper")}>
          <table className={cx("table")}>
            <thead>
              <tr>
                {TITLE_TABLE.map((item, index) => (
                  <th
                    className={cx("sort-table")}
                    key={index}
                    onClick={() => {
                      handleImageClick(index);
                      sortData(item.field, currentImageIndexs[index]);
                      setFieldSort(item.field);
                    }}
                  >
                    {item.name}
                    {sortIcon[currentImageIndexs[index]]}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className={cx("scrollbar", "data-table")}>
              {filteredInstruments.length !== 0 ? (
                filteredInstruments.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      className={cx("table-row")}
                      onClick={async () => {
                        dispatch(setFirstLoadOrder(true));
                        dispatch(setFirstLoadFill(true));
                        dispatch(setFirstLoadFunding(true));
                        dispatch(removeOrderbook());
                        dispatch(clearMarketTrades());
                        // await dispatch(setCurrentInstrument(item));
                        // history.push(`/futures/${item?.contractType}/${item.symbol}`);
                        window.location.replace(
                          `/futures/${item?.contractType}/${item.symbol}`
                        );
                        handleChooseOption(item);
                      }}
                    >
                      <td
                        className={cx("contract-list")}
                        style={{ display: "flex", gap: "5px" }}
                      >
                        <BsFillStarFill
                          fill={item.isFavorite ? "#F0B90B" : "gray"}
                          style={{ marginBottom: "6px" }}
                          onClick={(event) => {
                            handleUpdateFavInstrument(item, !item.isFavorite);
                            event.stopPropagation();
                          }}
                        />
                        <div className={cx("symbol-pair")}>
                          {item?.symbol} {t("pair.perpetual")}
                        </div>
                      </td>
                      <td className={cx("last-price")}>
                        <span
                          className={cx(
                            getValueClassName(item?.lastPriceChange)
                          )}
                        >
                          <TooltipNumber
                            defaultValue={item?.lastPrice}
                            characters={9}
                            decimal={
                              -Math.ceil(
                                Math.log10(
                                  Number(item?.minPriceMovement ?? "0.01")
                                )
                              )
                            }
                          />
                        </span>
                      </td>
                      <td className={cx("change-time")}>
                        <div>
                          {getIconPriceChange(item?.priceChangePercent)}
                        </div>
                        <span
                          className={cx(getValueClassName(item?.priceChange))}
                        >
                          {item?.priceChangePercent
                            ? formatPriceRoundDown(
                                Math.abs(item?.priceChangePercent),
                                2
                              )
                            : "--"}
                          %
                        </span>
                      </td>
                      <td className={cx("volume")}>
                        {formatPriceRoundDown(item?.quoteVolume || 0, 2)}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className={cx("no-data")}>
                  <div>
                    <BsExclamationCircle fill="lightgray" style={{ zoom: 2.5 }} />
                  </div>
                  {tab === "favourites" ? (
                    <>
                      {keywordSearch != "" ? (
                        <div className={cx("text-no-data")}>
                          {t("pair.no_result_found")}
                        </div>
                      ) : (
                        <div className={cx("text-no-data")}>
                          {t("pair.no_data")}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className={cx("text-no-data")}>
                      {t("pair.no_result_found")}
                    </div>
                  )}
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ChooseMarketModal;
