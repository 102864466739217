import BigNumber from 'bignumber.js';
import { getMarginBalance } from 'src/features/Market/components/MarginInformation/MarginInforCalculator';
import store from 'src/store/store';
import { balanceCoin, getAvailableBalance } from './balanceCalculator';
import { TypeTrade } from 'src/constants/common.constants';

let tickers = store.getState().ticker.tickers;
let leverageCache = store.getState().masterdataFuture.leverageMarginCache;
let instruments = store.getState().instrument.instrument;

store.subscribe(() => {
  tickers = store.getState().ticker.tickers;
  leverageCache = store.getState().masterdataFuture.leverageMarginCache;
  instruments = store.getState().instrument.instrument;
}); 

export const multiplierIns = (symbol: string) => {
  const instrumentFilter = instruments && instruments?.filter((item) => item.symbol === symbol);
  return instrumentFilter?.length > 0 ? instrumentFilter[0].multiplier : 100;
};

export default {
  getCurrentTicker: function (position: any) {
    const currentTicker = tickers?.find((ticker) => ticker.symbol === position?.symbol);
    return currentTicker;
  },

  getSideOfPosition: function (position: any) {
    return Number(position?.currentQty) > 0 ? 1 : -1;
  },

  getAllocatedMargin: function (position: any) {
    const currentTicker = this.getCurrentTicker(position);
    const size = Number(position?.currentQty);
    const markPrice = Number(currentTicker?.oraclePrice);
    const leverage =position?.leverage? Number(position?.leverage).toFixed():0;
    const isCoinM = position?.contractType === TypeTrade.COIN_M;
    let allocatedMargin = '--';

    if (isCoinM) {
      //  Allocated Margin for Cross position
      // = Size * Contract Multiplier / (Leverage * Mark price)

      // Allocated Margin for Isolated position
      // = Size * Contract Multiplier / (Leverage * Entry price) + Added Margin
      const multiplier = multiplierIns(position?.symbol);
      if (position?.isCross) {
        allocatedMargin = new BigNumber(size)
          .abs()
          .times(multiplier)
          .dividedBy(new BigNumber(leverage).times(markPrice))
          .toString();
      } else {
        allocatedMargin = new BigNumber(position?.positionMargin || 0).plus(position?.adjustMargin || 0).toString();
      }
    } else {
      // Allocated Margin for Cross position
      // = Size * Mark price / Leverage

      // Allocated Margin for Isolated position
      // = Size * Entry price / Leverage  + Added Margin
      if (position?.isCross) {
        allocatedMargin = new BigNumber(size).abs().times(markPrice).dividedBy(leverage).toString();
      } else {
        // allocatedMargin = new BigNumber(size)
        //   .abs()
        //   .times(entryPrice)
        //   .dividedBy(leverage)
        //   .plus(position?.adjustMargin || 0)
        //   .toString();
        allocatedMargin = new BigNumber(position?.positionMargin || 0).plus(position?.adjustMargin || 0).toString();
      }
    }

    return allocatedMargin;
  },

  getRealizedPNL: function (position: any, exitPrice: any) {
    const size = Number(position?.currentQty);
    const entryPrice = Number(position?.entryPrice);
    const side = this.getSideOfPosition(position);
    const isCoinM = position?.contractType === TypeTrade.COIN_M;
    let realizedPNL = '0';
    if (isCoinM) {
      // Realized PNL = Size * (1/ Entry price - 1/ Exit Price) * Side *  Contract Multiplier
      const multiplier = multiplierIns(position?.symbol);
      const inverseEntry = new BigNumber(1).dividedBy(entryPrice);
      const inverseExit = new BigNumber(1).dividedBy(exitPrice);
      realizedPNL = new BigNumber(size)
        .abs()
        .times(new BigNumber(inverseEntry).minus(inverseExit))
        .times(side)
        .times(multiplier)
        .toString();
    } else {
      // Realized PNL = Size * (Exit price - Entry Price) * Side
      realizedPNL = new BigNumber(size).abs().times(new BigNumber(exitPrice).minus(entryPrice)).times(side).toString();
    }
    return realizedPNL;
  },

  getUnrealizedPNL: function (position: any) {
    const currentTicker = this.getCurrentTicker(position);
    const size = Number(position?.currentQty);
    const markPrice = Number(currentTicker?.oraclePrice);
    const entryPrice = Number(position?.entryPrice);
    const side = this.getSideOfPosition(position);
    const isCoinM = position?.contractType === TypeTrade.COIN_M;
    let unrealizedPNL = '0';

    if (isCoinM) {
      // Unrealized PNL = Size * Contract Multiplier  *(1/ Entry price - 1/ Mark Price) * Side
      const inverseEntry = new BigNumber(1).dividedBy(entryPrice);
      const inverseMark = new BigNumber(1).dividedBy(markPrice);
      const multiplier = multiplierIns(position?.symbol);
      unrealizedPNL = new BigNumber(size)
        .abs()
        .times(multiplier)
        .times(new BigNumber(inverseEntry).minus(inverseMark))
        .times(side)
        .toString();
    } else {
      // Unrealized PNL = Size * (Mark price - Entry Price) * Side
      unrealizedPNL = new BigNumber(size)
        .abs()
        .times(new BigNumber(markPrice).minus(entryPrice))
        .times(side)
        .toString();
    }

    return unrealizedPNL;
  },

  // Unrealized ROE = Unrealized PNL / Allocated Margin * 100%
  getUnrealizedROE: function (position: any) {
    // const size = Number(position?.currentQty);
    // const entryPrice = Number(position?.entryPrice);
    // const leverage = Number(position?.leverage).toFixed();
    // const initMargin = new BigNumber(size).abs().times(entryPrice).dividedBy(leverage);
    const allocatedMargin = this.getAllocatedMargin(position);
    const unrealizedPNL = this.getUnrealizedPNL(position);
    const unrealizedROE = new BigNumber(allocatedMargin).eq('0')
      ? '--'
      : new BigNumber(unrealizedPNL).dividedBy(allocatedMargin).times(100);

    return unrealizedROE.toString();
  },

  // Margin ratio = Maintenance margin / Margin balance * 100%
  getMarginRatio: function (position: any) {
    let marginRatio = '--';

    const marginBalance =
      getMarginBalance(position?.isCross ? 'Cross' : 'Isolate', position?.asset, position?.symbol)?.toString() || '0';
    const maintenanceMargin = this.getMaintainAmountPosition(position);

    if (new BigNumber(marginBalance).lte(0)) {
      if (new BigNumber(maintenanceMargin).gt(0)) {
        marginRatio = '100.00';
      } else {
        marginRatio = '0.00';
      }
    } else {
      marginRatio = new BigNumber(maintenanceMargin).dividedBy(marginBalance).times(100).toString();
    }
    return marginRatio;
  },

  getMaxAddable: function (position: any, account: any, allPosition: any[]) {
    const filterIsolatedOrderMargin = allPosition?.filter((positon: any) => {
      return !positon.isCross;
    });
    const isolatedOrderMarginArray = filterIsolatedOrderMargin.map((position: any) =>
      this.getAllocatedMargin(position),
    );
    const totalIsolatedOrderMargin = isolatedOrderMarginArray.reduce(
      (prev, cur) => new BigNumber(prev).plus(cur),
      new BigNumber(0),
    );
    const filterCrossOrderMargin = allPosition?.filter((positon: any) => {
      return positon.isCross;
    });
    const crossMaintenanceMarginArray = filterCrossOrderMargin.map((position: any) => position?.maintainMargin);
    const totalCrossMaintenanceMargin = crossMaintenanceMarginArray.reduce(
      (prev, cur) => new BigNumber(prev).plus(cur),
      new BigNumber(0),
    );
    const unrealizedPNL = this.getUnrealizedPNL(position);
    const availableBalance = getAvailableBalance(position?.asset);
    const walletBalance = balanceCoin(position?.asset);
    const calculatorParam = new BigNumber(walletBalance)
      .plus(unrealizedPNL)
      .minus(totalIsolatedOrderMargin)
      .minus(totalCrossMaintenanceMargin);
    const maxAddable = BigNumber.minimum(calculatorParam, availableBalance).toString();
    return maxAddable;
  },

  getMaxRemovable: function (position: any) {
    const currentTicker = this.getCurrentTicker(position);
    const allocatedMargin = this.getAllocatedMargin(position);
    const side = this.getSideOfPosition(position);
    const size = new BigNumber(position?.currentQty).abs();
    const markPrice = Number(currentTicker?.oraclePrice);
    const entryPrice = Number(position?.entryPrice);
    const leverage = position?.leverage ? Number(position?.leverage).toFixed() : 0;
    const isCoinM = position?.contractType === TypeTrade.COIN_M;
    let maxRemovable;

    if (isCoinM) {
      const multiplier = multiplierIns(position?.symbol);
      const calculatorParamOfMin = new BigNumber(allocatedMargin)
        .plus(
          new BigNumber(size)
            .times(multiplier)
            .times(new BigNumber(new BigNumber(1).dividedBy(entryPrice)).minus(new BigNumber(1).dividedBy(markPrice)))
            .times(side),
        )
        .minus(new BigNumber(size).times(multiplier).dividedBy(new BigNumber(leverage).times(markPrice)));
      const calculatorParamOfMax = BigNumber.minimum(allocatedMargin, calculatorParamOfMin);
      maxRemovable = BigNumber.maximum(0, calculatorParamOfMax);
    } else {
      const calculatorParamOfMin = new BigNumber(allocatedMargin)
        .plus(new BigNumber(size).times(new BigNumber(markPrice).minus(entryPrice)).times(side))
        .minus(new BigNumber(markPrice).times(size).dividedBy(leverage));
      const calculatorParamOfMax = BigNumber.minimum(allocatedMargin, calculatorParamOfMin);
      maxRemovable = BigNumber.maximum(0, calculatorParamOfMax);
    }

    return maxRemovable.toString();
  },

  getMarginRateAndAmount: function (position: any) {
    let maintenanceMarginRate = 0,
      maintenanceAmount = 0;
    const currentTicker = this.getCurrentTicker(position);
    const markPrice = Number(currentTicker?.oraclePrice);
    const isCoinM = position?.contractType === TypeTrade.COIN_M;
    const multiplier = multiplierIns(position?.symbol) || 0;
    const cost = isCoinM
      ? markPrice
        ? new BigNumber(position?.currentQty).abs().times(multiplier).div(markPrice)
        : '0'
      : new BigNumber(position?.currentQty).abs().times(markPrice);

    if (leverageCache) {
      const listTierCurrent = leverageCache?.filter((item: any) => item?.symbol === position?.symbol);
      const listTierResult = listTierCurrent?.filter(
        (item: any) => Number(cost) <= item.max && Number(cost) >= item.min,
      );
      const listTierFinal = listTierResult?.length > 0 ? listTierResult[0] : listTierCurrent[listTierCurrent.length - 1];
      maintenanceMarginRate = listTierFinal?.maintenanceMarginRate / 100;
      maintenanceAmount = Number(listTierFinal?.maintenanceAmount);
    }

    return { maintenanceMarginRate, maintenanceAmount };
  },

  getLiquidationPrice: function (position: any, account: any, allPosition: any[], adjustMargin: string) {
    let liquidationPrice;

    const side = this.getSideOfPosition(position);
    const size = new BigNumber(position?.currentQty).abs();
    const entryPrice = Number(position?.entryPrice);
    const walletBalance = balanceCoin(position?.asset);
    const isCoinM = position?.contractType === TypeTrade.COIN_M;

    const { maintenanceAmount, maintenanceMarginRate } = this.getMarginRateAndAmount(position);

    if (isCoinM) {
      const multiplier = multiplierIns(position?.symbol);
      // (Size * MMR + Side * Size)
      const numerator = new BigNumber(size).times(maintenanceMarginRate).plus(new BigNumber(side).times(size));
      // Side * Size / Entry Price
      const denominator2 = new BigNumber(side).times(size).dividedBy(entryPrice);
      if (position?.isCross) {
        // (WB + cumB)
        const denominator1 = new BigNumber(walletBalance).plus(maintenanceAmount).dividedBy(multiplier);

        // Liquidation Price = numerator /(denominator1 + denominator2 )
        liquidationPrice = numerator.dividedBy(denominator1.plus(denominator2));
      } else {
        const positionMargin = new BigNumber(this.getAllocatedMargin(position)).minus(position?.adjustMargin);
        const addedMargin = adjustMargin ? adjustMargin : 0;
        // Size*MMR
        const numerator1 = new BigNumber(size).times(maintenanceMarginRate);
        // Side * Size
        const numerator2 = new BigNumber(side).times(size);
        // (Size * MMR + Side * Size)
        const resultNumerator = numerator1.plus(numerator2);
        // (Allocated Position Margin + cumB) / Contract Multiplier
        const denominator1 = new BigNumber(positionMargin).plus(addedMargin).plus(maintenanceAmount).div(multiplier);
        // Side * Size / Entry Price
        const denominator2 = new BigNumber(side).times(size).div(entryPrice);
        // denominator1 + denominator2
        const resultDenominator = denominator1.plus(denominator2);
        liquidationPrice = resultNumerator.dividedBy(resultDenominator);
      }
    } else {
      if (position?.isCross) {
        // Isolated Position Margin
        const isolatedMarginArray = allPosition.map((item: any) => {
          return !item?.isCross && item.asset === position.asset ? this.getAllocatedMargin(item) : 0;
        });

        const isolatedPositionMargin = isolatedMarginArray.reduce(
          (prev, cur) => new BigNumber(prev).plus(cur),
          new BigNumber(0),
        );

        // Maintenance Margin of all other contracts ( not isolated)
        const maintenanceMarginArray = allPosition.map((item: any) => {
          return item.symbol !== position?.symbol && item?.isCross && item.asset === position.asset
            ? this.getMaintainAmountPosition(item)
            : 0;
        });

        const totalMaintenanceMargin = maintenanceMarginArray.reduce(
          (prev, cur) => new BigNumber(prev).plus(cur),
          new BigNumber(0),
        );

        // Unrealized PNL of all other contracts ( not isolated)
        const unrealizedPNLArray = allPosition.map((item: any) => {
          return item.symbol !== position?.symbol && item?.isCross && item.asset === position.asset
            ? this.getUnrealizedPNL(item)
            : 0;
        });
        const totalUnrealizedPNL = unrealizedPNLArray.reduce(
          (prev, cur) => new BigNumber(prev).plus(cur),
          new BigNumber(0),
        );
        const param1 = new BigNumber(walletBalance)
          .minus(isolatedPositionMargin)
          .minus(totalMaintenanceMargin)
          .plus(totalUnrealizedPNL)
          .plus(maintenanceAmount)
          .minus(new BigNumber(side).times(size).times(entryPrice));
        const param2 = new BigNumber(new BigNumber(size).times(maintenanceMarginRate)).minus(
          new BigNumber(side).times(size),
        );
        liquidationPrice = new BigNumber(param1).dividedBy(param2);
      } else {
        const positionMargin = new BigNumber(this.getAllocatedMargin(position)).minus(position?.adjustMargin);
        const addedMargin = adjustMargin ? adjustMargin : 0;
        // Position Margin + cumB
        const numerator1 = new BigNumber(positionMargin).plus(addedMargin).plus(maintenanceAmount);
        // Side * Size * Entry price
        const numerator2 = new BigNumber(side).times(size).times(entryPrice);
        // numerator1 - numerator2
        const resultNumerator = numerator1.minus(numerator2);
        // Size * MMR
        const denominator1 = new BigNumber(size).times(maintenanceMarginRate);
        // Side * size
        const denominator2 = new BigNumber(side).times(size);
        // denominator1 - denominator2
        const resultDenominator = denominator1.minus(denominator2);
        liquidationPrice = resultNumerator.dividedBy(resultDenominator);
      }
    }

    return new BigNumber(liquidationPrice).lt(0) ? '0' : liquidationPrice.toString();
  },

  getMaintainAmountPosition: function (position: any) {
    const currentTicker = this.getCurrentTicker(position);
    const markPrice = Number(currentTicker?.oraclePrice);

    const { maintenanceAmount, maintenanceMarginRate } = this.getMarginRateAndAmount(position);

    let result = new BigNumber(0);
    const isCoinM = position?.contractType === TypeTrade.COIN_M;

    if (isCoinM) {
      // = Size * Contract Multiplier / Mark price * Maintenance Margin rate - Maintenance Amount"
      const multiplier = multiplierIns(position?.symbol);
      result = new BigNumber(position.currentQty)
        .abs()
        .times(multiplier)
        .times(maintenanceMarginRate)
        .dividedBy(markPrice)
        .minus(maintenanceAmount);
    } else {
      // = Size * Mark price * Maintenance Margin rate - Maintenance Amount
      result = new BigNumber(position.currentQty)
        .abs()
        .times(markPrice)
        .times(maintenanceMarginRate)
        .minus(maintenanceAmount);
    }

    return Number(result);
  },
};
