import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookieStorage, setNewOneCookieStorage } from "src/helpers/storage";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import Assets from "./Assets";
import { OrderSide, OrderType, listTab } from "./constant";
import axiosInstance from "src/helpers/config";
import { IPositionItem } from "./PositionRecord";
import * as S from "./styles/HistoryDashBoardStyle";
import HeaderHistoryDashBoard from "./Components/HeaderHistoryDashboard";
import { DECIMAL_TWO } from "src/components/cores/Tooltip";
import BigNumber from "bignumber.js";
import { isNumber } from "src/helpers/numberFormatter";
import PositionHistory from "./Components/ContentHistoryDashboard/PositionHistory";
import { noti } from "src/hooks/useNoti";
import {
  MOBILE_DIMENSION,
  RECORDS_PER_PAGE,
} from "src/constants/common.constants";
import { getPosition, IPosition, getAllPosition } from "src/services/position";
import { getContractType, getPositionDirection } from "./helper";
import { ClosePositionDialog } from "./dialog/ClosePositionDialog";
import NotLogin from "./Components/ContentHistoryDashboard/NotLogin";
import PositionOpenOrder from "src/features/PositionAndHistory/Components/ContentHistoryDashboard/OpenOrder";
import PositionOrderHistory from "src/features/PositionAndHistory/Components/ContentHistoryDashboard/OpenHistory";
import PositionTradingHistory from "src/features/PositionAndHistory/Components/ContentHistoryDashboard/TradeHistory";
import PositionTransactionHistory from "src/features/PositionAndHistory/Components/ContentHistoryDashboard/TransactionHistory";
import useIsMobile from "src/hooks/useIsMobile";
import MobilePositionHistory from "./Components/ContentHistoryDashboard/PositionHistory/Mobile";
import MobileOpenOrder from "./Components/ContentHistoryDashboard/OpenOrder/MobileOpenOrder";
import MobileOpenHistory from "./Components/ContentHistoryDashboard/OpenHistory/MobileOpenHistory";
import MobileTradeHistory from "./Components/ContentHistoryDashboard/TradeHistory/MobileTradeHistory";
import MobileTransactionHistory from "./Components/ContentHistoryDashboard/TransactionHistory/MobileTransactionHistory";

export const handleDirect = (type: string) => {
  const domain = process.env.REACT_APP_DOMAIN_SPOT;
  if (type === "login") {
    window.location.replace(`${domain}/login?destination=%2F`);
    // setNewOneCookieStorage("isExpired", false);
    // setNewOneCookieStorage(
    //   "access_token",
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI3IiwianRpIjoiNjViYjhiYzA5YTMyMDFjOGNiY2Y3MGE0NmY0OGJkMmVhMGFlN2YyY2FmNjk2MDQwYWQ0NTcxYTQwYmVhNWE1YWY1ZDZiODZiOGY1YjRkMjEiLCJpYXQiOjE3MzM4NDE5NzUuODQ1OTk1LCJuYmYiOjE3MzM4NDE5NzUuODQ1OTk4LCJleHAiOjE3MzM5MjgzNzUuODE4ODE2LCJzdWIiOiIxIiwic2NvcGVzIjpbIioiXX0.Yf22ltKPV-uOvx2oHrKTNNLi1Ol1CIyp7-ZvlJcau1v85xwojNa3CWnPYwFrCM9TXEzEEH6GmKg3WGD3jKi4ctZJao-zoqUsJI7l3f4X-nUiJe8IctcCLCEW6KzsoiU_Eus4XAozXMiTh5fNVLtoy2etcx_n2GFwJPLhudylwHqIOEbz8i_b9ncQSf8so5FS8fbzHevJjGkjlzf9BsFge_5psxd1qrQ79N2xWetSJXu0khIqGd2PF-KQmVD8ENG6yyOZoWholN8nzD6qPQhiwyLu_1DCnDKCMEt1EStI5Jv5VObta24UgUmF5SKxUJ7O2QIVnava_RZ9lPDaOYiVA8PC_YS7jt758RwVVScHzMZrS_ugpWCYYElRnHnMd520OlaJb62ktY26Rp11Yku4BCFtNMsRS4U-ikOQyknoJFx-sWRpf-UYsP7MuF4AZIP-mrsDglemSEmOLOjtpSnC81xNl2p12SFnc61PAfIfgTQM81r8tBcRselGVdfQpC-PyXoQpJhJCkxacDTDIT_dfF2zWkREhfvXyEVIn7vpfp3xnnvSO7c79rnRbSVyq3OxWxzUmG1MbCdxfKokw6AvTK-yqmPGA5Kedqw3S0ipEKCLapuzgbUCScZAmrk6lEOeZv5AMFlxk-Cj4pogoqatx_F_x-T-wesHBNaBeJuDtgc"
    // );
  } else {
    window.location.replace(`${domain}/register`);
    // alert("set register later");
  }
};

const HistoryDashboard: React.FC = () => {
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const userSetting = useAppSelector((state) => state.account.userSetting);
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const [rows, setRows] = useState<IPositionItem[]>([]);
  const listPositionRef = useRef<any>([]);
  const listPosition = useAppSelector((state) => state.position.positions);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const tickers = useAppSelector((state) => state.ticker.tickers);

  const [tab, setTab] = useState<number>(0);
  const [listPositionId, setListPositionId] = useState<number[]>([]);
  const [hideOtherPairsChecked, setHideOtherPairsChecked] =
    useState<boolean>(false);
  const [hideAssetChecked, setHideAssetChecked] = useState<boolean>(false);
  const [listTabs, setListTabs] = useState(listTab);
  const allPosition = useAppSelector((state) => state.position.allPosition);
  const allOrders = useAppSelector((state) => state.order.allOrders);
  const isCoinM = useAppSelector((state) => state.typeTrade.isCoinM);
  const token = getCookieStorage("access_token");
  const isExpired = getCookieStorage("isExpired");
  const isAuthenticated =
    isExpired === "false" && token && token !== "undefined";
  // &&
  // account &&
  // account[0]?.id;
  const { t } = useTranslation("common");
  const contractType = getContractType();
  const loadingInstrument = useAppSelector((state) => state.instrument.loading);
  const loadingTicker = useAppSelector((state) => state.ticker.loading);
  const loadingTradingRules = useAppSelector(
    (state) => state.masterdataFuture.loading
  );
  const [openClosePositionDialog, setOpenClosePositionDialog] =
    useState<boolean>(false);
  const [closePostionData, setClosePostionData] =
    useState<{ type: OrderType.market | OrderType.limit; price: string }>();
  const [currentPosition, setCurrentPosition] = useState<IPositionItem>();
  useEffect(() => {
    if (!isCoinM) setListTabs(listTab.slice(0, 5));
    else setListTabs(listTab);
  }, [isCoinM, allOrders]);
  useEffect(() => {
    if (listPosition) {
      const newListPosition = listPosition.map((position: any) => {
        const curTradingRule = tradingRulesCache?.filter(
          (item) => item?.symbol === position?.symbol
        );
        const decimalPrice =
          Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
        const decimalAmount =
          Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

        const currentTicker = tickers?.find(
          (ticker) => ticker.symbol === position.symbol
        );
        const findPosition = listPositionRef.current?.find(
          (item: IPositionItem) => Number(item.id) === Number(position.id)
        );
        const updatePosition = { ...position };

        const isFindCurTradingRule = curTradingRule.length > 0 ? true : false;

        const newLimitPrice =
          isFindCurTradingRule && currentTicker?.oraclePrice
            ? new BigNumber(currentTicker?.oraclePrice).toFixed(
                decimalPrice,
                BigNumber.ROUND_DOWN
              )
            : "NaN";

        if (findPosition && !isNumber(findPosition.limitPrice)) {
          updatePosition.limitPrice =
            findPosition.limitPrice !== "" ? newLimitPrice : "";
        } else if (findPosition && isNumber(findPosition.limitPrice)) {
          updatePosition.limitPrice = findPosition.limitPrice;
        } else {
          updatePosition.limitPrice = newLimitPrice;
        }

        const newPositionSize = isCoinM
          ? new BigNumber(position.currentQty)
              .abs()
              .toFixed(0, BigNumber.ROUND_DOWN)
          : isFindCurTradingRule
          ? new BigNumber(position.currentQty)
              .abs()
              .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
          : "NaN";

        if (findPosition && !isNumber(findPosition.positionSize)) {
          updatePosition.positionSize =
            findPosition.positionSize !== "" ? newPositionSize : "";
        } else if (findPosition && isNumber(findPosition.positionSize)) {
          const currentPositionSize = isCoinM
            ? new BigNumber(findPosition.currentQty)
                .abs()
                .toFixed(0, BigNumber.ROUND_DOWN)
            : isFindCurTradingRule
            ? new BigNumber(findPosition.currentQty)
                .abs()
                .toFixed(decimalAmount, BigNumber.ROUND_DOWN)
            : "NaN";
          updatePosition.positionSize =
            Number(currentPositionSize) === Number(newPositionSize)
              ? findPosition.positionSize
              : newPositionSize;
        } else {
          updatePosition.positionSize = newPositionSize;
        }

        updatePosition.orders = [];
        const findTakeProfitOrder = allOrders?.find(
          (order) =>
            Number(order.id) === Number(updatePosition?.takeProfitOrderId)
        );
        const findStopLossOrder = allOrders?.find(
          (order) =>
            Number(order.id) === Number(updatePosition?.stopLossOrderId)
        );
        if (findTakeProfitOrder) {
          updatePosition.orders.push(findTakeProfitOrder);
        }
        if (findStopLossOrder) {
          updatePosition.orders.push(findStopLossOrder);
        }

        return updatePosition;
      });
      setRows(newListPosition);
      listPositionRef.current = newListPosition;
    }
  }, [listPosition, account[0]?.id, allOrders, tickers, tradingRulesCache]);

  const renderValue = (value: number | undefined) => {
    if (!value) return "";
    if (
      !isAuthenticated ||
      loadingInstrument ||
      loadingTicker ||
      loadingTradingRules
    )
      return 0;
    if (value === 1) return allPosition.length;
    if (value === 2) return allOrders.length;
  };

  const handleChange = (event: any) => {
    if (tab !== 4) {
      if (tab === 5) {
        setHideAssetChecked(event.target.checked);
      } else {
        setHideOtherPairsChecked(event.target.checked);
      }
    }
  };
  const handleOpenDialog = (
    type: OrderType.market | OrderType.limit,
    position: IPositionItem
  ) => {
    setClosePostionData({
      type,
      price: type === OrderType.limit ? position.limitPrice : "",
    });
    setOpenClosePositionDialog(true);
    setCurrentPosition(position);
  };
  const handleChangeLimitPrice = (value: string, position: IPositionItem) => {
    const updatePosition = { ...position };
    updatePosition.limitPrice = value;
    const newListPosition = rows.map((row) => {
      return Number(row.id) === Number(position.id) ? updatePosition : row;
    });
    setRows(newListPosition);
    listPositionRef.current = newListPosition;
  };

  const handleChangePositionSize = (value: string, position: IPositionItem) => {
    const updatePosition = { ...position };
    updatePosition.positionSize = value;
    const newListPosition = rows.map((row) => {
      return Number(row?.id) === Number(position?.id) ? updatePosition : row;
    });
    setRows(newListPosition);
    listPositionRef.current = newListPosition;
  };

  const handleClosePosition = async (
    type: OrderType.market | OrderType.limit,
    position: IPositionItem
  ) => {
    const payload: any = {
      positionId: position.id,
      // quantity: Number(position.positionSize),
      quantity: position.positionSize,
      type,
    };

    if (type === OrderType.limit) {
      payload.limitPrice = position.limitPrice;
    }

    try {
      setListPositionId((listId) => {
        const findId = listId?.find((id) => Number(id) === Number(position.id));
        if (!findId) {
          const updateListId = [...listId, Number(position.id)];
          return updateListId;
        }
        return listId;
      });
      const response: any = await axiosInstance.post(
        "/positions/close",
        payload
      );
      setListPositionId((listId) => {
        const filterId = listId?.filter(
          (id) => Number(id) !== Number(position.id)
        );
        return filterId;
      });
      if (response?.code === 200) {
        noti.success({ title: `${t("order.noti.order_submitted")}` });
        dispatch(
          getPosition({ page: 1, size: RECORDS_PER_PAGE, contractType })
        );
        dispatch(getAllPosition(contractType));
      }
      if (
        response.status === 400 &&
        response?.data?.info?.message === "Current quantity not enough"
      ) {
        noti.error({ title: `${t("order.noti.invalid_order_quantity")}` });
      }
    } catch (error) {
      console.log(error);
      setListPositionId((listId) => {
        const filterId = listId?.filter(
          (id) => Number(id) !== Number(position.id)
        );
        return filterId;
      });
    }
  };

  const handleCloseWithMarketPrice = (row: IPositionItem) => {
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item?.symbol === row?.symbol
    );
    const decimalAmount =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    if (!Number(row.positionSize)) {
      noti.error({ title: `${t("order.noti.blank_size")}` });
      return;
    }
    if (
      new BigNumber(row.positionSize).gt(
        new BigNumber(row.currentQty)
          .abs()
          .toFixed(isCoinM ? 0 : decimalAmount, BigNumber.ROUND_DOWN)
      )
    ) {
      noti.error({ title: `${t("order.noti.invalid_size")}` });
      return;
    }

    if (listPositionId.includes(Number(row.id))) return;

    return userSetting.marketOrder
      ? handleOpenDialog(OrderType.market, row)
      : handleClosePosition(OrderType.market, row);
  };

  const handleCloseWithLimitPrice = (row: IPositionItem) => {
    const currentTicker = tickers?.find(
      (ticker) => ticker?.symbol === row?.symbol
    );
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item?.symbol === row?.symbol
    );
    const decimalPrice =
      Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
    const decimalAmount =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;
    const floorRatio = Number(curTradingRule[0]?.floorRatio) / 100;
    const capRatio = Number(curTradingRule[0]?.limitOrderPrice) / 100;
    const minPrice = curTradingRule[0]?.minPrice;
    const maxPrice = curTradingRule[0]?.maxPrice;
    let newMinPrice = 0;
    let newMaxPrice = 0;

    if (!Number(row.limitPrice)) {
      noti.error({ title: `${t("order.noti.blank_price")}` });
      return;
    }
    if (!Number(row.positionSize)) {
      noti.error({ title: `${t("order.noti.blank_size")}` });
      return;
    }
    if (
      new BigNumber(row.positionSize).gt(
        new BigNumber(row.currentQty)
          .abs()
          .toFixed(isCoinM ? 0 : decimalAmount, BigNumber.ROUND_DOWN)
      )
    ) {
      noti.error({ title: `${t("order.noti.invalid_size")}` });
      return;
    }

    const positionDirection = getPositionDirection(row);
    const orderDirection =
      positionDirection === "Long" ? OrderSide.sell : OrderSide.buy;

    if (orderDirection === OrderSide.buy) {
      newMinPrice = Number(minPrice);
      newMaxPrice = Math.min(
        Number(maxPrice),
        Number(currentTicker?.oraclePrice) * (1 + capRatio)
      );
    } else {
      newMinPrice = Math.max(
        Number(minPrice),
        Number(currentTicker?.oraclePrice) * (1 - floorRatio)
      );
      newMaxPrice = Number(maxPrice);
    }

    if (Number(row.limitPrice) < newMinPrice) {
      noti.error({
        title: `${t("order.noti.min_order", { number: newMinPrice })}`,
      });
      return;
    } else if (Number(row.limitPrice) > newMaxPrice) {
      noti.error({
        title: `${t("order.noti.max_order", { number: newMaxPrice })}`,
      });
      return;
    }

    if (listPositionId.includes(Number(row.id))) return;

    return userSetting.limitOrder
      ? handleOpenDialog(OrderType.limit, row)
      : handleClosePosition(OrderType.limit, row);
  };
  const handleCloseDialog = () => {
    setOpenClosePositionDialog(false);
  };
  if (!isAuthenticated) {
    return <NotLogin />;
  }
  return (
    <S.Container>
      <HeaderHistoryDashBoard
        rows={rows}
        listTabs={listTabs}
        handleChange={handleChange}
        hideAssetChecked={hideAssetChecked}
        hideOtherPairsChecked={hideOtherPairsChecked}
        renderValue={renderValue}
        setTab={setTab}
        tab={tab}
      />
      {tab === 0 &&
        (isMobile ? (
          <MobilePositionHistory
            hideAssetChecked={hideAssetChecked}
            handleChange={handleChange}
            hideOtherPairsChecked={hideOtherPairsChecked}
            tab={tab}
            handleChangeLimitPrice={handleChangeLimitPrice}
            handleCloseWithMarketPrice={handleCloseWithMarketPrice}
            handleCloseWithLimitPrice={handleCloseWithLimitPrice}
          />
        ) : (
          <PositionHistory
            hideOtherPairs={hideOtherPairsChecked}
            rows={rows}
            allPosition={allPosition}
            tickers={tickers}
            tradingRulesCache={tradingRulesCache}
            account={account}
            handleChangeLimitPrice={handleChangeLimitPrice}
            handleChangePositionSize={handleChangePositionSize}
            handleCloseWithMarketPrice={handleCloseWithMarketPrice}
            handleCloseWithLimitPrice={handleCloseWithLimitPrice}
          />
        ))}

      {tab === 1 &&
        (isMobile ? (
          <MobileOpenOrder
            hideAssetChecked={hideAssetChecked}
            handleChange={handleChange}
            hideOtherPairsChecked={hideOtherPairsChecked}
            tradingRulesCache={tradingRulesCache}
            tab={tab}
            account={account}
          />
        ) : (
          <PositionOpenOrder
            hideOtherPairs={hideOtherPairsChecked}
            tickers={tickers}
            tradingRulesCache={tradingRulesCache}
            account={account}
            allPosition={allPosition}
          />
        ))}

      {tab === 2 &&
        (isMobile ? (
          <MobileOpenHistory />
        ) : (
          <PositionOrderHistory hideOtherPairs={hideOtherPairsChecked} />
        ))}

      {tab === 3 &&
        (isMobile ? (
          <MobileTradeHistory />
        ) : (
          <PositionTradingHistory hideOtherPairs={hideOtherPairsChecked} />
        ))}

      {tab === 4 &&
        (isMobile ? (
          <MobileTransactionHistory />
        ) : (
          <PositionTransactionHistory />
        ))}

      {tab === 5 && <Assets hideAsset={hideAssetChecked} />}
      <ClosePositionDialog
        closePostionData={closePostionData}
        data={currentPosition}
        handleCloseDialog={handleCloseDialog}
        handleConfirm={handleClosePosition}
        openDialog={openClosePositionDialog}
      />
    </S.Container>
  );
};

export default HistoryDashboard;
