import { Pagination } from "@mui/material";
import classNames from "classnames/bind";
import BigNumber from "bignumber.js";
// import moment from 'moment';
import React, { useEffect, useState } from "react";
// import {
//   garbageIcon,
//   garbageIconDark,
//   noData,
//   noDataDark,
//   iconInfoLight,
//   iconInfoDark,
//   usdmProfile,
//   usdmProfileDark,
// } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { SelectFilterCancelOrder } from "src/features/Orders/SelectOrderCancel";
import { SelectFilterSideOrder } from "src/features/Orders/SelectOrderSide";
import {
  formatOrderEnum,
  formatPriceRoundDown,
} from "src/helpers/numberFormatter";
import { SelectFilterTypeOrder } from "src/features/Orders/SelectOrderType";
import { IOrder, OrderStatus } from "src/interfaces/order";
import { Instrument } from "src/services/instrument";
import { cancelOrder, cancelAllOrder, getOpenOrder } from "src/services/order";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import OrderTitle from "../OrderTitle";
import styles from "../Orders.module.scss";
import { SelectFilterSymbolOrder } from "../SelectOrderSymbol/index";
import FilterMobile from "../FilterMobile";
import {
  OPEN_ORDER_TABLE_HEAD,
  fakeDataCancel,
  dataOrder,
  fakeDataSide,
} from "../constant";
import { CancelOrderDialog } from "src/features/PositionAndHistory/dialog/CancelOrderDialog";
import { CancelAllOrderModal } from "src/features/Orders/CancelAllOrderModal";
import { formatTimeDay, formatTimeHour } from "src/helpers/stringFormatter";
import { useWindowSize } from "src/hooks/useScreen";
import Loading from "src/components/Loading";
import { THEME_MODE } from "src/interfaces/theme";
import { OrderType } from "src/features/PositionAndHistory/constant";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import TooltipText from "src/components/cores/TooltipText";
import { Box } from "@material-ui/core";
import { TypeTrade } from "src/constants/common.constants";
import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import { useTranslation } from "react-i18next";
import {
  BsPersonBoundingBox,
  BsFillInfoSquareFill,
  BsFillInboxesFill,
} from "react-icons/bs";

const cx = classNames.bind(styles);

enum PriceTypeValue {
  last = "LAST",
  mark = "ORACLE",
}

export const RECORDS_PER_PAGE = 6;

const OpenOrderPage: React.FC = () => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const dispatch = useAppDispatch();
  const currentInstrument: Instrument | undefined = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const currentUser = useAppSelector((state) => state.account.account);
  const { orders, loading, totalPage } = useAppSelector((state) => state.order);
  const [tabActive, setTabActive] = useState(0);
  const [dataTable, setDataTable] = useState<IOrder[]>([]);
  const [valueCancelFilter, setValueCancelFilter] = useState<string>("");
  const [valueTypeFilter, setValueTypeFilter] = useState<string>("");
  const [valueSymbolFilter, setValueSymbolFilter] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [valueSideFilter, setValueSideFilter] = useState<string>("");
  const [isReset, setIsReset] = useState<number>(0);
  const size = useWindowSize();
  const [listCoin, setListCoin] = useState(instruments);
  const [openCancelAllDialog, setOpenCancelAllDialog] =
    useState<boolean>(false);
  const [totalItem, setTotalItem] = useState<number>(1);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const [tab, setTab] = useState(TypeTrade.USD_M);
  const { t } = useTranslation("common");

  useEffect(() => {
    history.pushState(tab, "");
  }, [tab]);

  useEffect(() => {
    if (orders) {
      setDataTable(orders);
    }
  }, [orders]);

  useEffect(() => {
    // if (orders.length !== 0) {
    //   setTotalItem(totalPage);
    // }
    setTotalItem(totalPage);
  }, [orders, currentUser[0]?.id, totalPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [tab]);

  const handleFilter = () => {
    setCurrentPage(1);
    setIsReset(0);
    getDataOpen();
  };
  const handleFilterMobile = () => {
    setIsReset(0);
  };
  const searchFilterMobile = () => {
    setCurrentPage(1);
    setIsReset(0);
    getDataOpen();
  };

  const getDataOpen = () => {
    if (currentInstrument?.symbol && currentUser[0]?.id) {
      dispatch(
        getOpenOrder({
          params: {
            page: currentPage,
            size: RECORDS_PER_PAGE,
          },
          payload: {
            type: valueTypeFilter ? valueTypeFilter : null,
            side: valueSideFilter ? valueSideFilter : null,
            symbol: valueSymbolFilter ? valueSymbolFilter : null,
            contractType: tab,
          },
        })
      );
    }
  };

  useEffect(() => {
    getDataOpen();
  }, [
    currentInstrument?.symbol,
    currentUser[0]?.id,
    // valueSideFilter,
    // isReset,
    // valueTypeFilter,
    // valueSymbolFilter,
    // valueCancelFilter,
    dispatch,
    currentPage,
    tab,
  ]);

  useEffect(() => {
    const list = instruments?.filter((item) =>
      tab === TypeTrade.COIN_M
        ? item.contractType === TypeTrade.COIN_M
        : item.contractType === TypeTrade.USD_M
    );
    setListCoin(list);
  }, [instruments, tab]);

  const renderPrice = (order: IOrder, currentPrice: string) => {
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === order?.symbol
    );
    const maxFiguresForPrice =
      Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={11}
        decimal={maxFiguresForPrice}
        defaultValue={currentPrice}
      />
    );
  };

  const renderQuantity = (order: IOrder) => {
    const currentInstrument = listCoin?.find(
      (instrument) => instrument?.symbol === order?.symbol
    );
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === order?.symbol
    );
    const maxFiguresForSize =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={7}
        decimal={tab === TypeTrade.COIN_M ? 0 : maxFiguresForSize}
        symbol={
          tab === TypeTrade.COIN_M ? "Cont" : currentInstrument?.rootSymbol
        }
        defaultValue={order?.quantity}
      />
    );
  };

  const renderAmount = (order: IOrder) => {
    if (!order.parentOrderId) {
      if (
        order.tpSLType === OrderType.takeProfitMarket ||
        (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
      ) {
        return t("order.close_position");
      }
    }

    return renderQuantity(order);
  };

  const renderFilledQuantity = (order: IOrder) => {
    const fillQuantity = new BigNumber(order.quantity ?? "0").minus(
      order.remaining ?? "0"
    );
    const currentInstrument = listCoin?.find(
      (instrument) => instrument?.symbol === order?.symbol
    );
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === order?.symbol
    );
    const maxFiguresForSize =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={7}
        decimal={tab === TypeTrade.COIN_M ? 0 : maxFiguresForSize}
        symbol={
          tab === TypeTrade.COIN_M ? "Cont" : currentInstrument?.rootSymbol
        }
        defaultValue={fillQuantity}
      />
    );
  };

  const renderTriggerConditions = (order: IOrder) => {
    if (!order.stopCondition) return "-";
    if (
      order.tpSLType === OrderType.stopLimit &&
      order.status === OrderStatus.ACTIVE
    )
      return "-";
    const currentInstrument = listCoin?.find(
      (instrument) => instrument.symbol === order.symbol
    );
    const tickSize = Number(currentInstrument?.tickSize) || "0.01";
    const precision = -Math.ceil(Math.log10(Number(tickSize)));

    const isTrailingStop = order.tpSLType === OrderType.trailingStop;
    const text = `${t("order.activation_price")}: ${formatPriceRoundDown(
      order?.activationPrice,
      precision
    )}. ${t("order.trigger_type")}: ${
      order?.trigger === PriceTypeValue.last
        ? t("order.last_price")
        : t("order.mark_price")
    }, ${t("order.callback_rate")}: ${order?.callbackRate ?? "0"}%.`;

    if (order.trigger === PriceTypeValue.last) {
      if (isTrailingStop) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {t("order.activation_price")}{" "}
            {order.stopCondition === "GT" ? ">=" : "<="}&nbsp;
            <TooltipNumber
              defaultValue={order?.activationPrice}
              characters={7}
              decimal={precision}
            />
            &nbsp;
            <TooltipText text={text}>
              <BsPersonBoundingBox
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.75 }}
              />
            </TooltipText>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {t("order.last_price")} {order.stopCondition === "GT" ? ">=" : "<="}
            &nbsp;
            <TooltipNumber
              defaultValue={order?.tpSLPrice}
              characters={7}
              decimal={precision}
            />
          </div>
        );
      }
    } else if (order.trigger === PriceTypeValue.mark) {
      if (isTrailingStop) {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {t("order.activation_price")}{" "}
            {order.stopCondition === "GT" ? ">=" : "<="}&nbsp;
            <TooltipNumber
              defaultValue={order?.activationPrice}
              characters={7}
              decimal={precision}
            />
            &nbsp;
            <TooltipText text={text}>
              <BsPersonBoundingBox
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.75 }}
              />
            </TooltipText>
          </div>
        );
      } else {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {t("order.mark_price")} {order.stopCondition === "GT" ? ">=" : "<="}
            &nbsp;
            <TooltipNumber
              defaultValue={order?.tpSLPrice}
              characters={7}
              decimal={precision}
            />
          </div>
        );
      }
    }
  };

  const handleCancelOrder = async (orderSelectedToCancel: IOrder) => {
    await dispatch(cancelOrder(orderSelectedToCancel?.id));
  };

  const handleCloseCancelAllDialog = () => {
    setOpenCancelAllDialog(false);
  };

  const handleCancelAllOrder = async () => {
    handleCloseCancelAllDialog();
    await dispatch(
      cancelAllOrder({ type: valueCancelFilter, contractType: tab })
    );
  };

  const renderTime = (order: IOrder) => {
    // if (
    //   order.tpSLType === OrderType.takeProfitMarket ||
    //   (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
    // ) {
    //   return formatTime(order.updatedAt);
    // }

    // return formatTime(order.createdAt);
    if (!order.createdAt) return "--";
    return (
      <div>
        <div>{formatTimeDay(order.createdAt)}</div>
        <div className={cx("describe")}>{formatTimeHour(order.createdAt)}</div>
      </div>
    );
  };

  const renderTypeOrder = (order: IOrder) => {
    if (
      order.tpSLType === OrderType.stopLimit &&
      order.status === OrderStatus.ACTIVE
    ) {
      return t(formatOrderText(order.type));
    }

    if (order.tpSLType === OrderType.takeProfitMarket) {
      return t("order.take_profit");
    }
    if (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder) {
      return t("order.stop_loss");
    }

    return t(formatOrderText(order.tpSLType || order.type));
  };

  const renderReduceOnly = (order: IOrder) => {
    return order.isReduceOnly ? t("order.yes") : t("order.no");
  };

  return (
    <div className={cx("orders-wrapper")}>
      {/* <OrderTitle
        title={t("order.future_order")}
        label={t("order.open_orders")}
      /> */}

      <div className={cx("open-order-details")}>
        {/* {TAB_DETAILS.map((item, i) => (
          <div
            key={i}
            className={cx('tab-details', tabActive === i && 'tab-details-active')}
            onClick={() => handleOnClickChooseTab(i)}
          >
            {item}
          </div>
        ))} */}
        <div className={cx("tabs-area")}>
          <div
            onClick={() => {
              setTab(TypeTrade.USD_M);
              setIsReset(1);
            }}
            className={cx(
              "tab-details",
              tab === TypeTrade.USD_M && "tab-details-active"
            )}
          >
            USDⓈ-M
          </div>
          <div
            onClick={() => {
              setTab(TypeTrade.COIN_M);
              setIsReset(1);
            }}
            className={cx(
              "tab-details",
              tab === TypeTrade.COIN_M && "tab-details-active"
            )}
          >
            COIN-M
          </div>
        </div>

        <div className={cx("open-order-filter-and-table-box")}>
          {tabActive === 0 && (
            <>
              <div className={cx("filter-area")}>
                <div
                  className={cx(
                    size[0] <= 1440 ? "filter-row-responsive" : "filter-row"
                  )}
                >
                  <SelectFilterTypeOrder
                    className={cx("filter-all")}
                    isReset={isReset}
                    handleFilter={handleFilter}
                    setValueTypeFilter={setValueTypeFilter}
                    dataList={dataOrder}
                    placeHolder={t("order.filter")}
                    label={"Open Orders"}
                    width={200}
                  />
                  <SelectFilterSymbolOrder
                    className={cx("filter-symbol")}
                    isReset={isReset}
                    handleFilter={handleFilter}
                    setValueSymbolFilter={setValueSymbolFilter}
                    isSearch
                    // dataList={fakeDataSymbol}
                    placeHolder={t("order.symbol")}
                    label={"Open Orders"}
                    dataList={listCoin}
                    width={200}
                  />
                </div>

                <SelectFilterSideOrder
                  className={cx("filter-side")}
                  isReset={isReset}
                  handleFilter={handleFilter}
                  setValueSideFilter={setValueSideFilter}
                  dataList={fakeDataSide}
                  placeHolder={t("order.side")}
                  width={200}
                />

                <div
                  className={cx(
                    size[0] <= 1440 ? "filter-row-res" : "filter-row1"
                  )}
                >
                  <div className={cx("filter-reset")}>
                    <button
                      className={cx("btn-reset")}
                      onClick={() => {
                        setIsReset(1);
                        setCurrentPage(1);
                        getDataOpen();
                      }}
                    >
                      {t("order.reset")}
                    </button>
                  </div>
                  <SelectFilterCancelOrder
                    className={cx("filter-cancel")}
                    isReset={isReset}
                    setIsReset={setIsReset}
                    setValueCancelFilter={setValueCancelFilter}
                    setIsOpenCancel={setOpenCancelAllDialog}
                    dataList={fakeDataCancel}
                    placeHolder={t("order.cancel_all_order")}
                    isCancel
                  />
                </div>
              </div>
              <div className={cx("filter-area-mobile")}>
                <SelectFilterCancelOrder
                  className={cx("filter-cancel")}
                  isReset={isReset}
                  setIsReset={setIsReset}
                  setValueCancelFilter={setValueCancelFilter}
                  setIsOpenCancel={setOpenCancelAllDialog}
                  dataList={fakeDataCancel}
                  placeHolder={t("order.cancel_all_order")}
                  isCancel
                />
                <FilterMobile
                  onReset={() => {
                    setIsReset(2);
                  }}
                  onSearch={searchFilterMobile}
                >
                  <div className={cx("filter-mobile-content")}>
                    <SelectFilterTypeOrder
                      className={cx("filter-all")}
                      isReset={isReset}
                      value={valueTypeFilter}
                      handleFilter={handleFilterMobile}
                      setValueTypeFilter={setValueTypeFilter}
                      dataList={dataOrder}
                      placeHolder={t("order.filter")}
                      label={"Open Orders"}
                      width={200}
                    />
                    <SelectFilterSymbolOrder
                      className={cx("filter-symbol")}
                      isReset={isReset}
                      value={valueSymbolFilter}
                      handleFilter={handleFilterMobile}
                      setValueSymbolFilter={setValueSymbolFilter}
                      isSearch
                      // dataList={fakeDataSymbol}
                      placeHolder={t("order.symbol")}
                      label={"Open Orders"}
                      dataList={listCoin}
                      width={200}
                    />
                    <SelectFilterSideOrder
                      className={cx("filter-side")}
                      isReset={isReset}
                      value={valueSideFilter}
                      handleFilter={handleFilterMobile}
                      setValueSideFilter={setValueSideFilter}
                      dataList={fakeDataSide}
                      placeHolder={t("order.side")}
                      width={200}
                    />
                  </div>
                </FilterMobile>
              </div>
              {
                <div style={{ position: "relative" }}>
                  <div className={cx("table-content")}>
                    <div
                      className={cx("no-data")}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "40%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {!loading && dataTable.length === 0 && (
                        <>
                          <BsFillInboxesFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            style={{ zoom: 5 }}
                          />
                          <div className={cx("no-data-desc")}>
                            {t("order.no_data")}
                          </div>
                        </>
                      )}

                      {loading && <Loading />}
                    </div>

                    <table className={cx("dex_table")}>
                      <thead>
                        <tr>
                          {OPEN_ORDER_TABLE_HEAD.map((head, index) => (
                            <th key={`${t(head.label)}${index}`}>
                              {t(head.label)}
                            </th>
                          ))}
                        </tr>
                      </thead>

                      <tbody
                        className={cx("dex_table__scrollbar", "overflow-tbody")}
                        style={{ maxHeight: 210 }}
                      >
                        {loading && <div style={{ height: 340 }}></div>}
                        {!loading && dataTable.length === 0 && (
                          <div style={{ height: 340 }}></div>
                        )}

                        {!loading && dataTable.length !== 0 && (
                          <>
                            {dataTable.map((data: IOrder, index: number) => (
                              <tr className={cx("row-content")} key={index}>
                                {/* <td className={cx("text-value")}>
                                  <div
                                    className={cx("icon-cancel")}
                                    onClick={(e) => {
                                      handleCancelOrder(data);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <BsFillInfoSquareFill
                                      fill={
                                        theme === THEME_MODE.LIGHT
                                          ? "black"
                                          : "white"
                                      }
                                      style={{ zoom: 1.75, cursor: "pointer" }}
                                    />
                                  </div>
                                </td> */}
                                <td className={cx("text-value")}>
                                  {renderTime(data)}
                                </td>
                                <td className={cx("text-value")}>
                                  <div className={cx("symbol")}>
                                    <div>
                                      {getSymbolName(data.symbol ?? "")}
                                    </div>
                                    <div className={cx("describe")}>
                                      {t("order.perpetual")}
                                    </div>
                                  </div>
                                </td>
                                <td className={cx("text-value")}>
                                  {data.type ? renderTypeOrder(data) : "-"}
                                </td>
                                <td
                                  className={cx(
                                    data.side == "BUY"
                                      ? "value-buy"
                                      : "value-sell"
                                  )}
                                >
                                  {data.side
                                    ? t(formatOrderText(data.side))
                                    : "-"}
                                </td>
                                <td className={cx("text-value")}>
                                  {data.price
                                    ? renderPrice(data, data?.price ?? "0")
                                    : "-"}
                                </td>
                                <td className={cx("text-value")}>
                                  {renderAmount(data)}
                                </td>
                                <td className={cx("text-value")}>
                                  {renderFilledQuantity(data)}
                                </td>
                                <td className={cx("text-value")}>
                                  {Number(data.tpSLPrice) ||
                                  Number(data.activationPrice)
                                    ? renderTriggerConditions(data)
                                    : "-"}
                                </td>

                                <td className={cx("text-value")}>
                                  {renderReduceOnly(data)}
                                </td>
                                <td className={cx("text-value")}>
                                  {data?.isPostOnly
                                    ? t("order.yes")
                                    : t("order.no")}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className={cx("table-content-mobile")}>
                    <div
                      className={cx("no-data")}
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "40%",
                        transform: "translate(-50%, -50%)",
                      }}
                    >
                      {!loading && dataTable.length === 0 && (
                        <>
                          <BsFillInboxesFill
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                            style={{ zoom: 2 }}
                          />
                          <div className={cx("no-data-desc")}>
                            {t("order.no_data")}
                          </div>
                        </>
                      )}

                      {loading && <Loading />}
                    </div>
                    {dataTable.map((data: IOrder, index: number) => (
                      <div className={cx("content-item-mobile")} key={index}>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[0].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {renderTime(data)}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[1].label)}
                          </div>
                          <div className={cx("text-value")}>
                            <div className={cx("symbol")}>
                              <div>{getSymbolName(data.symbol ?? "")}</div>
                              <div className={cx("describe")}>
                                {t("order.perpetual")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[2].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {data.type ? renderTypeOrder(data) : "-"}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[3].label)}
                          </div>
                          <div
                            className={cx(
                              "text-value",
                              data.side == "BUY" ? "value-buy" : "value-sell"
                            )}
                          >
                            {data.side ? t(formatOrderText(data.side)) : "-"}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[4].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {data.price
                              ? renderPrice(data, data?.price ?? "0")
                              : "-"}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[5].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {renderAmount(data)}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[6].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {renderFilledQuantity(data)}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[7].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {Number(data.tpSLPrice) ||
                            Number(data.activationPrice)
                              ? renderTriggerConditions(data)
                              : "-"}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[8].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {renderReduceOnly(data)}
                          </div>
                        </div>
                        <div className={cx("item-box")}>
                          <div className={cx("label")}>
                            {t(OPEN_ORDER_TABLE_HEAD[9].label)}
                          </div>
                          <div className={cx("text-value")}>
                            {data?.isPostOnly ? t("order.yes") : t("order.no")}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {!loading && totalItem > 1 && (
                    <div className={cx("pagination")}>
                      <Pagination
                        page={currentPage}
                        onChange={(e, page) => setCurrentPage(page)}
                        count={totalItem}
                        variant="outlined"
                        shape="rounded"
                        className={cx("pagination-custom")}
                        sx={{
                          "& .MuiPaginationItem-root": {
                            color: "var(--color-value-1) !important",
                          },
                          "& .Mui-selected": {
                            backgroundColor:
                              "var(--color-background-pagination) !important",
                            border:
                              "1px solid var(--color-outline-pagination) !important",
                            color: "var(--color-text-pagination) !important",
                            borderRadius: "5px !important",
                          },
                          "& .MuiPagination-ul": {
                            flexWrap: "nowrap",
                          },
                        }}
                      />
                    </div>
                  )}
                </div>
              }
            </>
          )}
        </div>
      </div>

      <CancelOrderDialog
        cancelType={valueCancelFilter}
        handleCloseDialog={handleCloseCancelAllDialog}
        handleConfirm={handleCancelAllOrder}
        openDialog={openCancelAllDialog}
      />
    </div>
  );
};

export default OpenOrderPage;
