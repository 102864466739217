/* eslint-disable max-len */
import Dashboard from 'src/pages/Trading/Dashboard';
import PrivateRoute from 'src/routes/PrivateRoute';
import routeConstants from 'src/routes/route.constants';
import Maintenance from 'src/pages/Maintenance/Maintenance';
import NotFound from 'src/pages/Notfound/NotFound';
import { Orders } from 'src/pages/Orders';
import Trading from 'src/features/Trading/Trading';
import { CalculatorMain } from 'src/features/Market/components/Calculator/CalculatorMain';

const routers = {
  dashboard: {
    exact: true,
    path: routeConstants.DASHBOARD,
    component: Dashboard,
    route: PrivateRoute,
  },
  trade: {
    exact: true,
    path: `${routeConstants.TRADING}/:typeTrade/:instrument`,
    component: Dashboard,
    route: PrivateRoute,
  },
  history: {
    exact: true,
    path: routeConstants.ACCOUNT_HISTORY,
    component: History,
    route: PrivateRoute,
  },
  maintain: {
    exact: true,
    path: routeConstants.MAIN_TAIN,
    component: Maintenance,
    route: PrivateRoute,
  },
  notfound: {
    exact: false,
    path: routeConstants.NOT_FOUND,
    component: NotFound,
    route: PrivateRoute,
  },
  open_order: {
    exact: true,
    path: routeConstants.OPEN_ORDER,
    component: Orders,
    route: PrivateRoute,
  },
  webview_chart: {
    exact: true,
    path: routeConstants.WEBVIEW_CHART,
    component: Trading,
    route: PrivateRoute,
  },
  webview_calculator: {
    exact: true,
    path: routeConstants.WEBVIEW_CALCULATOR,
    component: CalculatorMain,
    route: PrivateRoute,
  },
};
export default routers;
