import React, { forwardRef } from "react";
import useAppTheme from "src/hooks/useAppTheme";
import { THEME_MODE } from "src/interfaces/theme";

const AsksIcon = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => {
    const { themeMode } = useAppTheme();
    return (
      <span
        style={{ display: "flex", alignItems: "center" }}
        ref={ref}
        {...props}
      >
        {themeMode == THEME_MODE.LIGHT ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M8.08301 3.33316C8.08301 2.96497 8.38149 2.6665 8.74968 2.6665H14.083C14.4512 2.6665 14.7497 2.96497 14.7497 3.33316C14.7497 3.70135 14.4512 3.99983 14.083 3.99983H8.74968C8.38149 3.99983 8.08301 3.70135 8.08301 3.33316ZM8.08301 5.99983C8.08301 5.63164 8.38149 5.33316 8.74968 5.33316H14.083C14.4512 5.33316 14.7497 5.63164 14.7497 5.99983C14.7497 6.36802 14.4512 6.6665 14.083 6.6665H8.74968C8.38149 6.6665 8.08301 6.36802 8.08301 5.99983ZM8.08301 9.99983C8.08301 9.63164 8.38149 9.33316 8.74968 9.33316H14.083C14.4512 9.33316 14.7497 9.63164 14.7497 9.99983C14.7497 10.368 14.4512 10.6665 14.083 10.6665H8.74968C8.38149 10.6665 8.08301 10.368 8.08301 9.99983ZM8.08301 12.6665C8.08301 12.2983 8.38149 11.9999 8.74968 11.9999H14.083C14.4512 11.9999 14.7497 12.2983 14.7497 12.6665C14.7497 13.0347 14.4512 13.3332 14.083 13.3332H8.74968C8.38149 13.3332 8.08301 13.0347 8.08301 12.6665Z"
              fill="#151717"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.75 3.66406C2.75 3.11178 3.19772 2.66406 3.75 2.66406H5.75C6.30228 2.66406 6.75 3.11178 6.75 3.66406V12.33C6.75 12.8823 6.30228 13.33 5.75 13.33H3.75C3.19772 13.33 2.75 12.8823 2.75 12.33V3.66406ZM4.08333 12V3.9974H5.41667V12H4.08333Z"
              fill="#F1493F"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M8.08301 3.33316C8.08301 2.96497 8.38149 2.6665 8.74968 2.6665H14.083C14.4512 2.6665 14.7497 2.96497 14.7497 3.33316C14.7497 3.70135 14.4512 3.99983 14.083 3.99983H8.74968C8.38149 3.99983 8.08301 3.70135 8.08301 3.33316ZM8.08301 5.99983C8.08301 5.63164 8.38149 5.33316 8.74968 5.33316H14.083C14.4512 5.33316 14.7497 5.63164 14.7497 5.99983C14.7497 6.36802 14.4512 6.6665 14.083 6.6665H8.74968C8.38149 6.6665 8.08301 6.36802 8.08301 5.99983ZM8.08301 9.99983C8.08301 9.63164 8.38149 9.33316 8.74968 9.33316H14.083C14.4512 9.33316 14.7497 9.63164 14.7497 9.99983C14.7497 10.368 14.4512 10.6665 14.083 10.6665H8.74968C8.38149 10.6665 8.08301 10.368 8.08301 9.99983ZM8.08301 12.6665C8.08301 12.2983 8.38149 11.9999 8.74968 11.9999H14.083C14.4512 11.9999 14.7497 12.2983 14.7497 12.6665C14.7497 13.0347 14.4512 13.3332 14.083 13.3332H8.74968C8.38149 13.3332 8.08301 13.0347 8.08301 12.6665Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.75 3.66406C2.75 3.11178 3.19772 2.66406 3.75 2.66406H5.75C6.30228 2.66406 6.75 3.11178 6.75 3.66406V12.33C6.75 12.8823 6.30228 13.33 5.75 13.33H3.75C3.19772 13.33 2.75 12.8823 2.75 12.33V3.66406ZM4.08333 12V3.9974H5.41667V12H4.08333Z"
              fill="#F1493F"
            />
          </svg>
        )}
      </span>
    );
  }
);

export default AsksIcon;
