import styled from "@emotion/styled";

export const Flex = styled.div`
  display: flex;
  width: 100%;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.chart.bgContainer};
  padding: 0 12px 6px;
  border-radius: 8px;

  .Orderbook {
    height: calc(100% - 48px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .orderbook-content {
      height: calc(100% - 46px);
      /* height: 100%; */

      .last_price_change {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .icon-change {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 5px;
        }

        .sub {
          display: flex;
          align-items: flex-end;
          position: relative;
          color: ${({ theme }) => theme.orderBook.colorSub};
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
        .icon-positive-value {
          path {
            fill: #00b277;
          }
        }
        .icon-negative-value {
          transform: rotate(180deg);
          path {
            fill: #e02d3c;
          }
        }

        .App-positive-value {
          color: ${({ theme }) => theme.orderBook.colorPositive};
        }

        .App-negative-value {
          color: ${({ theme }) => theme.orderBook.colorNegative};
        }
      }
      .buy-sell-rate {
        .rate-bar {
          width: 100%;
          display: flex;
          align-items: center;
          .rate-bar-item {
            height: 4px;
            border-radius: 2px;
            &.buy {
              background: #01bc8d;
            }
            &.sell {
              background: #f1493f;
            }
          }
        }
        .rate-value {
          margin-top: 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .rate-value-item {
            font-size: 12px;
            &.buy {
              color: #01bc8d;
            }
            &.sell {
              color: #f1493f;
            }
          }
        }
        @media only screen and (max-width: 700px) {
          margin-top: 6px;
        }
      }
    }

    .table-header {
      border-radius: 5px;
      display: block;
      width: 100%;
      text-align: left;
      border-spacing: 0px;

      > thead {
        position: relative;
        display: block;
        width: 100%;

        > tr {
          width: 100%;
          display: flex;

          > th {
            flex-basis: 100%;
            flex-grow: 2;
            color: ${({ theme }) => theme.colors.neutral};
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            white-space: nowrap;

            &:first-child {
              text-align: left;
            }
            &:nth-child(2),
            &:nth-child(3) {
              text-align: right;
            }
          }
        }
      }
    }
    .orderbook-body {
      display: flex;
      flex-direction: column;
      transition: 0.1s linear;
      gap: 12px;
      /* height: calc(100% - 20px); */
      height: auto;
      @media only screen and (max-width: 700px) {
        width: 100%;
        max-height: 380px;
        gap: 7px;
      }
    }

    .table-wrapper {
      border-radius: 5px;
      display: flex;
      flex: 1;

      .table {
        height: 100%;
        width: 100%;
        text-align: left;
        border-spacing: 0px;
        display: block;

        > thead {
          position: relative;
          display: block;
          width: 100%;

          > tr {
            width: 100%;
            display: flex;

            > th {
              flex-basis: 100%;
              flex-grow: 2;
              color: var(--text-orderbook);
              font-size: 40px;
              display: block;
              text-align: right;
              padding-right: 30px;
              white-space: nowrap;
              &:first-child {
                @media only screen and (max-width: 400px) {
                  text-align: left;
                }
              }
            }
          }
        }

        > tbody {
          display: flex;
          flex-direction: column;
          gap: 1px;
          position: relative;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          @media only screen and (max-width: 700px) {
            gap: 2px;
          }

          > tr {
            display: flex;
            /* margin-top: 2px; */

            > td {
              font-size: 12px;
              height: 20px;
              flex-basis: 100%;
              flex-grow: 2;
              display: flex;
              align-items: center;
              text-align: right;

              .row {
                position: absolute;
                right: 0;
                left: 0;
                display: flex;
                padding: 0 2px;

                > span {
                  flex-grow: 1;
                  flex-basis: 0;
                  &:nth-child(1) {
                    text-align: left;
                  }
                }
                .normal-value {
                  font-size: 12px;
                  color: ${({ theme }) => theme.orderBook.colorPrice};
                }
              }
            }
          }
        }
      }

      .table-row {
        position: relative;
        height: 22px;
        cursor: pointer;
        @media only screen and (max-width: 700px) {
          height: 17px;
        }

        .ordertable-overlay-left {
          position: absolute;
          border-radius: 6px;
          height: 22px;
          left: 0;
          @media only screen and (max-width: 700px) {
            height: 17px;
          }
        }

        .ordertable-overlay-right {
          position: absolute;
          border-radius: 6px;
          height: 22px;
          right: 0;
          @media only screen and (max-width: 700px) {
            height: 17px;
          }
        }
      }

      .row-odd {
        background-color: var(--color-row-odd);
      }
    }

    .table-wrapper-expand {
      height: calc(100% - 50px);
      /* max-height: 380px; */
      overflow-x: hidden;
      overflow-y: auto;
    }
  }

  //scroll bar

  .scrollbar::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: var(--background-color);
    border-radius: 20px;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: var(--color-scrollbar-ob);
    border-radius: 20px;
    height: 30px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: var(--background-color);
    border-radius: 20px;
  }

  .non-scrollbar {
    overflow: visible !important;
  }

  .Trade {
    height: calc(100% - 48px);
    overflow-y: auto;
    @media only screen and (max-width: 700px) {
      height: 380px;
    }

    .table-wrapper {
      margin: 5px 0px;
      border-radius: 5px;
      height: calc(100% - 30px);

      .table {
        display: block;
        height: 100%;
        width: 100%;
        text-align: left;

        > thead {
          position: relative;
          display: block;
          width: 100%;

          > tr {
            width: 100%;
            display: flex;

            > th {
              flex-basis: 100%;
              flex-grow: 2;
              display: block;
              text-align: left;

              color: ${({ theme }) => theme.colors.neutral};
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              white-space: nowrap;

              &:first-child {
                text-align: left;
              }
              &:nth-child(2),
              &:last-child {
                text-align: right;
              }
            }
          }
        }

        > tbody {
          display: block;
          position: relative;
          width: 100%;
          overflow-y: scroll;

          > tr {
            width: 100%;
            display: flex;
            margin-top: 2px;

            > td {
              color: ${({ theme }) => theme.orderBook.colorPrice};
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;

              flex-basis: 100%;
              flex-grow: 2;
              display: block;

              &:nth-child(2),
              &:nth-child(3) {
                color: var(--color-value-1);
                text-align: right;
              }
            }
          }
        }
      }

      .table-row {
        position: relative;
        display: flex;
        flex-direction: row;

        .ordertable-overlay {
          position: absolute;
          height: 100%;
          opacity: 0.2;
          right: 0;
        }

        > td {
          flex-grow: 1;
        }
      }

      .row-odd {
        background-color: var(--color-row-odd);
      }
    }
  }

  .Orderbook-head {
    display: flex;
    padding: 0px;
    border-radius: 5px;

    /* Text xl/Semibold */
    color: ${({ theme }) => theme.orderBook.colorLastPrice};
    font-family: "NotoSan-SemiBold";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;

    .icon-change {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
    }
  }

  .orderbook-mb10 {
    margin-bottom: 10px;
  }

  .orderbook-mt10 {
    margin-top: 10px;
  }

  /* .Orderbook-head:nth-child(1) {
    color: blue;
  } */
  .MuiTabs-scroller {
    .MuiTabs-flexContainer {
      gap: 12px;
      .MuiButtonBase-root {
        text-transform: capitalize;
        padding: 0;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        min-height: 36px;
        margin-top: 3px;
      }
    }
    .MuiTabs-indicator {
      bottom: 8px;
    }
  }
`;
export const Wrap = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`;

export const Orderbook = styled(Container)`
  /* flex-direction: column;
  gap: 12px; */
`;
