import React from 'react';
import { formatPriceRoundDown } from 'src/helpers/numberFormatter';

interface Props {
  customText?: any;
  defaultValue: string;
  symbol?: string;
  isCoinM: boolean;
}

const TextNumber: React.FC<Props> = ({ customText, defaultValue, symbol, isCoinM }) => {
  const formatNumberAndDecimal = (num: any) => {
    const isDecimal = Boolean(Number(num.toString().split('.')[1]));

    if (!isDecimal) {
      const numStr = num?.toString().split('.')[0];
      return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    let numStr = num?.toString();
    let beforeDecimal = numStr.split('.')[0];
    beforeDecimal = beforeDecimal.includes('-') ? beforeDecimal.split('-')[1] : beforeDecimal;
    const decimalIndex = numStr.indexOf('.');
    const afterDecimal = beforeDecimal.length > 6 ? 4 : 8;
    numStr = numStr.replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
    numStr = numStr.substring(0, decimalIndex + (afterDecimal + 1));
    const parts = numStr.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ','); // Add commas
    return parts.join('.');
  };

  const numberFinal = isCoinM ? formatPriceRoundDown(defaultValue, 0) : formatNumberAndDecimal(defaultValue);

  return (
    <div className={customText}>
      {numberFinal} <span>{symbol}</span>
    </div>
  );
};

export default TextNumber;
