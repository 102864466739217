// @ts-ignore
import io from 'socket.io-client';
var Buffer = require("buffer/").Buffer;
import eventBus from 'src/event/event-bus';
import {
  addNewTrades,
  getContractType,
  updateActiveOrders,
  updateActivePosition,
} from 'src/features/PositionAndHistory/helper';
import { onReceiveUpdates } from 'src/features/OrderbookTrade/orderbookHelper';
import {
  addNewRecentTrades,
  changeUpdateAt,
  clearMarketTrades,
  getMarketTrade,
} from 'src/features/OrderbookTrade/redux/MarketTrade.slice';
import { getCookieStorage } from 'src/helpers/storage';
import { IOrder } from 'src/interfaces/order';
import { getBalanceSpot, IAccount, setAccount, updateMode } from 'src/services/account';
import { Instrument } from 'src/services/instrument';
import { IPosition } from 'src/services/position';
import { ITicker, updateTickers } from 'src/services/ticker';
import { SocketEvent } from 'src/socket/SocketEvent';
import store from 'src/store/store';
import { noti } from 'src/hooks/useNoti';
import i18next from 'i18next';
import { OrderSide } from 'src/features/Market/Constant';
import i18n from 'src/i18n';
import { ITrade } from 'src/interfaces/trade';

let orderMode = store.getState().account.orderMode;
let account = store.getState().account.account;

store.subscribe(() => {
  orderMode = store.getState().account.orderMode;
  account = store.getState().account.account;
});
export class BaseSocket {
  private static instance: BaseSocket;
  // @ts-ignore
  private socket;
  private currentInstrument?: Instrument;

  public static getInstance(): BaseSocket {
    if (!BaseSocket.instance) {
      BaseSocket.instance = new BaseSocket();
    }
    return BaseSocket.instance;
  }

  public connect(): void {
    const accessToken = getCookieStorage('access_token');
    this.socket = io(process.env.REACT_APP_BASE_SOCKET, {
      query: {
        authorization: accessToken,
      },
      transports: ['websocket'],
    });
    this.listen24Ticker();
    this.listenBalance();
    this.listenPosition();
    this.listenOrders();
    this.listenAjustMode();
    this.listenAdjustMargin();
    this.listenNotifications();
    this.socket.on('reconnect', () => {
      if (this.currentInstrument) {
        const instrument = this.currentInstrument;
        this.currentInstrument = undefined;
        this.listenInstrumentEvents(instrument);
      }
    });
  }

  public reconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.connect();
    this.socket.on('connect', () => {
      if (this.currentInstrument) {
        const instrument = this.currentInstrument;
        this.currentInstrument = undefined;
        this.listenInstrumentEvents(instrument);
      }
    });
  }

  listenInstrumentEvents(instrument?: Instrument): void {
    if (instrument?.symbol === this.currentInstrument?.symbol) {
      return;
    }
    if (this.currentInstrument?.symbol && this.socket) {
      this.socket.off(`trades_${this.currentInstrument.symbol}`);
      this.socket.off(`orderbook_${this.currentInstrument.symbol}`);
      this.socket.emit('leave', this.currentInstrument.symbol);
    }
    this.currentInstrument = instrument;
    if (!this.currentInstrument?.symbol || !this.socket) {
      return;
    }
    this.socket.emit('join', this.currentInstrument.symbol);

    // this.socket.off(`trades_${this.currentInstrument.symbol}`);
    // this.socket.on(`trades_${this.currentInstrument.symbol}`, (data: ITrade[]) => {
    //   const symbol = store.getState().instrument.currentInstrument.symbol;
    //   if (data.length <= 0 || data[0].symbol !== symbol) {
    //     return;
    //   }
    //   eventBus.dispatch(SocketEvent.TradesCreated, data);
    //   store.dispatch(addNewRecentTrades(data.reverse()));
    //   addNewTrades(store, data);
    // });

    this.socket.off(`orderbook_${this.currentInstrument.symbol}`);
    this.socket.on(`orderbook_${this.currentInstrument.symbol}`, (data: string) => {
      const decodedData = Buffer.from(data, 'base64').toString('ascii');
      onReceiveUpdates(store, JSON.parse(decodedData));
      // console.log("🚀 ~ BaseSocket ~ this.socket.on ~ decodedData:", decodedData)
    });
  }

  listen24Ticker(): void {
    this.socket.on(`tickers`, (data: ITicker[]) => {
      store.dispatch(updateTickers(data));

      const symbol = store.getState().instrument.currentInstrument.symbol;
      const currentTicker = data?.find((ticker: ITicker) => ticker.symbol === symbol);

      if (!currentTicker) return;
      if (location?.pathname.includes('webview')) {
        eventBus.dispatch(SocketEvent.TradesCreated, currentTicker.trades);
        store.dispatch(addNewRecentTrades([...currentTicker.trades].reverse()));
      }

      const marketTrade = store.getState().marketTrade;

      if (currentTicker?.updateAt) {
          // console.log("43214321", currentTicker.updateAt, currentTicker?.lastUpdateAt);
          store.dispatch(changeUpdateAt(currentTicker.updateAt));
      }
      if (marketTrade.isReady) {
        if (marketTrade?.updateAt === currentTicker?.lastUpdateAt) {
          // console.log("222222", marketTrade.updateAt, currentTicker?.lastUpdateAt, marketTrade.updateAt === currentTicker?.lastUpdateAt);
          if (!currentTicker?.trades || currentTicker?.trades?.length <= 0) return;
          // console.log("3333333", currentTicker.trades);
          eventBus.dispatch(SocketEvent.TradesCreated, currentTicker.trades);
          store.dispatch(addNewRecentTrades([...currentTicker.trades].reverse()));
        } else {
          store.dispatch(clearMarketTrades());
          // console.log('444444')
          store.dispatch(getMarketTrade(symbol)).then((res: any) => {
            if (res.payload?.data?.length === 0 || !marketTrade.lastTradeId) return;

            const filterMarketTrade = res.payload?.data?.filter((trade: any) => {
              return Number(trade.id) > Number(marketTrade.lastTradeId);
            });

            if (filterMarketTrade?.length === 0) return;

            const mappingMarketTrade = filterMarketTrade.map((trade: any) => {
              const cloneTrade = { ...trade };
              cloneTrade.updatedAt = cloneTrade.createdAt;

              return cloneTrade;
            });

            eventBus.dispatch(SocketEvent.TradesCreated, [...mappingMarketTrade].reverse());
          });
        }
      } else {
        if (!currentTicker?.trades || currentTicker?.trades?.length <= 0) return;
        store.dispatch(addNewRecentTrades([...currentTicker.trades].reverse()));
      }
    });
  }

  listenBalance(): void {
    this.socket.on('balance', (data: IAccount) => {
      const index = account.findIndex((item) => item.asset === data.asset);
      if (index !== -1) {
        const updatedAccount = { ...account[index], balance: data.balance };
        const updatedAccounts = [...account];
        updatedAccounts[index] = updatedAccount;
        store.dispatch(setAccount(updatedAccounts));
      }
    });
  }

  listenPosition(): void {
    this.socket.on('position', async (position: IPosition) => {
      await updateActivePosition(store, position);
    });
  }

  listenOrders(): void {
    this.socket.on('orders', async (orders: IOrder[]) => {
      await updateActiveOrders(store, orders);
    });
  }

  listenAjustMode(): void {
    this.socket.on('adjust-leverage', async (data: any) => {
      const resources = i18next.getResource(i18n.language, 'common', 'transfer');
      if (this.currentInstrument?.symbol === data.symbol) {
        const isAjustLeverage = Number(orderMode.leverage) !== Number(data.leverage);

        if (data.status === 'SUCCESS') {
          store.dispatch(updateMode(data));
          noti.success({
            title: isAjustLeverage ? resources.leverage_adjusted_successfully : resources.margin_adjusted_successfully,
          });
        } else {
          noti.error({
            title: isAjustLeverage ? resources.leverage_adjusted_fail : resources.margin_adjusted_fail,
          });
        }
      }
    });
  }

  listenAdjustMargin(): void {
    const resources = i18next.getResource(i18n.language, 'common', 'order');
    this.socket.on('margin-position', (data: any) => {
      if (data.status === 'SUCCESS') {
        noti.success({
          title: resources.noti.adjust_margin,
        });
      } else {
        const isAddAmount = Number(data.assignedMarginValue) > 0;
        noti.error({
          title: isAddAmount ? resources.validate_add_amount : resources.validate_remove_amount,
        });
      }
    });
  }

  listenNotifications(): void {
    const resources = i18next.getResource(i18n.language, 'common', 'transfer');
    const resourcesOrder = i18next.getResource(i18n.language, 'common', 'order');
    this.socket.on(
      'notifications',
      (
        notifications: {
          type: string;
          title: string;
          amount?: string;
          event?: string;
          isHidden?: boolean;
          code?: string;
          side?: string;
          contractType?: string;
        }[],
      ) => {
        for (const notification of notifications) {
          if (notification?.amount) {
            if (notification.type === 'success') {
              noti.success({
                title: resources.transfer_success,
              });
              store.dispatch(getBalanceSpot());
            } else {
              noti.error({
                title: resources.transfer_fail,
              });
            }
          }
          if (notification.event === 'OrderCanceled') {
            const contractType = getContractType();
            if (notification.isHidden === false && notification.contractType === contractType) {
              if (notification?.code === 'E001') {
                noti.info({
                  title: resourcesOrder.noti.insufficient_balance,
                });
              } else {
                noti.info({
                  title:
                    notification.side === OrderSide.buy
                      ? resourcesOrder.noti.order_buy_cancel
                      : resourcesOrder.noti.order_sell_cancel,
                });
              }
            }
          }
        }
      },
    );
  }
}
