import { Typography } from "@mui/material";
import { IPositionItem } from "../../PositionRecord";
import { useAppSelector } from "src/store/hooks";
import { Instrument } from "src/services/instrument";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import { useState } from "react";
import { getValueClassName } from "src/helpers/numberFormatter";
import { getSymbolName } from "../../helper";

export default function GeneralInfo({
  generalData,
  tradingRulesCache,
  isCoinM,
  currentInstrument,
}: {
  generalData?: IPositionItem;
  tradingRulesCache: ITradingRuleCache[];
  isCoinM: boolean;
  currentInstrument?: Instrument;
}) {
  const tickers = useAppSelector((state) => state.ticker.tickers);
  const textPosition =
    getValueClassName(generalData?.currentQty) === "App-positive-value"
      ? "Long"
      : "Short";
  const currentTicker = tickers?.find(
    (ticker) => ticker.symbol === generalData?.symbol
  );

  const decimal = -Math.ceil(
    Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
  );
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === generalData?.symbol
  );
  const maxFiguresForPrice =
    Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;
  const maxFiguresForSize =
    Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;
  return (
    <div className="containerGeneralInfo">
      <div className="header">
        <span className="text-h-4">
          {getSymbolName(generalData?.symbol ?? "")}
        </span>
        <Typography style={{ margin: "0 8px", fontWeight: "bold" }}>
          ·
        </Typography>
        <span className="text-h-4">Cross</span>
        <div
          style={{ display: "flex", marginLeft: 10, alignItems: "flex-end" }}
        >
          <span className={getValueClassName(generalData?.currentQty)}>
            Close {textPosition}
          </span>
          <Typography
            style={{ margin: "0 8px", fontWeight: "bold" }}
            className="rate"
          >
            ·
          </Typography>
          <span className="rate">
            {Number(generalData?.leverage).toFixed().concat("x")}
          </span>
        </div>
      </div>
      <div className="container-list-info">
        <div className="container-info-item">
          <span className="title">Current price</span>
          <TooltipNumber
            characters={11}
            customText="value"
            decimal={decimal}
            defaultValue={currentTicker?.oraclePrice}
            symbol={generalData?.asset}
          />
        </div>
        <div className="container-info-item">
          <span className="title">Entry price</span>
          <TooltipNumber
            characters={11}
            customText="value"
            decimal={maxFiguresForPrice}
            defaultValue={generalData?.entryPrice}
            symbol={generalData?.asset}
          />
        </div>
        <div className="container-info-item">
          <span className="title">Position</span>
          <TooltipNumber
            defaultValue={Math.abs(Number(generalData?.currentQty))}
            characters={7}
            customText="value"
            decimal={isCoinM ? 0 : maxFiguresForSize}
            symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
          />
        </div>
        <div className="container-info-item">
          <span className="title">Max Close</span>
          <TooltipNumber
            defaultValue={Math.abs(Number(generalData?.currentQty))}
            characters={7}
            customText="value"
            decimal={isCoinM ? 0 : maxFiguresForSize}
            symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
          />
        </div>
      </div>
    </div>
  );
}
