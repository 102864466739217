import styled from "@emotion/styled";

export const Layout = styled.div`
  background-color: ${({ theme }) => theme.colors.bgPrimary};
  /* height: 100vh; */
  .future-wrapper {
    padding: 8px;
    display: flex;
    gap: 8px;
    background-color: ${({ theme }) => theme.chart.bgTabTrading};
    @media only screen and (max-width: 700px) {
      flex-direction: column;
      background-color: ${({ theme }) => theme.colors.bgPrimary};
      gap: 0px;
      padding: 0px;
    }
    .future-wrapper-left {
      width: calc(100% - 410px);
      display: flex;
      flex-direction: column;
      gap: 8px;
      background-color: ${({ theme }) => theme.chart.bgTabTrading};
      @media only screen and (max-width: 1400px) {
        width: calc(100% - 350px);
      }
      @media only screen and (max-width: 900px) {
        width: calc(100% - 300px);
      }
      @media only screen and (max-width: 700px) {
        width: 100%;
        background-color: ${({ theme }) => theme.colors.bgPrimary};
        // overflow-x: hidden;
      }
    }
  }
  .dashboard {
    width: 100%;
    height: 592px;
    max-height: 592px;
    display: flex;
    gap: 8px;
    // padding: 0 8px;
  }
  .market-order {
    flex: 1 0 auto;
    width: 410px;
    border-right: 1px solid var(--color-line);
    background-color: var(--bg-order);
    @media only screen and (max-width: 1400px) {
      width: 350px;
    }
    @media only screen and (max-width: 1400px) {
      width: 300px;
    }
    @media only screen and (max-width: 700px) {
      width: 100%;
      display: none;
    }
  }
  .order-book {
    height: 100%;
    flex: 1 0 auto;
    width: 30%;
    max-width: 374px;
    @media only screen and (max-width: 700px) {
      width: 100%;
    }
  }
  .trading {
    flex: 1 0 auto;
    width: calc(70% - 8px);
    height: 100%;
    z-index: 0;
    @media only screen and (max-width: 700px) {
      width: 100%;
      height: 464px;
    }
  }
  .trading-wrapper {
    display: flex;
    flex-direction: column;
    .box_list_coint {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;
      background-color: var(--bg-list-coint);
      height: 32px;
      padding: 0 10px;
      @media only screen and (max-width: 700px) {
        display: none;
      }
      .icon {
        padding: 9px 12px;
        img {
          width: 14px;
          height: 14px;
        }
      }
      .list-icon {
        display: flex;
        align-items: center;
        .item {
          display: flex;
          align-items: center;
          gap: 4px;
          margin: 0 16px 0 8px;
          position: relative;
          .name {
            font-size: 12px;
            color: var(--color-text-1);
          }
          .rate {
            font-size: 12px;
          }
          .new {
            font-size: 9px;
            font-weight: 400;
            color: var(--color-text-2);
            position: absolute;
            right: -11px;
            top: -9px;
          }
        }
      }
    }
    .box_container {
      display: flex;
      width: 100%;
      position: relative;
      background-color: var(--bg-order-detail);
      .pair-statistics {
        display: flex;
        border-bottom: 4px solid var(--border-pair);
        align-items: center;
        overflow-y: hidden;
        overflow-x: hidden;
        width: 100%;
        // padding-top: 5px;
        @media only screen and (max-width: 770px) {
          display: block;
          border-bottom: 2px solid var(--border-pair);
        }
        @media only screen and (max-width: 300px) {
          border-right: none;
        }
        .statistics-wrapper {
          display: flex;
          .statistics-container {
            display: flex;
            margin-right: -400px;
            position: relative;
            padding: 4px 0;
            @media only screen and (max-width: 770px) {
              width: 100%;
              justify-content: space-between;
              flex-wrap: wrap;
              height: auto;
            }
            .statistic-item-price {
              margin-top: 14px;
              margin-bottom: 14px;
              width: fit-content;
              display: flex;
              justify-content: center;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              max-height: 35px;
              display: none;
              @media only screen and (max-width: 770px) {
                display: block;
                margin-top: 12px;
              }
              @media only screen and (max-width: 700px) {
                display: none;
              }
              .statistic-item-value-price {
                font-size: 12px;
                font-weight: 400;
                margin-right: 20px;
                text-align: left;
                color: #888888;
                @media only screen and (max-width: 770px) {
                  display: none;
                }
              }
            }
            .coint-item {
              margin: 2px 20px;
              width: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;
              text-align: left;
              @media only screen and (max-width: 770px) {
                margin: 2px 12px;
              }
              .icon {
                margin-top: 2px;
                img {
                  @media only screen and (max-width: 770px) {
                    width: 30px;
                  }
                }
              }
              .info {
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              .network {
                display: flex;
                cursor: pointer;
                align-items: center;
                .symbol {
                  font-size: 18px;
                  line-height: 24px;
                  font-weight: 600;
                  margin-left: 8px;
                  color: var(--color-value-1);
                  @media only screen and (max-width: 770px) {
                    font-size: 14px;
                  }
                }
                .drop-icon {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-top: 12px;
                  margin-left: 8px;
                  position: relative;
                }
              }
              .statistic-item-label-perpetual {
                font-size: 12px;
                line-height: 12px;
                color: var(--text-tab);
                margin-left: 8px;
              }
            }
            .statistic-item {
              margin: 14px 12px;
              width: fit-content;
              display: flex;
              justify-content: center;
              flex-direction: column;
              text-align: left;
              align-items: flex-start;
              max-height: 35px;
              @media only screen and (max-width: 770px) {
                margin: 2px 0;
                padding: 2px 10px;
                width: calc(33.33% - 6px);
              }
              .statistic-item-label {
                font-size: 12px;
                color: var(--text-tab);
                // margin-left: 12px;
                // margin-right: 12px;
                margin-bottom: 2px;
                white-space: nowrap;
                user-select: none;
                @media only screen and (max-width: 770px) {
                  // font-size: 10px;
                }
              }
              .statistic-item-value {
                @media only screen and (max-width: 770px) {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
              &.coint-choose {
                height: 32px;
                padding: 0 8px 0 20px;
                border-left: 1px solid #e5e5e5;
                @media only screen and (max-width: 770px) {
                  border: none;
                  padding: 0 12px;
                  width: auto;
                }
              }
            }
          }
        }
      }
      .statistic-prev {
        left: 0px;
        height: 92%;
        width: 68px;
        margin: 0px;
        left: 0px;
        border-radius: 4px;
        background-image: linear-gradient(
          -270deg,
          #fafafc 71.88%,
          rgba(250, 250, 252, 0) 100%
        );
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .statistic-next {
        background-image: var(--color-arrowNext);
        height: 92%;
        width: 68px;
        right: 0px;
        margin: 0px;
        border-radius: 4px;
        position: absolute;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        zoom: 2.2;
      }
    }
    .button-wrapper {
      width: 100%;
      padding: 8px 12px;
      border-bottom: 4px solid var(--border-pair);
      display: none;
      @media only screen and (max-width: 700px) {
        display: block;
      }
      .button-trade {
        /* width: calc(100% - 24px); */
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 500;
        color: var(--color-background-pagination);
        background-color: var(--color-slider-ranger);
        border-radius: 21px;
        -webkit-border-radius: 21px;
        -moz-border-radius: 21px;
        -ms-border-radius: 21px;
        -o-border-radius: 21px;
      }
    }
  }
  .footer-mobile {
    display: none;
    @media only screen and (max-width: 700px) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .trade-mobile-wrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    .trade-none {
      width: 100%;
      height: 35vh;
      background: #00000045;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
      justify-content: end;
      .blank {
        width: 100%;
        height: calc(35vh - 36px);
      }
      .trade-menu {
        width: 100%;
        padding: 18px 14px 0;
        border-top-left-radius: 16px 16px;
        border-top-right-radius: 16px 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: var(--bg-order);
        .trade-label {
          color: var(--color-text-1);
          font-weight: 500;
          font-size: 16px;
          line-height: 21px;
          white-space: nowrap;
          @media only screen and (max-width: 567px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
    .trade-form {
      width: 100%;
      height: 65vh;
      overflow: scroll;
      scrollbar-width: none;
      background: var(--bg-order);
    }
  }
  .hidden-mb {
    @media only screen and (max-width: 770px) {
      display: none !important;
    }
  }
  .hidden-pair-mb {
    display: none !important;
    @media only screen and (max-width: 770px) {
      display: block !important;
    }
  }
  .scroll-mb {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      scrollbar-width: thin;
      height: 0px;
    }
    @media only screen and (max-width: 770px) {
      justify-content: start !important;
    }
  }
  .flex {
    display: flex;
    white-space: nowrap;
    gap: 8px;
    .funding {
      // color: #db761a;
      @media only screen and (max-width: 770px) {
        // width: 56px;
        white-space: wrap;
      }
    }
    @media only screen and (max-width: 470px) {
      white-space: wrap;
      gap: 4px;
    }
  }
  .time-change {
    display: flex;
    .price-change {
      padding-right: 4px;
    }
  }
  .increase {
    color: #00b277;
  }
  .decrease {
    color: #e02d3c;
  }
  .price-change-percent {
    display: flex;
    .icon-status {
      padding-left: 10px;
    }
  }
  .volume-wrap {
    position: relative;
    &:hover {
      .tooltip-volume {
        display: block;
      }
    }
    .tooltip-volume {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: rgba(25, 25, 25, 0.8);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      align-self: center;
      z-index: 10;
      left: 14px;
      top: 0px;
    }
  }
  .change-percent {
    position: relative;
    &:hover {
      .tooltip-change-percent {
        display: block;
      }
    }
    .tooltip-change-percent {
      display: none;
      position: absolute;
      padding: 10px;
      white-space: nowrap;
      background-color: rgba(25, 25, 25, 0.8);
      border-radius: 8px;
      color: #ffffff;
      font-size: 14px;
      z-index: 10;
    }
  }
`;
