import { Dialog, makeStyles, withStyles } from '@material-ui/core';
// import Dialog from '@mui/material/Dialog';
// import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogContent from '@material-ui/core/DialogContent';
import classnames from 'classnames/bind';
import React, { useEffect } from 'react';
import ChooseMarketModal from 'src/features/Market/components/ChooseMarketModal/ChooseMarketModal';
import styles from './Market.module.scss';
import { Instrument } from 'src/services/instrument';
import { BaseSocket } from 'src/socket/BaseSocket';
import { useAppSelector } from 'src/store/hooks';
const cx = classnames.bind(styles);

interface MarketProps {
  open: boolean;
  handleClose: () => void;
}

const DialogContent = withStyles(() => ({
  root: {
    padding: 0,
    '&:first-child': {
      padding: 0,
    },
  },
}))(MuiDialogContent);

const Market: React.FC<MarketProps> = ({ open, handleClose }) => {
  const instrument: Instrument = useAppSelector((state) => state.instrument.currentInstrument);

  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: 'flex-start',
      justifyContent: 'left',
    },
    paper: {
      marginTop: '120px',
      marginLeft: '18px',
    },
  });
  const classes = useStyles();
  useEffect(() => {
    BaseSocket.getInstance().listenInstrumentEvents(instrument);
  }, [instrument]);

  const chooseInstrument = async () => {
    handleClose();
  };

  return (
    <Dialog
      maxWidth={'md'}
      open={open}
      onClose={handleClose}
      scroll="paper"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paper: classes.paper,
      }}
    >
      <DialogContent className={cx('modal-content')}>
        <ChooseMarketModal handleChooseOption={chooseInstrument} handleClose={handleClose} />
      </DialogContent>
    </Dialog>
  );
};

export default Market;
