import styled from "@emotion/styled";

export const MobileStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .container-des {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    .text-des {
      font-size: 10px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.text1};
    }
    .action-close-all {
      font-size: 12px;
      font-weight: normal;
      color: ${({ theme }) => theme.colors.colorTabActive};
    }
  }
  .container-info {
    display: flex;
    justify-content: space-between;
  }
  .color-buy {
    color: ${({ theme }) => theme.colors.greenChart};
  }
  .color-sell {
    color: ${({ theme }) => theme.colors.errorChart};
  }
  .container-item-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .title {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.text1};
      font-weight: 400;
    }
    .content {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.colorTab};
      font-weight: 400;
      text-align: left;
    }
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
  }

  .container-list {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    .container-item {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
      padding-bottom: 32px;
      margin-bottom: 24px;
    }
  }
  .text-color {
    color: ${({ theme }) => theme.colors.text1};
  }
`;
