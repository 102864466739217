import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'src/store/store';
import App from 'src/App';
import 'src/i18n';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { QueryClient, QueryClientProvider } from 'react-query';

i18next.init({
  interpolation: { escapeValue: false },
  fallbackLng: 'en',
});
const queryClient = new QueryClient();

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18next}>
        <SnackbarProvider
          maxSnack={5}
          autoHideDuration={5000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SnackbarProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'),
);
