import React, { forwardRef } from "react";
import useAppTheme from "src/hooks/useAppTheme";
import { THEME_MODE } from "src/interfaces/theme";

const BidsIcon = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => {
    const { themeMode } = useAppTheme();
    return (
      <div
        style={{ display: "flex", alignItems: "center" }}
        ref={ref}
        {...props}
      >
        {themeMode == THEME_MODE.LIGHT ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M7.58301 3.33316C7.58301 2.96497 7.88149 2.6665 8.24968 2.6665H13.583C13.9512 2.6665 14.2497 2.96497 14.2497 3.33316C14.2497 3.70135 13.9512 3.99983 13.583 3.99983H8.24968C7.88149 3.99983 7.58301 3.70135 7.58301 3.33316ZM7.58301 5.99983C7.58301 5.63164 7.88149 5.33316 8.24968 5.33316H13.583C13.9512 5.33316 14.2497 5.63164 14.2497 5.99983C14.2497 6.36802 13.9512 6.6665 13.583 6.6665H8.24968C7.88149 6.6665 7.58301 6.36802 7.58301 5.99983ZM7.58301 9.99983C7.58301 9.63164 7.88149 9.33316 8.24968 9.33316H13.583C13.9512 9.33316 14.2497 9.63164 14.2497 9.99983C14.2497 10.368 13.9512 10.6665 13.583 10.6665H8.24968C7.88149 10.6665 7.58301 10.368 7.58301 9.99983ZM7.58301 12.6665C7.58301 12.2983 7.88149 11.9999 8.24968 11.9999H13.583C13.9512 11.9999 14.2497 12.2983 14.2497 12.6665C14.2497 13.0347 13.9512 13.3332 13.583 13.3332H8.24968C7.88149 13.3332 7.58301 13.0347 7.58301 12.6665Z"
              fill="#151717"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.25 3.66406C2.25 3.11178 2.69772 2.66406 3.25 2.66406H5.25C5.80228 2.66406 6.25 3.11178 6.25 3.66406V12.33C6.25 12.8823 5.80228 13.33 5.25 13.33H3.25C2.69772 13.33 2.25 12.8823 2.25 12.33V3.66406ZM3.58333 12V3.9974H4.91667V12H3.58333Z"
              fill="#01BC8D"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <path
              d="M7.58301 3.33316C7.58301 2.96497 7.88149 2.6665 8.24968 2.6665H13.583C13.9512 2.6665 14.2497 2.96497 14.2497 3.33316C14.2497 3.70135 13.9512 3.99983 13.583 3.99983H8.24968C7.88149 3.99983 7.58301 3.70135 7.58301 3.33316ZM7.58301 5.99983C7.58301 5.63164 7.88149 5.33316 8.24968 5.33316H13.583C13.9512 5.33316 14.2497 5.63164 14.2497 5.99983C14.2497 6.36802 13.9512 6.6665 13.583 6.6665H8.24968C7.88149 6.6665 7.58301 6.36802 7.58301 5.99983ZM7.58301 9.99983C7.58301 9.63164 7.88149 9.33316 8.24968 9.33316H13.583C13.9512 9.33316 14.2497 9.63164 14.2497 9.99983C14.2497 10.368 13.9512 10.6665 13.583 10.6665H8.24968C7.88149 10.6665 7.58301 10.368 7.58301 9.99983ZM7.58301 12.6665C7.58301 12.2983 7.88149 11.9999 8.24968 11.9999H13.583C13.9512 11.9999 14.2497 12.2983 14.2497 12.6665C14.2497 13.0347 13.9512 13.3332 13.583 13.3332H8.24968C7.88149 13.3332 7.58301 13.0347 7.58301 12.6665Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.25 3.66406C2.25 3.11178 2.69772 2.66406 3.25 2.66406H5.25C5.80228 2.66406 6.25 3.11178 6.25 3.66406V12.33C6.25 12.8823 5.80228 13.33 5.25 13.33H3.25C2.69772 13.33 2.25 12.8823 2.25 12.33V3.66406ZM3.58333 12V3.9974H4.91667V12H3.58333Z"
              fill="#01BC8D"
            />
          </svg>
        )}
      </div>
    );
  }
);

export default BidsIcon;
