import classnames from "classnames/bind";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
// import { facebookIcon, logoMonas, teleIcon, twitterIcon, youtubeIcon } from 'src/assets/icon';
import { getLocalStorage } from "src/helpers/storage";
import useScreen from "src/hooks/useScreen";
import { getCookieStorage } from "src/helpers/storage";
import { AboutUs, Produtcs, Service, Support, Legal, Contact } from "./config";
import SelectBoxMobile from "./SelectBoxMobileFooter";
import { TypeTrade } from "src/constants/common.constants";

import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import SelectLanguage from "../cores/SelectLanguage";
import { languages } from "../NavBar/Constant";

import LogoFooterDark from "src/assets/img/logo-footer-dark.png";
import LogoFooterLight from "src/assets/img/logo-footer-light.png";
import IconXDark from "src/assets/img/footer-x-dark.png";
import IconXLight from "src/assets/img/footer-x-light.png";
import IconTeleDark from "src/assets/img/footer-tele-dark.png";
import IconTeleLight from "src/assets/img/footer-tele-light.png";
import IconFBDark from "src/assets/img/footer-fb-dark.png";
import IconFBLight from "src/assets/img/footer-fb-light.png";
import IconYTDark from "src/assets/img/footer-yt-dark.png";
import IconYTLight from "src/assets/img/footer-yt-light.png";

import languageLight from "src/assets/icon/navbar/languageLight.png";
import languageDark from "src/assets/icon/navbar/languageDark.png";
import themeLight from "src/assets/icon/navbar/themeLight.png";
import themeDark from "src/assets/icon/navbar/themeDark.png";

import styles from "./Footer.module.scss";

const cx = classnames.bind(styles);

const Footer: React.FunctionComponent = () => {
  const { t } = useTranslation("common");
  const theme = useAppSelector((state) => state.theme.themeMode);
  const { isMobile, isTablet } = useScreen();
  const [currentSelectBox, setCurrentSelectBox] = useState<any>();
  const masterdata = getLocalStorage("masterdata");
  const getLanguage = getCookieStorage("locale") || "en";
  const currentLanguage =
    languages.find((item) => item.value === getLanguage) || languages[0];
  const social_networks = masterdata?.social_networks || null;

  const settingSite = (key: string) => {
    if (!masterdata) return "";
    const resultSetting = masterdata?.settings?.filter(
      (item: any) => item.key === key
    );

    return resultSetting.length > 0 ? resultSetting[0].value : "";
  };

  const handleToggleSelectionBox = useCallback((key: any) => {
    setCurrentSelectBox((pre: any) => {
      if (pre === key) return;
      return key;
    });
  }, []);

  const convertOptions = (options: any) => {
    let newOption: any[] = [];
    for (let i = 0; i < options.length; i++) {
      const oneOption = {
        label: !options[i].isContact
          ? t(`footer.${options[i].label}`)
          : settingSite("contact_email"),
        onAction: async () => {
           !options[i].isBlank
             ? options[i].href && window.location.replace(options[i].href)
             : options[i].href && window.open(options[i].href, "_blank");
        },
      };
      newOption = [...newOption, oneOption];
    }
    return newOption;
  };

  return (
    <div className={cx("footer")}>
      <div className={cx("footer-info")}>
        <div className={cx("logo")}>
          <img
            src={theme === THEME_MODE.LIGHT ? LogoFooterLight : LogoFooterDark}
            alt="logo"
          />
          <div className={cx("logo-media")}>
            <img
              src={theme === THEME_MODE.LIGHT ? IconXLight : IconXDark}
              alt="logo"
            />
            <img
              src={theme === THEME_MODE.LIGHT ? IconTeleLight : IconTeleDark}
              alt="logo"
            />
            <img
              src={theme === THEME_MODE.LIGHT ? IconFBLight : IconFBDark}
              alt="logo"
            />
            <img
              src={theme === THEME_MODE.LIGHT ? IconYTLight : IconYTDark}
              alt="logo"
            />
          </div>
        </div>
        <div className={cx("links-all")}>
          <div className={cx("links-group")}>
            <div className={cx("links-title")}>{t(`footer.about_us`)}</div>
            {AboutUs.map(({ label, href, isBlank }) => (
              <div className={cx("links-item")} key={label}>
                {href ? (
                  <a
                    href={href}
                    className={cx("link-item-href")}
                    target={isBlank ? "_blank" : "_parent"}
                    rel="noreferrer noopener"
                  >
                    {t(`footer.${label}`)}
                  </a>
                ) : (
                  <div>{t(`footer.${label}`)}</div>
                )}
              </div>
            ))}
          </div>
          <div className={cx("links-group")}>
            <div className={cx("links-title")}>{t(`footer.products`)}</div>
            {Produtcs.map(({ label, href }) => (
              <div className={cx("links-item")} key={label}>
                {href ? (
                  <a
                    href={href}
                    className={cx("link-item-href")}
                    target="_parent"
                    rel="noreferrer noopener"
                  >
                    {t(`footer.${label}`)}
                  </a>
                ) : (
                  <div>{t(`footer.${label}`)}</div>
                )}
              </div>
            ))}
          </div>
          <div className={cx("links-group")}>
            <div className={cx("links-title")}>{t(`footer.services`)}</div>
            {Service.map(({ label, href }) => (
              <div className={cx("links-item")} key={label}>
                {href ? (
                  <a
                    href={href}
                    className={cx("link-item-href")}
                    target="_parent"
                    rel="noreferrer noopener"
                  >
                    {t(`footer.${label}`)}
                  </a>
                ) : (
                  <div>{t(`footer.${label}`)}</div>
                )}
              </div>
            ))}
          </div>
          <div className={cx("links-group")}>
            <div className={cx("links-title")}>{t(`footer.support`)}</div>
            {Support.map(({ label, href }) => (
              <div className={cx("links-item")} key={label}>
                {href ? (
                  <a
                    href={href}
                    className={cx("link-item-href")}
                    target="_parent"
                    rel="noreferrer noopener"
                  >
                    {t(`footer.${label}`)}
                  </a>
                ) : (
                  <div>{t(`footer.${label}`)}</div>
                )}
              </div>
            ))}
          </div>
          <div className={cx("links-group")}>
            <div className={cx("links-title")}>{t(`footer.legal`)}</div>
            {Legal.map(({ label, href }) => (
              <div className={cx("links-item")} key={label}>
                {href ? (
                  <a
                    href={href}
                    className={cx("link-item-href")}
                    target="_parent"
                    rel="noreferrer noopener"
                  >
                    {t(`footer.${label}`)}
                  </a>
                ) : (
                  <div>{t(`footer.${label}`)}</div>
                )}
              </div>
            ))}
            <div className={cx("links-title", "cu")}>
              {t(`footer.contact_us`)}
            </div>
            {Contact.map(({ label }) => (
              <div className={cx("links-item")} key={label}>
                <a
                  className={cx("link-item-href")}
                  target="_parent"
                  rel="noreferrer noopener"
                >
                  {settingSite("contact_email")}
                </a>
              </div>
            ))}
          </div>
        </div>
        <div className={cx("links-all-mobile")}>
          <SelectBoxMobile
            iconLink
            title={`${t("footer.about_us")}`}
            options={convertOptions(AboutUs)}
            isOpen={currentSelectBox === "about_us"}
            handleToggleSelection={() => handleToggleSelectionBox("about_us")}
          />
          <SelectBoxMobile
            title={`${t("footer.products")}`}
            options={convertOptions(Produtcs)}
            isOpen={currentSelectBox === "products"}
            handleToggleSelection={() => handleToggleSelectionBox("products")}
          />
          <SelectBoxMobile
            title={`${t("footer.services")}`}
            options={convertOptions(Service)}
            isOpen={currentSelectBox === "services"}
            handleToggleSelection={() => handleToggleSelectionBox("services")}
          />
          <SelectBoxMobile
            iconLink
            title={`${t("footer.support")}`}
            options={convertOptions(Support)}
            isOpen={currentSelectBox === "support"}
            handleToggleSelection={() => handleToggleSelectionBox("support")}
          />
          <SelectBoxMobile
            iconLink
            title={`${t("footer.legal")}`}
            options={convertOptions(Legal)}
            isOpen={currentSelectBox === "legal"}
            handleToggleSelection={() => handleToggleSelectionBox("legal")}
          />
          <SelectBoxMobile
            iconLink
            title={`${t("footer.contact_us")}`}
            options={convertOptions(Contact)}
            isOpen={currentSelectBox === "contact_us"}
            handleToggleSelection={() => handleToggleSelectionBox("contact_us")}
          />
        </div>
      </div>
      <div className={cx("footer-line")}>
        <div className={cx("line-name")}>2024 © client.dikek.com</div>
        <div className={cx("line-info")}>
          <div className={cx("line-item")}>
            <img
              src={theme === THEME_MODE.LIGHT ? languageLight : languageDark}
            />
            <span className={cx("item-text")}>
              {t(`header.${currentLanguage.value}`)}
            </span>
          </div>
          <div className={cx("line-item")}>
            <div className={cx("item-money")}>đ</div>
            <span className={cx("item-text")}>VND</span>
          </div>
          <div className={cx("line-item")}>
            <img src={theme === THEME_MODE.LIGHT ? themeLight : themeDark} />
            <span className={cx("item-text")}>
              {theme === THEME_MODE.LIGHT ? "Light Mode" : "Dark Mode"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
