import classnames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import HistoryDashboard from "src/features/PositionAndHistory/HistoryDashboard";
import MarginInformation from "src/features/Market/components/MarginInformation/MarginInformation";
import OrderForm from "src/features/Market/components/OrderForm";
import OrderbookTrade from "src/features/OrderbookTrade/OrderbookTrade";
import Trading from "src/features/Trading/Trading";
import ListCoint from "src/features/Trading/components/ListCoint";
import PairStatistics from "src/features/Trading/components/PairStatistics";
import PairStatisticsMobile from "src/features/Trading/components/PairStatisticMobile";
import { setCurrentInstrument } from "src/services/instrument";
import { useAppSelector } from "src/store/hooks";
import useIsMobile from "src/hooks/useIsMobile";
import { useTranslation } from "react-i18next";
import { THEME_MODE } from "src/interfaces/theme";
import {
  MarketDefault,
  MOBILE_DIMENSION,
  TypeTrade,
} from "src/constants/common.constants";
import { changeTypeTrade } from "src/services/typeTrade";
import * as S from "./style";
import closeLight from "src/assets/icon/navbar/closeLight.png";
import closeDark from "src/assets/icon/navbar/closeDark.png";
import DashboardMobile from "./DashboardMobile";
import FooterMobile from "./FooterMobile";

const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation("common");
  const [tradeMobile, setTradeMobile] = useState<boolean>(false);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const instrument = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<any>();
  // console.log("instrument ==>", instrument);
  const isMobile = useIsMobile(MOBILE_DIMENSION);

  // watch change instrument - url
  // console.log("fdsafdsa", instrument, currentInstrument);
  useEffect(() => {
    const instrumentNew = instrument?.filter(
      (item) => item.contractType === params.typeTrade
    );
    if (
      !params.instrument &&
      location.pathname.includes("futures") &&
      instrumentNew?.length &&
      instrumentNew[0].symbol &&
      currentInstrument.symbol === ""
    ) {
      const instru = instrumentNew?.find((e) =>
        params.typeTrade === TypeTrade.USD_M
          ? e.symbol === MarketDefault.BTCUSDT
          : e.symbol === MarketDefault.BTCUSDM
      );
      dispatch(setCurrentInstrument(instru || instrumentNew[0]));
      history.push(
        `/futures/${params.typeTrade}/${
          instru?.symbol || instrumentNew[0].symbol
        }`
      );
    } else if (params.instrument) {
      const instru = instrumentNew?.find((e) => e.symbol === params.instrument);
      if (instru) {
        dispatch(setCurrentInstrument(instru));
        history.push(`/futures/${params.typeTrade}/${instru.symbol}`);
      } else if (instrument.length > 0 && instrument[0].symbol) {
        // history.push(`${routeConstants.NOT_FOUND}`);
      }
    } else if (currentInstrument.symbol !== "") {
      history.push(`/futures/${params.typeTrade}/${currentInstrument.symbol}`);
    }

    if (params.typeTrade) {
      dispatch(changeTypeTrade(params.typeTrade === TypeTrade.COIN_M));
    }
  }, [instrument]);

  const renderChartOrderBook = () => {
    if (isMobile) {
      return <DashboardMobile />;
    } else {
      return (
        <div className={"dashboard"}>
          <div className={"trading"}>
            <Trading />
          </div>
          <div className={"order-book"}>
            <OrderbookTrade />
          </div>
        </div>
      );
    }
  };

  return (
    <S.Layout>
      <div className={"trading-wrapper"}>
        <ListCoint />
        <PairStatistics />
        <PairStatisticsMobile />
        <div className={"button-wrapper"}>
          <div className={"button-trade"} onClick={() => setTradeMobile(true)}>
            {t("header.trade")}
          </div>
        </div>
      </div>
      <div className={"future-wrapper"}>
        <div className={"future-wrapper-left"}>
          {renderChartOrderBook()}
          {!isMobile && <HistoryDashboard />}
        </div>
        <div className={"market-order"}>
          <OrderForm />
          <MarginInformation />
        </div>
        {isMobile && <HistoryDashboard />}
      </div>
      <div className="footer-mobile">
        <FooterMobile />
      </div>
      {tradeMobile && (
        <div className={"trade-mobile-wrapper"}>
          <div className={"trade-none"}>
            <div className={"blank"} onClick={() => setTradeMobile(false)} />
            <div className={"trade-menu"}>
              <div className={"trade-label"}>Open</div>
              <img
                alt="menu"
                className="menu-icon"
                src={theme === THEME_MODE.LIGHT ? closeLight : closeDark}
                onClick={() => setTradeMobile(false)}
              />
            </div>
          </div>
          <div className={"trade-form"}>
            <OrderForm />
          </div>
        </div>
      )}
    </S.Layout>
  );
};

export default Dashboard;
