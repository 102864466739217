import BigNumber from 'bignumber.js';
import { TypeTrade } from 'src/constants/common.constants';
import store from 'src/store/store';
import PositionCalculator from './PositionCalculator';

let allPositions = store.getState().position.allPosition;
let allOpenorder = store.getState().order.allOrders;
let account = store.getState().account.account;
let instruments = store.getState().instrument.instrument;

store.subscribe(() => {
  allPositions = store.getState().position.allPosition;
  allOpenorder = store.getState().order.allOrders;
  account = store.getState().account.account;
  instruments = store.getState().instrument.instrument;
});

export const balanceCoin = (asset: string) => {
  const balanceArr =
    account?.length > 0 && asset && account?.filter((item) => item && item?.asset.toLowerCase() === asset.toLowerCase());

  return balanceArr ? balanceArr[0]?.balance : '0';
};

// Available balance = WB - Position Margin - Order Margin + Unrealized PNL of Cross position
export const getAvailableBalance = (asset: any) => {
  const walletBalance = balanceCoin(asset);
  const assetFinal = asset && asset.toLowerCase();
  // Position Margin
  const marginPositionArray = allPositions.map((item: any) => {
    return item?.asset?.toLowerCase() === assetFinal ? new BigNumber(PositionCalculator.getAllocatedMargin(item)) : 0;
  });
  const totalMarginPosition = marginPositionArray.reduce(
    (prev, cur) => new BigNumber(prev).plus(cur),
    new BigNumber(0),
  );
  //Order Margin
  const marginOrderArray = allOpenorder.map((order: any) => {
    const instruFilter = instruments && instruments?.filter((item) => item.symbol === order?.symbol);
    const assetFilter =
      order.contractType === TypeTrade.COIN_M ? instruFilter[0]?.rootSymbol : instruFilter[0]?.quoteCurrency;
    return assetFilter?.toLowerCase() === assetFinal ? new BigNumber(order.cost || 0) : 0;
  });
  const totalMarginOrder = marginOrderArray.reduce((prev, cur) => new BigNumber(prev).plus(cur), new BigNumber(0));

  // Unrealized PNL of Cross position
  const PNLArray = allPositions.map((item: any) => {
    return item.isCross && item?.asset?.toLowerCase() === assetFinal
      ? new BigNumber(PositionCalculator.getUnrealizedPNL(item))
      : 0;
  });
  const totalPNL = PNLArray.reduce((prev, cur) => new BigNumber(prev).plus(cur), new BigNumber(0));

  const available = new BigNumber(walletBalance).minus(totalMarginPosition).minus(totalMarginOrder).plus(totalPNL);
  return available.lt(0) ? '0' : available.toString();
};

export const maxTransfer = (asset: any) => {
  const walletBalance = balanceCoin(asset);

  const assetFinal = asset && asset.toLowerCase();

  // Position Margin
  const marginPositionArray = allPositions.map((item: any) => {
    return item?.asset?.toLowerCase() === assetFinal ? new BigNumber(PositionCalculator.getAllocatedMargin(item)) : 0;
  });
  const totalMarginPosition = marginPositionArray.reduce(
    (prev, cur) => new BigNumber(prev).plus(cur),
    new BigNumber(0),
  );

  //Order Margin
  const marginOrderArray = allOpenorder.map((order: any) => {
    const instruFilter = instruments && instruments?.filter((item) => item.symbol === order?.symbol);
    const assetFilter =
      order.contractType === TypeTrade.COIN_M ? instruFilter[0]?.rootSymbol : instruFilter[0]?.quoteCurrency;
    return assetFilter.toLowerCase() === assetFinal ? new BigNumber(order.cost || 0) : 0;
  });
  const totalMarginOrder = marginOrderArray.reduce((prev, cur) => new BigNumber(prev).plus(cur), new BigNumber(0));

  const balanceCompare = new BigNumber(walletBalance).minus(totalMarginPosition).minus(totalMarginOrder);
  const maxTransfer = BigNumber.min(balanceCompare, getAvailableBalance(assetFinal));

  return Number(maxTransfer) < 0 ? 0 : maxTransfer.toString();
};
