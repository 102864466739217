import React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
// import { warningIcon, iconWarningWhite, iconCloseCommon } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { CancelLabel, CancelType } from "../../OpenOrder";
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import useIsMobile from "src/hooks/useIsMobile";
import { MOBILE_DIMENSION } from "src/constants/common.constants";
import { useTranslation } from "react-i18next";
import { BsHypnotize, BsFillXOctagonFill } from "react-icons/bs";

interface CancelOrderProps {
  cancelType: string;
  handleCloseDialog: () => void;
  handleConfirm?: () => void;
  openDialog: boolean;
}

const cx = classnames.bind(styles);

const useStyles = makeStyles({
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: "560px",
      background: "var(--color-background-modal)",
      borderRadius: "8px",
    },
    "& .MuiDialogTitle-root": {
      padding: "20px 23px 0px 40px",
    },
    "& .MuiDialogContent-root": {
      padding: "8px 40px",
      fontFamily: "inherit",
    },
    "& .MuiDialogActions-root": {
      padding: "0px 40px 20px 40px",
      fontFamily: "inherit",
    },
    "& .MuiTypography-root": {
      fontFamily: "inherit",
    },
  },
});

const useStylesMobile = makeStyles({
  root: {
    "& .MuiDialog-paperWidthSm": {
      width: "500px",
      background: "var(--color-background-modal)",
    },
    "& .MuiDialogTitle-root": {
      padding: "20px 15px 0px 15px",
    },
    "& .MuiDialogContent-root": {
      padding: "8px 15px",
    },
    "& .MuiDialogActions-root": {
      padding: "0px 15px 15px 15px",
    },
  },
});

export const CancelOrderDialog: React.FC<CancelOrderProps> = ({
  cancelType,
  openDialog,
  handleCloseDialog,
  handleConfirm,
}) => {
  const classes = useStyles();
  const classesMobile = useStylesMobile();
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const { t } = useTranslation("common");

  const renderText = () => {
    if (cancelType === CancelType.all) return `${t("cancel_open_orders")}`;
    if (cancelType === CancelType.limit) return `${t("cancel_limit_orders")}`;
    if (cancelType === CancelType.stopLimit)
      return `${t("cancel_stop_orders")}`;
  };

  const renderTextConfirm = () => {
    if (cancelType === CancelType.all)
      return `${t("order.cancel_open_confirm")}`;
    if (cancelType === CancelType.limit)
      return `${t("order.cancel_limit_confirm")}`;
    if (cancelType === CancelType.stopLimit)
      return `${t("order.cancel_stop_confirm")}`;
  };

  return (
    <Dialog
      open={openDialog}
      className={!isMobile ? classes.root : classesMobile.root}
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end">
          <Typography>
            <IconButton
              size={"small"}
              onClick={() => {
                handleCloseDialog();
              }}
            >
              <BsFillXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.5, cursor: "pointer" }}
              />
            </IconButton>
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className={cx("dialog-content")}>
        <Box display="flex" justifyContent="center">
          <BsHypnotize
            fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            style={{ zoom: 5, marginBottom: "3px" }}
          />
        </Box>
        <Box className={cx("content-wrapper")}>
          <Typography className={cx("dialog-title")}>{renderText()}</Typography>
          <Typography className={cx("dialog-desc")}>
            {renderTextConfirm()}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={cx("action-dialog")}>
        <Box
          display="flex"
          justifyContent="center"
          className={cx("button-wrapper")}
        >
          <Button className={cx("close-dialog")} onClick={handleCloseDialog}>
            {t("order.close")}
          </Button>
          <Button className={cx("confirm-close-btn")} onClick={handleConfirm}>
            {t("order.confirm")}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
