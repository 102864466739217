import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import Trading from "src/features/Trading/Trading";
import * as S from "./style";
import { THEME_MODE } from "src/interfaces/theme";
import { useAppSelector } from "src/store/hooks";

import ftAsset from "src/assets/icon/mobile/ftAsset.png";
import ftFutureDark from "src/assets/icon/mobile/ftFutureDark.png";
import ftFutureLight from "src/assets/icon/mobile/ftFutureLight.png";
import ftHome from "src/assets/icon/mobile/ftHome.png";
import ftMarket from "src/assets/icon/mobile/ftMarket.png";
import ftTrade from "src/assets/icon/mobile/ftTrade.png";

const DOMAIN_SPOT = process.env.REACT_APP_DOMAIN_SPOT || "";

const FooterDashboardMobile = () => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  const linkList = [
    { id: 0, icon: ftHome, link: DOMAIN_SPOT },
    { id: 1, icon: ftMarket, link: `${DOMAIN_SPOT}/market-overview` },
    { id: 2, icon: ftTrade, link: `${DOMAIN_SPOT}/spot-exchange/basic` },
    {
      id: 3,
      icon: theme === THEME_MODE.LIGHT ? ftFutureLight : ftFutureDark,
      isFuture: true,
    },
    { id: 4, icon: ftAsset, link: `${DOMAIN_SPOT}/funds/balances-wallet` },
  ];
  return (
    <S.Container>
      {linkList.map((v: any, i: number) => (
        <img
          alt=""
          src={v.icon}
          key={i}
          onClick={() =>
            v.link ? window.location.replace(v.link) : window.scrollTo(0, 0)
          }
        />
      ))}
    </S.Container>
  );
};

export default FooterDashboardMobile;
