import { Box } from "@material-ui/core";
import classnames from "classnames/bind";
import React, { useRef, useState } from "react";
import { THEME_MODE } from "src/interfaces/theme";
// import { iconDown, iconUSDC, iconUSDT } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import styles from "src/features/Market/components/ManageFunds/Transfer.module.scss";
import useOnClickOutside from "src/hooks/useClickOutside";
import { useAppSelector } from "src/store/hooks";
import { useTranslation } from "react-i18next";
import { USD } from "../../Constant";
import { BsCaretUpFill, BsCaretDownFill } from "react-icons/bs";
import { BiMoney, BiMoneyWithdraw } from "react-icons/bi";

type AssetType = {
  name: string;
  label: string;
  key: string;
};

type Props = {
  setCoinSelect: any;
  coinSelect: any;
  theme: string;
  listAssets: AssetType[];
  handleChangeCoin: () => void;
};

const cx = classnames.bind(styles);

export const SelectCoin: React.FC<Props> = ({
  coinSelect,
  setCoinSelect,
  theme,
  handleChangeCoin,
  listAssets,
}) => {
  const [openSelect, setOpenSelect] = useState(false);
  const coinInfo = useAppSelector((state) => state.masterdataFuture.coinInfo);
  const el = useRef(null);
  const { t } = useTranslation("common");

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const handleTransformIcon = (item: any) => {
    const coinImg = coinInfo?.find(
      (coin) => coin.symbol === item?.key?.toLowerCase()
    );
    return (
      coinImg?.coin_image ||
      (item?.key === USD ? (
        <BiMoney fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"} />
      ) : (
        <BiMoneyWithdraw
          fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
        />
      ))
    );
  };

  const handleTransformLabel = (item: any) => {
    const coinImg = coinInfo?.find(
      (coin) => coin.symbol === item?.key?.toLowerCase()
    );
    return coinImg?.fullName || "United State Dollar";
  };

  return (
    <div className={cx("select-wrapper")} ref={el}>
      <div
        className={cx("select-value")}
        onClick={() => setOpenSelect(!openSelect)}
      >
        <Box display="flex" alignItems="center" gridGap={10}>
          {handleTransformIcon(coinSelect)}
          <div className={cx("coin-select")}>{coinSelect?.name}</div>
          <div className={cx("coin-label")}>
            {handleTransformLabel(coinSelect)}
          </div>
        </Box>
        {openSelect ? (
          <BsCaretUpFill
            fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            style={{ marginTop: "4px", zoom: 0.75 }}
          />
        ) : (
          <BsCaretDownFill
            fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            style={{ marginTop: "4px", zoom: 0.75 }}
          />
        )}
      </div>

      {openSelect && (
        <div className={cx("select-list")}>
          {listAssets.length > 0 ? (
            listAssets?.map((item, index) => (
              <div
                key={index}
                className={cx("select-item")}
                onClick={() => {
                  setCoinSelect(item);
                  setOpenSelect(false);
                  handleChangeCoin();
                }}
                style={{ cursor: "pointer" }}
              >
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center" gridGap={10}>
                    {handleTransformIcon(item)}
                    <div className={cx("coin-select")}>{item.name}</div>
                    <div className={cx("coin-label")}>
                      {handleTransformLabel(item)}
                    </div>
                  </Box>
                  <div></div>
                </Box>
              </div>
            ))
          ) : (
            <div className={cx("select-item")}>
              {t("pair.no_result_found")}.
            </div>
          )}
        </div>
      )}
    </div>
  );
};
