import React, { useCallback, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
import useOnClickOutside from "src/hooks/useClickOutside";
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import orderListLight from "src/assets/icon/orderFuture/orderListLight.png";
import orderListDark from "src/assets/icon/orderFuture/orderListDark.png";
import { AnyMxRecord } from "dns";

const cx = classnames.bind(styles);

export type TOption = {
  id?: number;
  label: string;
  value?: string | number;
  icon?: string;
  link?: string;
  onAction?: () => void;
};

interface Props {
  title?: string;
  iconTitle?: string;
  options?: TOption[];
  isIcon?: boolean;
  noLink?: boolean;
}

const SelectOrderMobile: React.FC<Props> = ({
  title,
  iconTitle,
  options,
  isIcon,
  noLink,
}) => {
  const rfTitle = useRef<HTMLDivElement>(null);
  const rfUnderPanel = useRef<HTMLUListElement>(null);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [openSelect, setOpenSelect] = useState(false);
  const el = useRef(null);

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });
  const clickItem = (option: any) => {
    console.log("🚀 ~ clickItem ~ option:", option)
    if (option.link && !option.isFutures) {
      window.location.replace(option.link);
    }
  };

  return (
    <div
      className={cx("select-wrapper")}
      onClick={() => setOpenSelect(!openSelect)}
      ref={el}
    >
      <ul>
        <li>
          <img
            src={
              iconTitle
                ? iconTitle
                : theme === THEME_MODE.LIGHT
                ? orderListLight
                : orderListDark
            }
          />

          {openSelect && (
            <ul className={cx("option")} ref={rfUnderPanel}>
              {options?.map((option, index) => (
                <a
                  key={index}
                  className={cx("option-value", noLink && "value-no-link")}
                  // href={option.href}
                  onClick={() => clickItem(option)}
                >
                  {option.icon && <img src={option.icon} />}
                  {option.label && (
                    <div className={cx("option-text")}>{option.label}</div>
                  )}
                  {/* {!noLink && (
                    <span
                      className={`${cx("link-icon")} icon icon-arrow7`}
                      style={{ color: "#00ddb3" }}
                    />
                  )} */}
                </a>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default SelectOrderMobile;
