import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "./TradingRule.module.scss";
// import { iconDrop, iconDropDarkPre, iconSearchSelect, iconX } from 'src/assets/icon';Icon
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import useOnClickOutside from "src/hooks/useClickOutside";
import { useAppSelector } from "src/store/hooks";
import { getValueClassName } from "src/helpers/numberFormatter";
import { Instrument } from "src/services/instrument";
import { THEME_MODE } from "src/interfaces/theme";
import { TypeTrade } from "src/constants/common.constants";
import { useSelector } from "react-redux";
import { RootState } from "src/store/store";
import { useTranslation } from "react-i18next";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { getIconPriceChange } from "../ChooseMarketModal/ChooseMarketModal";
import {
  BsCaretDownFill,
  BsXOctagonFill,
  BsSearchHeartFill,
} from "react-icons/bs";

type Props = {
  setContractSelect: any;
  contractSelect: any;
};

const cx = classnames.bind(styles);

export const SelectContract = ({
  setContractSelect,
  contractSelect,
}: Props) => {
  const { t } = useTranslation("common");
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const [openSelect, setOpenSelect] = useState(false);
  const [textSearch, setTextSearch] = useState("");
  const [listCoin, setListCoin] = useState(instruments);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);

  const decimal = -Math.ceil(
    Math.log10(Number(contractSelect?.minPriceMovement ?? "0.01"))
  );

  const ticker = useAppSelector((state) =>
    state.ticker.tickers?.find(
      (ticker) => ticker.symbol === contractSelect.symbol
    )
  );
  const coins = instruments?.filter((item) =>
    isCoinM
      ? item.contractType === TypeTrade.COIN_M
      : item.contractType === TypeTrade.USD_M
  );

  const el = useRef(null);

  useEffect(() => {
    setListCoin(coins);
  }, [instruments]);

  useOnClickOutside(el, () => {
    if (openSelect) setOpenSelect(false);
  });

  const handleSearch = (text: string) => {
    setTextSearch(text);

    const resultSearch = coins?.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(text.toLowerCase())
    );
    setListCoin(resultSearch);
  };

  return (
    <div className={cx("select-wrapper")} ref={el}>
      <div className={cx("coin-wrapper")}>
        <div
          className={cx("select-value")}
          onClick={() => setOpenSelect(!openSelect)}
        >
          <div className={cx("select-name")}>
            {contractSelect.name} {t("pair.perpetual")}
          </div>
          <BsCaretDownFill
            fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
          />
        </div>
        <div className={cx("time-change")}>
          <span
            className={cx(
              "item-last-price",
              getValueClassName(ticker?.lastPriceChange)
            )}
          >
            <TooltipNumber
              characters={9}
              decimal={decimal}
              defaultValue={ticker?.lastPrice}
            />
          </span>
          <span className={cx("item-value-change")}>
            <div className={cx(getValueClassName(ticker?.priceChange))}>
              {ticker?.priceChange ? (
                <TooltipNumber
                  defaultValue={Math.abs(Number(ticker?.priceChange))}
                  characters={8}
                  decimal={decimal}
                />
              ) : (
                "--"
              )}
            </div>
            <div
              className={cx(
                getValueClassName(ticker?.priceChangePercent),
                "price-change-percent"
              )}
              style={{ display: "flex" }}
            >
              {ticker?.priceChangePercent ? (
                <>
                  {" "}
                  {getIconPriceChange(ticker?.priceChangePercent)}{" "}
                  <TooltipNumber
                    defaultValue={Math.abs(Number(ticker?.priceChangePercent))}
                    characters={7}
                    decimal={DECIMAL_TWO}
                    symbol="%"
                  />{" "}
                </>
              ) : (
                "--"
              )}
            </div>
          </span>
        </div>
      </div>

      {openSelect && (
        <div className={cx("select-list")}>
          <div className={cx("select-search")}>
            <BsSearchHeartFill
              fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
            />
            <input
              placeholder={t("pair.search")}
              value={textSearch}
              onChange={(e) => handleSearch(e.target.value)}
            />
            {textSearch && (
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1, cursor: "pointer" }}
                onClick={() => {
                  setTextSearch("");
                  setListCoin(coins);
                }}
              />
            )}
          </div>

          {listCoin.length > 0 ? (
            listCoin?.map((item, index) => (
              <div
                key={index}
                className={cx(
                  "select-item",
                  contractSelect.symbol === item.symbol && "select-item-active"
                )}
                onClick={() => {
                  setContractSelect(item);
                  setOpenSelect(false);
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                {item.name} {t("pair.perpetual")}
              </div>
            ))
          ) : (
            <div className={cx("no-result-found")}>
              {t("pair.no_result_found")}.
            </div>
          )}
        </div>
      )}
    </div>
  );
};
