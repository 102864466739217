import { CSSProperties, HTMLAttributes } from "react";
import { useTranslation } from "react-i18next";
import TooltipText from "src/components/cores/TooltipText";

export default function ThHead({
  style,
  text,
  textTooltip,
  isTooltip,
}: {
  style?: CSSProperties;
  text: string;
  textTooltip?: string;
  isTooltip?: boolean;
}) {
  return (
    <th style={{ ...style }}>
      {isTooltip ? <TooltipText text={textTooltip}>{text}</TooltipText> : text}
    </th>
  );
}
