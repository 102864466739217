import classNames from "classnames/bind";
import { useHistory } from "react-router";
import React, { useState, useEffect, useMemo } from "react";
import { ORDERS_TAB } from "src/features/Orders/constant";
import OpenOrderPage from "src/features/Orders/OpenOrder/OpenOrder";
import OrderHistoryPage from "src/features/Orders/OrderHistory";
import TradeHistoryPage from "src/features/Orders/TradeHistory";
import TransactionHistoryPage from "src/features/Orders/TransactionHistory";
import styles from "./index.module.scss";
import { getCookieStorage } from "../../helpers/storage";
import routeConstants from "src/routes/route.constants";
import { useLocation } from "react-router-dom";
import { TypeTrade } from "src/constants/common.constants";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import Footer from "src/components/Footer/Footer";
import SelectOrderMobile from "src/components/cores/SelectOrderMobile";
import orderListLight from "src/assets/icon/orderFuture/orderListLight.png";
import orderListDark from "src/assets/icon/orderFuture/orderListDark.png";

import convertDark from "src/assets/icon/orderFuture/convertDark.png";
import convertLight from "src/assets/icon/orderFuture/convertLight.png";
import futureDark from "src/assets/icon/orderFuture/futureDark.png";
import futureLight from "src/assets/icon/orderFuture/futureLight.png";
import spotDark from "src/assets/icon/orderFuture/spotDark.png";
import spotLight from "src/assets/icon/orderFuture/spotLight.png";

const DOMAIN_SPOT = process.env.REACT_APP_DOMAIN_SPOT || "";

const cx = classNames.bind(styles);

export const Orders: React.FC = () => {
  const search = useLocation().search;
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [tabActive, setTabActive] = useState(0);
  const history = useHistory();
  const { t } = useTranslation("common");
  const token = getCookieStorage("access_token");

  const ORDERS_TAB_VALUE = [
    {
      id: 0,
      label: `${t("header.spot")}`,
      link: `${DOMAIN_SPOT}/spot-exchange/orders/open`,
      icon: theme === THEME_MODE.LIGHT ? spotLight : spotDark,
      isFutures: false,
    },
    {
      id: 1,
      label: `${t("header.futures")}`,
      link: `${DOMAIN_SPOT}/spot-exchange/orders/open`,
      icon: theme === THEME_MODE.LIGHT ? futureLight : futureDark,
      isFutures: true,
    },
    {
      id: 2,
      label: `${t("header.convert")}`,
      link: `${DOMAIN_SPOT}/spot-exchange/orders/open`,
      icon: theme === THEME_MODE.LIGHT ? convertLight : convertDark,
      isFutures: false,
    },
  ];

  useEffect(() => {
    const curTab = searchParams.get("tab");
    switch (curTab) {
      case "open-order":
        setTabActive(0);
        break;
      case "order-history":
        setTabActive(1);
        break;
      case "trade-history":
        setTabActive(2);
        break;
      case "transaction-history":
        setTabActive(3);
        break;

      default:
        break;
    }
  }, [searchParams]);

  useEffect(() => {
    if (!token) {
      history.push(`/futures/${TypeTrade.USD_M}`);
    }
  }, [token]);

  return (
    <div className={cx("wrapper")}>
      <div className={cx("orders-tab")}>
        {ORDERS_TAB_VALUE.map((tab, index) => (
          <div
            key={index}
            className={cx("tab-items", tab.isFutures && "tab-items-active")}
            onClick={() => {
              !tab.isFutures &&
                window.location.replace(tab.link);
            }}
          >
            <img alt="" src={tab.icon} />
            {t(tab.label)}
          </div>
        ))}
      </div>
      <div className={cx("orders-content")}>
        <div className={cx("icon-mobile")}>
          <SelectOrderMobile
            title=""
            iconTitle={
              theme === THEME_MODE.LIGHT ? orderListLight : orderListDark
            }
            options={ORDERS_TAB_VALUE}
          />
        </div>
        <div className={cx("title")}>
          <div className={cx("title-left")}>Order</div>
          <div className={cx("title-left")}>/</div>
          <div className={cx("title-right")}>Futures</div>
        </div>
        <div className={cx("tab")}>
          <div className={cx("tab-content")}>
            {ORDERS_TAB.map((tab, i) => (
              <div
                className={cx("tab-item", tabActive === i && "active")}
                key={i}
                onClick={() => setTabActive(i)}
              >
                {t(tab)}
              </div>
            ))}
          </div>
        </div>
        <div className={cx("name")}>
          <div className={cx("name-tab")}>{t(ORDERS_TAB[tabActive])}</div>
          <div className={cx("name-future")}>Futures</div>
        </div>
        <div className={cx("content")}>
          {tabActive === 0 && <OpenOrderPage />}
          {tabActive === 1 && <OrderHistoryPage />}
          {tabActive === 2 && <TradeHistoryPage />}
          {tabActive === 3 && <TransactionHistoryPage />}
        </div>
        <div className={cx("footer-warp")}>
          <Footer />
        </div>
      </div>
    </div>
  );
};
