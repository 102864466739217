import React, { useCallback, useRef, useState } from "react";
import classnames from "classnames/bind";
import styles from "./index.module.scss";
// import { linkIcon, DropIcon, DropIconDark } from 'src/assets/icon';
// import Icon from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { useAppSelector } from "src/store/hooks";
import { THEME_MODE } from "src/interfaces/theme";
import languageLight from "src/assets/icon/navbar/languageLight.png";
import languageDark from "src/assets/icon/navbar/languageDark.png";

const cx = classnames.bind(styles);

export type TOption = {
  label: string;
  value?: string | number;
  icon?: string;
  href?: string;
  onAction?: () => void;
};

interface Props {
  title?: string;
  iconTitle?: string;
  options?: TOption[];
  isIcon?: boolean;
  noLink?: boolean;
}

const SelectLanguage: React.FC<Props> = ({
  title,
  iconTitle,
  options,
  isIcon,
  noLink,
}) => {
  const rfTitle = useRef<HTMLDivElement>(null);
  const rfUnderPanel = useRef<HTMLUListElement>(null);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [openSelect, setOpenSelect] = useState(false);
  const el = useRef(null);

  const handleOnTitleMouseEntered = useCallback(() => {
    if (!rfTitle.current) return;
    if (!rfUnderPanel.current) return;
    if (
      window.innerWidth - rfTitle.current.getBoundingClientRect().right <
      rfUnderPanel.current.getBoundingClientRect().width + 20
    ) {
      rfUnderPanel.current.style.right = "0px";
    }
  }, []);

  return (
    <div
      className={cx("select-wrapper")}
      // onClick={() => setOpenSelect(!openSelect)}
      ref={el}
    >
      <ul>
        <li onMouseEnter={handleOnTitleMouseEntered}>
          <img
            src={theme === THEME_MODE.LIGHT ? languageLight : languageDark}
          />

          {options && (
            <ul className={cx("option")} ref={rfUnderPanel}>
              {options?.map((option, index) => (
                <a
                  key={index}
                  className={cx("option-value", noLink && "value-no-link")}
                  href={option.href}
                  onClick={option.onAction}
                >
                  {option.icon && (
                    <img
                      style={{ marginLeft: "15px" }}
                      width={25}
                      height={25}
                      src={option.icon}
                    />
                  )}
                  {option.label && (
                    <div className={cx("option-text")}>{option.label}</div>
                  )}
                  {!noLink && (
                    <span
                      className={`${cx("link-icon")} icon icon-arrow7`}
                      style={{ color: "#00ddb3" }}
                    />
                  )}
                </a>
              ))}
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
};

export default SelectLanguage;
