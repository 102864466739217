import BigNumber from "bignumber.js";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { IOrder } from "src/interfaces/order";
import { Instrument } from "src/services/instrument";
import { ITradingRuleCache } from "src/services/masterdataFuture";

export default function FilledQuantity({
  order,
  instruments,
  tradingRulesCache,
  isCoinM,
}: {
  order: IOrder;
  instruments: Instrument[];
  tradingRulesCache: ITradingRuleCache[];
  isCoinM: boolean;
}) {
  const fillQuantity = new BigNumber(order.quantity ?? "0").minus(
    order.remaining ?? "0"
  );
  const currentInstrument = instruments?.find(
    (instrument) => instrument?.symbol === order?.symbol
  );
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === order?.symbol
  );
  const maxFiguresForSize =
    Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

  return (
    <TooltipNumber
      characters={7}
      decimal={isCoinM ? 0 : maxFiguresForSize}
      symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
      defaultValue={fillQuantity}
    />
  );
}
