import React from 'react';
import classNames from 'classnames/bind';
import styles from 'src/features/Market/components/TradingRule/TradingRule.module.scss';
import { useAppSelector } from 'src/store/hooks';
import { formatIntBalance } from 'src/helpers/numberFormatter';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const CoinInfo = (props: any) => {
  const { t } = useTranslation('common');
  const coinInfoValue = useAppSelector((state) => state.general.coinInfo);

  return (
    <div>
      <div className={cx('order-content')}>
        <div className={cx('option-order')}>
          <div className={cx('contract')}>
            <img src={coinInfoValue?.coin_image} width={30} />
            <div className={cx('text-coin')}>{coinInfoValue?.fullName}</div>
            <div className={cx('text-contract')}>{props?.pairSelect.rootSymbol}</div>
          </div>
          <div className={cx('text-no')}> {t('rank_coin', { number: coinInfoValue?.rank })}</div>
        </div>

        <div className={cx('option-order')}>
          <div className={cx('option-text')}>{t('tradingRule.market_cap')}</div>
          <div className={cx('explorer')}>
            <div className={cx('option-value')}>${formatIntBalance(coinInfoValue?.marketCap || '--')}</div>
          </div>
        </div>
        <div className={cx('option-order')}>
          <div className={cx('option-text')}>{t('tradingRule.circulation_supply')}</div>
          <div className={cx('explorer')}>
            <div className={cx('option-value')}>{formatIntBalance(coinInfoValue?.cirSupply || '--')}</div>
          </div>
        </div>
        <div className={cx('option-order')}>
          <div className={cx('option-text')}>{t('tradingRule.max_supply')}</div>
          <div className={cx('explorer')}>
            <div className={cx('option-value')}>{formatIntBalance(coinInfoValue?.maxSupply || '--')}</div>
          </div>
        </div>
        <div className={cx('option-order')}>
          <div className={cx('option-text')}>{t('tradingRule.total_supply')}</div>
          <div className={cx('explorer')}>
            <div className={cx('option-value')}>{formatIntBalance(coinInfoValue?.totalSupply || '--')}</div>
          </div>
        </div>
        <div className={cx('empty-coin')}></div>
      </div>
    </div>
  );
};
