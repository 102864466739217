import BigNumber from 'bignumber.js';
import { balanceCoin } from 'src/helpers/balanceCalculator';
import PositionCalculator from 'src/helpers/PositionCalculator';
import store from 'src/store/store';

let allPositions = store.getState().position.allPosition;
let currentIns = store.getState().instrument.currentInstrument;
let isCoinM = store.getState().typeTrade.isCoinM;

store.subscribe(() => {
  allPositions = store.getState().position.allPosition;
  isCoinM = store.getState().typeTrade.isCoinM;
  currentIns = store.getState().instrument.currentInstrument;
});

// Allocated Margin for Cross position = Size * Mark price / Leverage
// Allocated Margin for Isolated position = Size * Entry price / Leverage
// Margin balance for Cross positions = Wallet balance + Sum PNL Cross position - Sum Margin của Isolated position
// Margin balance for Isolated position = Allocated margin + Unrealized PNL
export const getMarginBalance = (type: any, asset: any, symbol: any) => {
  if (allPositions.length === 0) {
    const wallet_balance = balanceCoin(isCoinM ? currentIns.rootSymbol : currentIns.quoteCurrency);
    return wallet_balance;
  }
  if (type === 'Cross') {
    const walletBalance = balanceCoin(asset);
    // Unrealized PNL of Cross position
    const PNLArray = allPositions.map((item: any) => {
      return item.isCross && item?.asset === asset ? new BigNumber(PositionCalculator.getUnrealizedPNL(item)) : 0;
    });
    const totalPNL = PNLArray.reduce((prev, cur) => new BigNumber(prev).plus(cur), new BigNumber(0));

    // Position Margin Isolate
    const marginPositionArray = allPositions.map((item: any) => {
      return !item.isCross && item?.asset === asset ? new BigNumber(PositionCalculator.getAllocatedMargin(item)) : 0;
    });
    const totalMarginPosition = marginPositionArray.reduce(
      (prev, cur) => new BigNumber(prev).plus(cur),
      new BigNumber(0),
    );
    const marginBalance = new BigNumber(walletBalance || 0).plus(totalPNL).minus(totalMarginPosition);
    return marginBalance.lt(0) ? 0.0 : Number(marginBalance);
  } else {
    const allocatedArray = allPositions.map((item: any) => {
      return !item.isCross && item.symbol === symbol
        ? new BigNumber(PositionCalculator.getAllocatedMargin(item)).plus(PositionCalculator.getUnrealizedPNL(item))
        : 0;
    });
    const marginBalance = allocatedArray.reduce((prev, cur) => new BigNumber(prev).plus(cur), new BigNumber(0));
    return Number(marginBalance) < 0 ? 0.0 : Number(marginBalance);
  }
};
// "Maintenance Margin (of each Position)
// = Size * Mark price * Maintenance Margin rate - Maintenance Amount"
export const getMaintenanceMargin = (type: any, symbol: any) => {
  if (allPositions.length === 0) {
    return 0.0;
  }
  const maintainArray = allPositions.map((item: any) => {
    return type === 'Cross' && item.isCross && item.asset === symbol
      ? new BigNumber(PositionCalculator.getMaintainAmountPosition(item))
      : type === 'Isolate' && item.symbol === symbol
      ? new BigNumber(PositionCalculator.getMaintainAmountPosition(item))
      : 0;
  });
  const totalMaintainMargin = maintainArray.reduce((prev, cur) => new BigNumber(prev).plus(cur), new BigNumber(0));
  return Number(totalMaintainMargin);
};
