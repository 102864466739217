import styled from "@emotion/styled";

export const Wrap = styled.div`
  display: flex;
  padding: 2px;
  align-items: flex-start;
  border-radius: 50px;
  background: ${({ theme }) => theme.chart.bgTabTrading};
  cursor: pointer;
  /* width: 196px; */
  /* height: 28px; */
`;

export const TabItem = styled.div`
  color: ${({ theme }) => theme.chart.colorTabTrading};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  display: flex;
  padding: 0 12px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 50px;
  flex: 1 0 0;
  transition: all 0.3s;
  &.active {
    background: ${({ theme }) => theme.chart.bgTabTradingActive};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    color: ${({ theme }) => theme.chart.colorTabTradingActive};
  }
`;
