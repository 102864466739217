import * as S from "./style";
import ItemMobile from "./ItemMobile";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { useEffect, useState, useCallback, useMemo } from "react";
import { IFill } from "src/interfaces/fill";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { TypeTrade } from "src/constants/common.constants";
import { getFilledTradesBySymbol } from "src/services/trade";
import Loading from "src/components/Loading";

export default function MobileTradeHistory() {
  const dispatch = useAppDispatch();
  const account = useAppSelector((state) => state.account.account);
  const instruments = useAppSelector((state) => state.instrument.instrument);

  const { fills, loading } = useAppSelector((state) => state.tradeHistory);
  const [rows, setRows] = useState<IFill[]>([]);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const contractType = useMemo(() => getContractType(), []);
  const isCoinM = useMemo(
    () => contractType === TypeTrade.COIN_M,
    [contractType]
  );

  const handleFormatTime = useCallback(() => {
    const endTime = new Date();
    const startTime = new Date();
    startTime.setMonth(startTime.getMonth() - 3);
    return {
      startTime: startTime.getTime(),
      endTime: endTime.getTime(),
    };
  }, []);

  const fetchData = useCallback(
    async (page: number) => {
      const { startTime, endTime } = handleFormatTime();

      const params = { page: page, size: 20 };

      const payload: any = {
        startTime: 0,
        endTime: 0,
        side: "ALL",
        contractType,
      };
      if (startTime) {
        payload.startTime = startTime;
      }
      if (endTime) {
        payload.endTime = endTime;
      }

      await dispatch(getFilledTradesBySymbol({ params, payload }));
    },
    [contractType, handleFormatTime, dispatch]
  );

  useEffect(() => {
    if (fills) {
      setRows(fills);
    }
  }, [fills]);

  useEffect(() => {
    fetchData(1);
  }, [fetchData, account[0]?.id]);
  return (
    <S.MobileStyle>
      <div className="container-des">
        <span className="text-des">Only the last 20 displayed</span>
      </div>
      <div className="container-list">
        {loading ? (
          <div
            style={{
              width: "100%",
              height: 30,
              display: "flex",
              justifyContent: "center",
            }}>
            <Loading />
          </div>
        ) : (
          rows.map((item, index) => (
            <ItemMobile
              key={`${item.id}-tradingHistory-${index}`}
              row={item}
              instruments={instruments}
              isCoinM={isCoinM}
              tradingRulesCache={tradingRulesCache}
            />
          ))
        )}
      </div>
    </S.MobileStyle>
  );
}
