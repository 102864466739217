import styled from "@emotion/styled";

export const MobileStyle = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  .container-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 17px;
    .hidden-btn {
      display: flex;
      align-items: center;
    }
  }
  .action-close-all {
    font-size: 12px;
    font-weight: normal;
    color: ${({ theme }) => theme.colors.colorTabActive};
  }
  .hidden-text {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.text1};
  }
  .container-item-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .title {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.text1};
      font-weight: 400;
    }
    .content {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.colorTab};
      font-weight: 400;
      text-align: left;
    }
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left;
    }
  }

  .container-list {
    display: flex;
    flex-direction: column;
    .container-item {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
      padding-bottom: 32px;
      margin-bottom: 24px;
    }
  }
  .symbol {
    display: none !important;
  }
  .btn-view {
    text-decoration: underline;
    cursor: pointer;
  }
  .color-buy {
    color: ${({ theme }) => theme.colors.greenChart};
  }
  .color-sell {
    color: ${({ theme }) => theme.colors.errorChart};
  }
  .text-color {
    color: ${({ theme }) => theme.colors.text1};
  }
`;
