const DASHBOARD = '/futures/:typeTrade';
const NOT_FOUND = '/system/not-found';
const MAIN_TAIN = '/maintain';
const TRADING = '/futures';

// account
const ACCOUNT_ORDER = '/account/order';
const ACCOUNT_HISTORY = '/account/history';
const VERIFY_EMAIL = '/verify-email';

// orders
const OPEN_ORDER = '/orders';

// webview
const WEBVIEW_CHART = '/webview/chart/:symbol/:theme/:lang';
const WEBVIEW_CALCULATOR = '/webview/calculator/:typeTrade/:symbol/:theme/:lang';

export default {
  NOT_FOUND,
  MAIN_TAIN,
  DASHBOARD,
  TRADING,

  // account
  ACCOUNT_ORDER,
  ACCOUNT_HISTORY,
  VERIFY_EMAIL,

  // Order
  OPEN_ORDER,

  // webview
  WEBVIEW_CHART,
  WEBVIEW_CALCULATOR,
};
