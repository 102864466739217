/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import classnames from 'classnames/bind';
import React, { useState, forwardRef, useEffect, useRef } from 'react';
import Select, { MenuPlacement, components } from 'react-select';
import 'src/components/cores/SelectTPSL/selectTPSL.scss';
import stylesSCSS from 'src/components/cores/SelectTPSL/SelectTPSL.module.scss';
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
// import { downIcon, increaseIconDark, reduceIconDark, upIcon } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import styles from "src/components/cores/SelectTPSL/styles/";
import { BsCaretUp, BsCaretDown } from "react-icons/bs";

export interface ISelect {
  icon?: string;
  label: string;
  value?: any;
}

export const renderDefaultValueSelect = (string: string): ISelect => ({
  label: string,
  value: string,
});

export const renderOptionsSelect = (arr: string[]): ISelect[] => arr.map((item) => renderDefaultValueSelect(item));

interface Props {
  value?: ISelect | ISelect[];
  onChange: (value: any) => void;
  options: ISelect[];
  defaultValue?: ISelect | ISelect[];
  placeholder?: string;
  isDisabled?: boolean;
  isMulti?: boolean;
  onMenuScrollToBottom?: () => void;
  className?: string;
  isError?: boolean;
  message?: string;
  menuPlacement?: MenuPlacement;
  menuIsOpen?: boolean;
  onMenuOpen?: () => void;
  showSearchBar?: boolean;
  hideSearchBarSearchIcon?: boolean;
  valueSet?: number;
  isSearchable?: boolean;
  classNameWrapper?: string;
  isMargin?: boolean;
}

const cx = classnames.bind(stylesSCSS);

const filterOption = (option: ISelect, string: string) => {
  if (!string) return true;
  return option.label.toLowerCase().includes(string.toLowerCase()) ? true : false;
};

const DropdownIndicator = (props: any) => {
  const { selectProps } = props;
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);

  let arrow;
  if (selectProps.menuIsOpen) {
    arrow = (
      <BsCaretUp
        fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
      />
    );
  } else {
    arrow = (
      <BsCaretDown
        fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
      />
    );
  }
  return (
    <components.DropdownIndicator {...props}>
      <>{arrow}</>
    </components.DropdownIndicator>
  );
};

const SelectTPSL: React.FC<Props> = forwardRef<HTMLInputElement, Props>(
  (
    { onChange = () => {}, isError = false, message = '', isSearchable = false, showSearchBar = false, ...props },
    ref,
  ) => {
    const classes = styles();

    const containerRef = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const onDomClick = (e: any) => {
      if (containerRef !== null && containerRef.current !== null) {
        const menu = containerRef.current.querySelector('.select__menu');

        if (!containerRef.current.contains(e.target) || !menu || !menu.contains(e.target)) {
          setIsFocused(false);
          setInputValue('');
        }
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', onDomClick);

      return () => {
        document.removeEventListener('mousedown', onDomClick);
      };
    }, []);

    return (
      <div className={cx(props.classNameWrapper)} ref={containerRef}>
        <Select
          onChange={(v: any) => {
            setIsFocused(false);
            onChange(
              Array.isArray(v)
                ? v.map((item: ISelect) => (props.isMargin ? item : item.value))
                : props.isMargin
                ? v
                : v.value,
            );
          }}
          {...props}
          backspaceRemovesValue={false}
          filterOption={filterOption}
          classNamePrefix={cx('theme-select-tpsl')}
          className={`${props.className} ${classes.select}`}
          isSearchable={isSearchable}
          noOptionsMessage={() => 'Not found'}
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            border: '0 !important',
            '&:foc': {
              border: '0 !important',
            },
            colors: {
              ...theme.colors,
              primary: '#1A88C9',
            },
          })}
          components={{ DropdownIndicator }}
          inputValue={inputValue}
          // setInputValue={setInputValue}
          // onMenuInputFocus={() => setIsFocused(true)}
          onInputChange={(val) => setInputValue(val)}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
          maxMenuHeight={250}
        />
        <div className="text-left">{isError && <span className="text-red-600 text-xs ">{message}</span>}</div>
      </div>
    );
  },
);

export default SelectTPSL;
