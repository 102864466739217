import { useTranslation } from "react-i18next";
import {
  OrderType,
  TransactionType,
} from "src/features/PositionAndHistory/constant";
import { formatOrderText } from "src/features/PositionAndHistory/helper";
import { IOrder, OrderStatus } from "src/interfaces/order";
import { ITransactionHistory } from ".";

export default function RenderTypeOrder({
  transaction,
}: {
  transaction: ITransactionHistory;
}) {
  const { t } = useTranslation("common");
  if (
    transaction.type === TransactionType.DEPOSIT ||
    transaction.type === TransactionType.WITHDRAWAL
  ) {
    return t("order.transfer");
  }
  if (transaction.type === TransactionType.REALIZED_PNL) {
    return t("order.realized_pnl");
  }

  return t(formatOrderText(transaction.type));
}
