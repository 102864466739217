import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';
import { ITrade } from 'src/interfaces/trade';

export interface ITicker {
  symbol: string;
  priceChange: string;
  priceChangePercent: string;
  lastPrice: string;
  lastPriceChange: string;
  highPrice: string;
  lowPrice: string;
  volume: string;
  quoteVolume: string;
  indexPrice: string;
  oraclePrice: string;
  fundingRate: string;
  nextFunding: number;
  trades: ITrade[];
  updateAt: number;
  lastUpdateAt: number;
}

const initialState: { loading: boolean; error: boolean; tickers: ITicker[] } = {
  loading: false,
  error: false,
  tickers: [],
};

export const getTicker24h = createAsyncThunk('get-ticker-24h', async () => {
  return await axiosInstance.get('/ticker/24h');
});

export const tickerSlice = createSlice({
  name: 'ticker',
  initialState,
  reducers: {
    updateTickers: (state, action) => {
      const ticketsNotNull = action?.payload?.filter((item: ITicker) => item.lastPrice);
      if (ticketsNotNull) {
        const updateTicker =
          state.tickers?.length > 0 &&
          state.tickers.map(
            (item) => ticketsNotNull?.find((ticker: ITicker) => ticker?.symbol === item?.symbol) || item,
          );
        state.tickers = updateTicker || [];
      }
      state.tickers = action.payload || [];
    },
  },
  extraReducers: {
    [`${getTicker24h.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getTicker24h.rejected}`]: (state, action: any) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getTicker24h.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.tickers = action.payload.data || [];
    },
  },
});

const { reducer: tickerReducer } = tickerSlice;

export default tickerReducer;

export const { updateTickers } = tickerSlice.actions;
