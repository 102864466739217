export const MOBILE_DIMENSION = 768;
export const TIMEOUT = 20000;

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export enum TradeSide {
  BUY = "BUY",
  SELL = "SELL",
}

export const PRECISION_FIX_4 = "0.0001";
export const PRECISION_FIX_3 = "0.001";
export const PRECISION_FIX_2 = "0.01";

export const DATE_TIME_FORMATTER = "yyyy-MM-DD HH:mm:ss";
export const RECORDS_PER_PAGE = 4;
export const DISPLAY_RECORD_NUMBER = 30;
export const RECORD_TRADE = 15;
export const TAB_HISTORY = {
  TRADE: 0,
  TRANSFER: 1,
  FUNDING: 2,
};

export enum TypeTrade {
  USD_M = "USD_M",
  COIN_M = "COIN_M",
}

export enum MarketDefault {
  BTCUSDT = "BTCUSDT",
  BTCUSDM = "BTCUSDM",
}

export const dataRegion: string[] = [
  "Egypt",
  "Iraq",
  "Qatar",
  "Oman",
  "Morocco",
  "Algeria",
  "Tunisia",
  "Bangladesh",
  "China",
  "United States",
];
