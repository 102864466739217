import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RECORD_TRADE } from 'src/constants/common.constants';
import axiosInstance from 'src/helpers/config';
// import { openSnackbarError } from 'src/store/snackbar';
// import store from 'src/store/store';

export interface IMarketTrade {
  id?: string;
  symbol?: string;
  instrumentSymbol: string;
  price: string;
  quantity: string;
  buyerIsTaker: boolean;
  createdAt: string;
}

const initialState: {
  marketTrade: IMarketTrade[];
  isReady: boolean;
  pendingUpdates: IMarketTrade[];
  updateAt: number;
  lastTradeId: number | null;
} = {
  marketTrade: [],
  pendingUpdates: [],
  isReady: false,
  updateAt: Date.now(),
  lastTradeId: null,
};

export const getMarketTrade = createAsyncThunk('getMarketTrade', async (symbol: string) => {
  const res = axiosInstance
    .get(`/trade/${symbol}`, { params: { size: RECORD_TRADE * 2 } })
    .catch((error) => {
      throw error;
    })
    .then((res) => res);
  return res;
});

export const marketTradeSlice = createSlice({
  name: 'marketTrade',
  initialState,
  reducers: {
    addNewRecentTrades: (state, action) => {
      // state.marketTrade = [...action.payload, ...state.marketTrade].slice(0, RECORD_TRADE * 2);
      if (state.isReady) {
        const updatedTrade = [...action.payload, ...state.marketTrade];
        state.marketTrade = updatedTrade.slice(0, RECORD_TRADE * 2);
        state.lastTradeId = updatedTrade[0]?.id ? Number(updatedTrade[0].id) : state.lastTradeId;
      } else {
        state.pendingUpdates = [...action.payload, ...state.pendingUpdates];
        state.lastTradeId = action.payload[0]?.id ? Number(action.payload[0].id) : state.lastTradeId;
      }
    },
    clearMarketTrades: (state) => {
      // state.marketTrade = [];
      state.isReady = false;
      state.pendingUpdates = [];
      state.lastTradeId = null;
    },
    changeUpdateAt: (state, action) => {
      state.updateAt = action.payload;
    },
  },
  extraReducers: {
    [getMarketTrade.pending.toString()]: (state) => {
      state.isReady = false;
    },
    [getMarketTrade.rejected.toString()]: () => {},
    [getMarketTrade.fulfilled.toString()]: (state, action) => {
      // state.marketTrade = action.payload.data;
      const updatedTrade = [...state.pendingUpdates, ...action.payload.data];
      state.marketTrade = updatedTrade.slice(0, RECORD_TRADE * 2);
      state.lastTradeId = updatedTrade[0]?.id ? Number(updatedTrade[0].id) : state.lastTradeId;
      state.isReady = true;
      state.pendingUpdates = [];
    },
  },
});

const { reducer: marketTradeReducer } = marketTradeSlice;

export default marketTradeReducer;

export const { addNewRecentTrades, clearMarketTrades, changeUpdateAt } = marketTradeSlice.actions;
