import { forwardRef } from "react";
import useAppTheme from "src/hooks/useAppTheme";
import { THEME_MODE } from "src/interfaces/theme";

const BothIcon = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(
  (props, ref) => {
    const { themeMode } = useAppTheme();
    return (
      <div
        style={{ display: "flex", alignItems: "center" }}
        ref={ref}
        {...props}
      >
        {themeMode == THEME_MODE.LIGHT ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M7.33301 3.83316C7.33301 3.46497 7.63149 3.1665 7.99968 3.1665H13.333C13.7012 3.1665 13.9997 3.46497 13.9997 3.83316C13.9997 4.20135 13.7012 4.49983 13.333 4.49983H7.99968C7.63149 4.49983 7.33301 4.20135 7.33301 3.83316ZM7.33301 6.49983C7.33301 6.13164 7.63149 5.83316 7.99968 5.83316H13.333C13.7012 5.83316 13.9997 6.13164 13.9997 6.49983C13.9997 6.86802 13.7012 7.1665 13.333 7.1665H7.99968C7.63149 7.1665 7.33301 6.86802 7.33301 6.49983ZM7.33301 10.4998C7.33301 10.1316 7.63149 9.83316 7.99968 9.83316H13.333C13.7012 9.83316 13.9997 10.1316 13.9997 10.4998C13.9997 10.868 13.7012 11.1665 13.333 11.1665H7.99968C7.63149 11.1665 7.33301 10.868 7.33301 10.4998ZM7.33301 13.1665C7.33301 12.7983 7.63149 12.4999 7.99968 12.4999H13.333C13.7012 12.4999 13.9997 12.7983 13.9997 13.1665C13.9997 13.5347 13.7012 13.8332 13.333 13.8332H7.99968C7.63149 13.8332 7.33301 13.5347 7.33301 13.1665Z"
              fill="#151717"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 10.8281C2 10.2758 2.44772 9.82812 3 9.82812H5C5.55228 9.82812 6 10.2758 6 10.8281V12.8281C6 13.3804 5.55228 13.8281 5 13.8281H3C2.44772 13.8281 2 13.3804 2 12.8281V10.8281ZM3.33333 12.4948V11.1615H4.66667V12.4948H3.33333Z"
              fill="#01BC8D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 4.16406C2 3.61178 2.44772 3.16406 3 3.16406H5C5.55228 3.16406 6 3.61178 6 4.16406V6.16406C6 6.71635 5.55228 7.16406 5 7.16406H3C2.44772 7.16406 2 6.71635 2 6.16406V4.16406ZM3.33333 5.83073V4.4974H4.66667V5.83073H3.33333Z"
              fill="#F1493F"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
          >
            <path
              d="M7.33301 3.83316C7.33301 3.46497 7.63149 3.1665 7.99968 3.1665H13.333C13.7012 3.1665 13.9997 3.46497 13.9997 3.83316C13.9997 4.20135 13.7012 4.49983 13.333 4.49983H7.99968C7.63149 4.49983 7.33301 4.20135 7.33301 3.83316ZM7.33301 6.49983C7.33301 6.13164 7.63149 5.83316 7.99968 5.83316H13.333C13.7012 5.83316 13.9997 6.13164 13.9997 6.49983C13.9997 6.86802 13.7012 7.1665 13.333 7.1665H7.99968C7.63149 7.1665 7.33301 6.86802 7.33301 6.49983ZM7.33301 10.4998C7.33301 10.1316 7.63149 9.83316 7.99968 9.83316H13.333C13.7012 9.83316 13.9997 10.1316 13.9997 10.4998C13.9997 10.868 13.7012 11.1665 13.333 11.1665H7.99968C7.63149 11.1665 7.33301 10.868 7.33301 10.4998ZM7.33301 13.1665C7.33301 12.7983 7.63149 12.4999 7.99968 12.4999H13.333C13.7012 12.4999 13.9997 12.7983 13.9997 13.1665C13.9997 13.5347 13.7012 13.8332 13.333 13.8332H7.99968C7.63149 13.8332 7.33301 13.5347 7.33301 13.1665Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 10.8281C2 10.2758 2.44772 9.82812 3 9.82812H5C5.55228 9.82812 6 10.2758 6 10.8281V12.8281C6 13.3804 5.55228 13.8281 5 13.8281H3C2.44772 13.8281 2 13.3804 2 12.8281V10.8281ZM3.33333 12.4948V11.1615H4.66667V12.4948H3.33333Z"
              fill="#01BC8D"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2 4.16406C2 3.61178 2.44772 3.16406 3 3.16406H5C5.55228 3.16406 6 3.61178 6 4.16406V6.16406C6 6.71635 5.55228 7.16406 5 7.16406H3C2.44772 7.16406 2 6.71635 2 6.16406V4.16406ZM3.33333 5.83073V4.4974H4.66667V5.83073H3.33333Z"
              fill="#F1493F"
            />
          </svg>
        )}
      </div>
    );
  }
);

export default BothIcon;
