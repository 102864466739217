import BigNumber from 'bignumber.js';
import PositionCalculator from 'src/helpers/PositionCalculator';
import { IOrder } from 'src/interfaces/order';
import { IOrderbook } from 'src/interfaces/orderbook';
import { Instrument } from 'src/services/instrument';
import { IPosition } from 'src/services/position';
import { ITicker } from 'src/services/ticker';
import { OrderType } from './Constant';

export const calculateCostUsdM = (
  price: string,
  quantity: string,
  orderType: string,
  currentInstrument: Instrument,
  allOrders: IOrder[],
  position: IPosition | undefined,
  ticker: ITicker | undefined,
  orderMode: any,
  orderbook: IOrderbook,
  maxBuyOpen: string,
  maxSellOpen: string,
  setOrderValueSell: (value: string) => void,
  setOrderValueBuy: (value: string) => void,
  symbolType?: string
) => {
  const initialValue = 0;
  if (
    (Number(price) === 0 &&
      orderType !== OrderType.market &&
      orderType !== OrderType.stopMarket &&
      orderType !== OrderType.trailingStop) ||
    Number(quantity) === 0
  ) {
    setOrderValueSell('0.00');
    setOrderValueBuy('0.00');
    return;
  }

  if (!allOrders) return;

  const filterAllOrders = allOrders
    .filter((item) => item.type === 'LIMIT' && item.symbol === currentInstrument.symbol && !item?.parentOrderId)
    .filter((item) => !item?.tpSLType || (item.tpSLType !== null && item.isTriggered === true));

  const filteredBuyOrders = filterAllOrders
    .filter((item) => item.side === 'BUY')
    .map((item) => Number(item.remaining) * Number(item.price));
  const filteredSellOrders = filterAllOrders
    .filter((item) => item.side === 'SELL')
    .map((item) => Number(item.remaining) * Number(item.price));

  const valBuy = filteredBuyOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  const valSell = filteredSellOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);

  const positionMargin = Number(PositionCalculator.getAllocatedMargin(position));
  const markPrice = Number(ticker?.oraclePrice || 0);

  const leverage = Number(orderMode.leverage);
  let sizeBuy = 0;
  let sizeSell = 0;

  let inputBuyPrice = 0;
  let inputSellPrice = 0;
  if (quantity.indexOf('%') !== -1) {
    const sizeOrder = Number(quantity.replace('%', '')) / 100;
    sizeBuy = sizeOrder * Number(maxBuyOpen);
    sizeSell = sizeOrder * Number(maxSellOpen);
  } else {
    sizeBuy = Number(quantity);
    sizeSell = Number(quantity);
  }
  if(symbolType === currentInstrument?.quoteCurrency && quantity.indexOf('%') === -1) {
    sizeBuy = sizeBuy  / Number(price)
    sizeSell = sizeSell  / Number(price)
  }
  if (orderType === OrderType.limit || orderType === OrderType.stopLimit || orderType === OrderType.postOnly) {
    inputBuyPrice = Number(price);
    inputSellPrice = Number(price);
  } else {
    inputBuyPrice = orderbook.asks[0] ? Number(orderbook.asks[0][0]) * (1 + 0.05 / 100) : 0;
    inputSellPrice = orderbook.bids[0] ? Number(orderbook.bids[0][0]) : 0;
  }
  const marBuy = new BigNumber(valBuy).dividedBy(leverage).toNumber();
  const marSell = new BigNumber(valSell).dividedBy(leverage).toNumber();
  const mulBuy = new BigNumber(inputBuyPrice).dividedBy(leverage).plus(inputBuyPrice).minus(markPrice).toNumber();
  const mulSell = new BigNumber(inputSellPrice)
    .dividedBy(leverage)
    .plus(
      new BigNumber(markPrice)
        .minus(inputSellPrice)
        .multipliedBy(new BigNumber(1).plus(new BigNumber(1).dividedBy(leverage))),
    )
    .toNumber();

  let sellOrderCost = 0;
  let buyOrderCost = 0;
  if (position) {
    // If user holds a Long position
    if (Number(position.currentQty) > 0) {
      // calculate sell order cost
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        sellOrderCost = Math.max(
          0,
          new BigNumber(new BigNumber(sizeSell).multipliedBy(inputSellPrice).dividedBy(leverage))
            .minus(new BigNumber(2).multipliedBy(positionMargin))
            .plus(marSell)
            .minus(marBuy)
            .toNumber(),
        );
      } else {
        sellOrderCost = Math.max(
          0,
          new BigNumber(new BigNumber(sizeSell).minus(position.currentQty))
            .multipliedBy(mulSell)
            .minus(positionMargin)
            .plus(marSell)
            .minus(marBuy)
            .toNumber(),
        );
      }

      // calculate buy order cost
      if (inputBuyPrice > markPrice) {
        buyOrderCost = new BigNumber(sizeBuy).multipliedBy(mulBuy).toNumber();
      } else {
        buyOrderCost = new BigNumber(inputBuyPrice).multipliedBy(sizeBuy).dividedBy(leverage).toNumber();
      }
    }
    // If user holds a Short position
    else {
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        sellOrderCost = new BigNumber(sizeSell).multipliedBy(inputSellPrice).dividedBy(leverage).toNumber();
      } else {
        sellOrderCost = new BigNumber(sizeSell).multipliedBy(mulSell).toNumber();
      }

      if (inputBuyPrice > markPrice) {
        buyOrderCost = new BigNumber(sizeSell).multipliedBy(inputBuyPrice).dividedBy(leverage).toNumber();
      } else {
        buyOrderCost = new BigNumber(sizeSell).multipliedBy(mulBuy).toNumber();
      }
      // if (inputBuyPrice > markPrice) {
      //   buyOrderCost = Math.max(
      //     0,
      //     new BigNumber(
      //       new BigNumber(new BigNumber(sizeBuy).minus(new BigNumber(position.currentQty).abs())).multipliedBy(mulBuy),
      //     )
      //       .minus(positionMargin)
      //       .plus(marBuy)
      //       .minus(marSell)
      //       .toNumber(),
      //   );
      // } else {
      //   buyOrderCost = Math.max(
      //     0,
      //     new BigNumber(new BigNumber(sizeBuy).multipliedBy(inputBuyPrice).dividedBy(leverage))
      //       .minus(new BigNumber(2).multipliedBy(positionMargin))
      //       .plus(marBuy)
      //       .minus(marSell)
      //       .toNumber(),
      //   );
      // }
    }
  } else {
    // calculate sell order cost and max open
    if (marBuy > marSell) {
      if (inputSellPrice > markPrice) {
        sellOrderCost = Math.max(
          0,
          new BigNumber(new BigNumber(inputSellPrice).multipliedBy(sizeSell).dividedBy(leverage))
            .minus(marBuy)
            .plus(marSell)
            .toNumber(),
        );
      } else {
        sellOrderCost = new BigNumber(sizeSell)
          .multipliedBy(mulSell)
          .minus(Math.min(new BigNumber(sizeSell).multipliedBy(inputSellPrice).dividedBy(leverage).toNumber(), marBuy))
          .toNumber();
      }
    } else if (marBuy < marSell) {
      if (inputSellPrice > markPrice) {
        sellOrderCost = new BigNumber(inputSellPrice).multipliedBy(sizeSell).dividedBy(leverage).toNumber();
      } else {
        sellOrderCost = new BigNumber(sizeSell).multipliedBy(mulSell).toNumber();
      }
    } else {
      if (inputSellPrice > markPrice) {
        sellOrderCost = new BigNumber(inputSellPrice).multipliedBy(sizeSell).dividedBy(leverage).toNumber();
      } else {
        sellOrderCost = new BigNumber(sizeSell).multipliedBy(mulSell).toNumber();
      }
    }

    // calculate Buy order cost and max buy open
    if (marBuy > marSell) {
      if (inputBuyPrice > markPrice) {
        buyOrderCost = new BigNumber(sizeBuy).multipliedBy(mulBuy).toNumber();
      } else {
        buyOrderCost = new BigNumber(inputBuyPrice).multipliedBy(sizeBuy).dividedBy(leverage).toNumber();
      }
    } else if (marBuy < marSell) {
      if (inputBuyPrice > markPrice) {
        buyOrderCost = new BigNumber(sizeBuy)
          .multipliedBy(mulBuy)
          .minus(Math.min(new BigNumber(sizeBuy).multipliedBy(inputBuyPrice).dividedBy(leverage).toNumber(), marSell))
          .toNumber();
      } else {
        buyOrderCost = Math.max(
          0,
          new BigNumber(new BigNumber(inputBuyPrice).multipliedBy(sizeBuy).dividedBy(leverage))
            .minus(marSell)
            .plus(marBuy)
            .toNumber(),
        );
      }
    } else {
      if (inputBuyPrice > markPrice) {
        buyOrderCost = new BigNumber(sizeBuy).multipliedBy(mulBuy).toNumber();
      } else {
        buyOrderCost = new BigNumber(inputBuyPrice).multipliedBy(sizeBuy).dividedBy(leverage).toNumber();
      }
    }
  }


  // console.log('sellOrderCost', sellOrderCost)
  // if(symbolType === currentInstrument?.quoteCurrency) {
  //   buyOrderCost = sizeBuy
  //   sellOrderCost = sizeBuy
  // }
  setOrderValueSell(sellOrderCost.toString());
  setOrderValueBuy(buyOrderCost.toString());
};

export const calculateMaxUsdM = (
  price: string,
  orderType: string,
  currentInstrument: Instrument,
  allOrders: IOrder[],
  position: IPosition | undefined,
  ticker: ITicker | undefined,
  orderMode: any,
  orderbook: IOrderbook,
  availableBalance: string,
  setMaxSellOpen: (value: string) => void,
  setMaxBuyOpen: (value: string) => void,
) => {
  const initialValue = 0;
  let valBuy = 0;
  let valSell = 0;
  if (
    Number(price) === 0 &&
    orderType !== OrderType.market &&
    orderType !== OrderType.stopMarket &&
    orderType !== OrderType.trailingStop
  ) {
    setMaxSellOpen('0');
    setMaxBuyOpen('0');
    return;
  }
  if (allOrders) {
    // filter stopMarket, stop limit, takeprofit, stoploss, trailing stop
    const filterAllOrders = allOrders
      .filter((item) => item.type === 'LIMIT' && item.symbol === currentInstrument.symbol && !item?.parentOrderId)
      .filter((item) => !item?.tpSLType || (item.tpSLType !== null && item.isTriggered === true));

    const filteredBuyOrders = filterAllOrders
      .filter((item) => item.side === 'BUY')
      .map((item) => Number(item.remaining) * Number(item.price));
    const filteredSellOrders = filterAllOrders
      .filter((item) => item.side === 'SELL')
      .map((item) => Number(item.remaining) * Number(item.price));

    // ValBuy = Total value of all Buy open orders
    valBuy = filteredBuyOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
    // ValSel = Total value of all Sell open orders
    valSell = filteredSellOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  }

  const positionMargin = Number(PositionCalculator.getAllocatedMargin(position));

  const markPrice = Number(ticker?.oraclePrice || 0);
  const availBalance = Number(availableBalance || 0);
  const leverage = Number(orderMode.leverage);
  let inputBuyPrice = 0;
  let inputSellPrice = 0;

  if (orderType === OrderType.limit || orderType === OrderType.stopLimit || orderType === OrderType.postOnly) {
    inputBuyPrice = Number(price);
    inputSellPrice = Number(price);
  } else {
    inputBuyPrice = orderbook.asks[0] ? Number(orderbook.asks[0][0]) * (1 + 0.05 / 100) : 0;
    inputSellPrice = orderbook.bids[0] ? Number(orderbook.bids[0][0]) : 0;
  }

  // MarBuy = Total margin of all Buy open orders
  // const marBuy = valBuy / leverage;
  const marBuy = new BigNumber(valBuy).dividedBy(leverage).toNumber();
  //  MarSel = Total margin of all Sell open orders
  // const marSell = valSell / leverage;
  const marSell = new BigNumber(valSell).dividedBy(leverage).toNumber();
  //  MulBuy = Input price/ Leverage + Input price - Mark price
  //  MulSel = Input price/ Leverage + (Mark price - Input price) * (1 + 1/ Leverage)
  const mulBuy = new BigNumber(inputBuyPrice).dividedBy(leverage).plus(inputBuyPrice).minus(markPrice).toNumber();
  const mulSell = new BigNumber(inputSellPrice)
    .dividedBy(leverage)
    .plus(
      new BigNumber(markPrice)
        .minus(inputSellPrice)
        .multipliedBy(new BigNumber(1).plus(new BigNumber(1).dividedBy(leverage))),
    )
    .toNumber();

  let maxSell = 0;
  let maxBuy = 0;
  if (position) {
    // If user holds a Long position
    if (Number(position.currentQty) > 0) {
      // calculate max sell open
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        // Max Sell open = (Available balance + MarBuy - MarSel + 2 * Position Margin) * Leverage / Input price
        maxSell = new BigNumber(
          new BigNumber(availBalance).plus(marBuy).minus(marSell).plus(new BigNumber(2).multipliedBy(positionMargin)),
        )
          .multipliedBy(leverage)
          .dividedBy(inputSellPrice)
          .toNumber();
      } else {
        // Max Sell open = (Available balance + MarBuy - MarSel + Position Margin) / MulSel + Position size
        maxSell = new BigNumber(new BigNumber(availBalance).plus(marBuy).minus(marSell).plus(positionMargin))
          .dividedBy(mulSell)
          .plus(position.currentQty)
          .toNumber();
      }

      // calculate max buy open
      if (inputBuyPrice > markPrice) {
        // Max Buy open = Available balance/ MulBuy
        maxBuy = new BigNumber(availBalance).dividedBy(mulBuy).toNumber();
      } else {
        // Max Buy open = Available balance * Leverage/ Input price
        maxBuy = new BigNumber(availBalance).multipliedBy(leverage).dividedBy(inputBuyPrice).toNumber();
      }
    }
    // If user holds a Short position
    else {
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        // Max Sell open = Available balance * Leverage/ Input price
        maxSell = new BigNumber(availBalance).multipliedBy(leverage).dividedBy(inputSellPrice).toNumber();
      } else {
        maxSell = new BigNumber(availBalance).dividedBy(mulSell).toNumber();
      }

      if (inputBuyPrice > markPrice) {
        maxBuy = new BigNumber(new BigNumber(availBalance).plus(marSell).minus(marBuy).plus(positionMargin))
          .dividedBy(mulBuy)
          .plus(new BigNumber(position.currentQty).abs())
          .toNumber();
      } else {
        maxBuy = new BigNumber(
          new BigNumber(availBalance).plus(marSell).minus(marBuy).plus(new BigNumber(2).multipliedBy(positionMargin)),
        )
          .multipliedBy(leverage)
          .dividedBy(inputBuyPrice)
          .toNumber();
      }
    }
  } else {
    // calculate max sell open
    if (marBuy > marSell) {
      if (inputSellPrice > markPrice) {
        // Max Sell open = (Available Balance - MarSel + MarBuy) * Leverage / Input price
        maxSell = new BigNumber(new BigNumber(availBalance).minus(marSell).plus(marBuy))
          .multipliedBy(leverage)
          .dividedBy(inputSellPrice)
          .toNumber();
      } else {
        // "Max Sell open = min [(Available balance + MarBuy)/ MulSel;
        // Available balance / (MulSel - Input price/ Leverage)]"
        maxSell = Math.min(
          new BigNumber(new BigNumber(availBalance).plus(marBuy)).dividedBy(mulSell).toNumber(),
          new BigNumber(availBalance)
            .dividedBy(new BigNumber(mulSell).minus(new BigNumber(inputSellPrice).dividedBy(leverage)))
            .toNumber(),
        );
      }
    } else if (marBuy < marSell) {
      if (inputSellPrice > markPrice) {
        // Max Sell open = Available Balance * Leverage / Input price
        maxSell = new BigNumber(availBalance).multipliedBy(leverage).dividedBy(inputSellPrice).toNumber();
      } else {
        // Max Sell open = Available balance/ MulSel
        maxSell = new BigNumber(availBalance).dividedBy(mulSell).toNumber();
      }
    } else {
      if (inputSellPrice > markPrice) {
        // Max Sell open = Available Balance * Leverage / Input price
        maxSell = new BigNumber(availBalance).multipliedBy(leverage).dividedBy(inputSellPrice).toNumber();
      } else {
        // Max Sell open = Available balance/ MulSel
        maxSell = new BigNumber(availBalance).dividedBy(mulSell).toNumber();
      }
    }

    // calculate Buy order cost and max buy open
    if (marBuy > marSell) {
      if (inputBuyPrice > markPrice) {
        // Max Buy open = Available Balance / MulBuy
        maxBuy = new BigNumber(availBalance).dividedBy(mulBuy).toNumber();
      } else {
        // Max Buy open = Available Balance * Leverage / Input price
        maxBuy = new BigNumber(availBalance).multipliedBy(leverage).dividedBy(inputBuyPrice).toNumber();
      }
    } else if (marBuy < marSell) {
      if (inputBuyPrice > markPrice) {
        // Max Buy open = min [(Available balance + MarSel)/MulBuy;
        // Available balance/ (MulBuy - Input price/ Leverage)
        maxBuy = Math.min(
          new BigNumber(new BigNumber(availBalance).plus(marSell)).dividedBy(mulBuy).toNumber(),
          new BigNumber(availBalance)
            .dividedBy(new BigNumber(mulBuy).minus(new BigNumber(inputBuyPrice).dividedBy(leverage)))
            .toNumber(),
        );
      } else {
        // Max Buy open = (Available balance + MarSel - MarBuy) * Leverage/ Input price
        maxBuy = new BigNumber(
          new BigNumber(new BigNumber(availBalance).plus(marSell).minus(marBuy)).multipliedBy(leverage),
        )
          .dividedBy(inputBuyPrice)
          .toNumber();
      }
    } else {
      if (inputBuyPrice > markPrice) {
        // Max Buy open = Available Balance / MulBuy
        maxBuy = new BigNumber(availBalance).dividedBy(mulBuy).toNumber();
      } else {
        // Max Buy open = Available Balance * Leverage / Input price
        maxBuy = new BigNumber(availBalance).multipliedBy(leverage).dividedBy(inputBuyPrice).toNumber();
      }
    }
  }

  setMaxSellOpen(maxSell.toString());
  setMaxBuyOpen(maxBuy.toString());
};

export const calculateCostCoinM = (
  price: string,
  quantity: string,
  orderType: string,
  currentInstrument: Instrument,
  allOrders: IOrder[],
  position: IPosition | undefined,
  ticker: ITicker | undefined,
  orderMode: any,
  orderbook: IOrderbook,
  maxBuyOpen: string,
  maxSellOpen: string,
  contractMul: number,
  setOrderValueSell: (value: string) => void,
  setOrderValueBuy: (value: string) => void,
) => {
  const initialValue = 0;
  if (
    (Number(price) === 0 &&
      orderType !== OrderType.market &&
      orderType !== OrderType.stopMarket &&
      orderType !== OrderType.trailingStop) ||
    Number(quantity) === 0
  ) {
    setOrderValueSell('0.00');
    setOrderValueBuy('0.00');
    return;
  }
  // Value of each Buy/Sell order = Input price * Size
  if (!allOrders) return;
  // filter stopMarket, stop limit, takeprofit, stoploss, trailing stop
  const filterAllOrders = allOrders
    .filter((item) => item.type === 'LIMIT' && item.symbol === currentInstrument.symbol && !item?.parentOrderId)
    .filter((item) => !item?.tpSLType || (item.tpSLType !== null && item.isTriggered === true));
  const leverage = Number(orderMode.leverage);
  console.log('🚀 ~ file: OrderHelper.ts:473 ~ leverage:', leverage);
  console.log('🚀 ~ file: OrderHelper.ts:473 ~ contractMul:', contractMul);

  const filteredBuyOrders = filterAllOrders
    .filter((item) => item.side === 'BUY')
    .map((item) =>
      new BigNumber(Number(item.remaining))
        .multipliedBy(contractMul)
        .dividedBy(new BigNumber(Number(item.price)).multipliedBy(leverage))
        .toNumber(),
    );
  const filteredSellOrders = filterAllOrders
    .filter((item) => item.side === 'SELL')
    .map((item) =>
      new BigNumber(Number(item.remaining))
        .multipliedBy(contractMul)
        .dividedBy(new BigNumber(Number(item.price)).multipliedBy(leverage))
        .toNumber(),
    );

  const marBuy = filteredBuyOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  console.log('🚀 ~ file: OrderHelper.ts:492 ~ marBuy:', marBuy);
  const marSell = filteredSellOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  console.log('🚀 ~ file: OrderHelper.ts:494 ~ marSell:', marSell);
  // to-do
  const positionMargin = Number(PositionCalculator.getAllocatedMargin(position));
  const markPrice = Number(ticker?.oraclePrice || 0);
  console.log('🚀 ~ file: OrderHelper.ts:500 ~ markPrice:', markPrice);

  let sizeBuy = 0;
  let sizeSell = 0;

  let inputBuyPrice = 0;
  let inputSellPrice = 0;
  if (quantity.indexOf('%') !== -1) {
    const sizeOrder = Number(quantity.replace('%', '')) / 100;
    sizeBuy = Number(new BigNumber(sizeOrder).times(maxBuyOpen).toFixed(0, BigNumber.ROUND_DOWN));
    sizeSell = Number(new BigNumber(sizeOrder).times(maxSellOpen).toFixed(0, BigNumber.ROUND_DOWN));
  } else {
    sizeBuy = Number(quantity);
    console.log('🚀 ~ file: OrderHelper.ts:511 ~ sizeBuy:', sizeBuy);
    sizeSell = Number(quantity);
    console.log('🚀 ~ file: OrderHelper.ts:512 ~ sizeSell:', sizeSell);
  }
  if (orderType === OrderType.limit || orderType === OrderType.stopLimit || orderType === OrderType.postOnly) {
    inputBuyPrice = Number(price);
    console.log('🚀 ~ file: OrderHelper.ts:515 ~ inputBuyPrice:', inputBuyPrice);
    inputSellPrice = Number(price);
    console.log('🚀 ~ file: OrderHelper.ts:517 ~ inputSellPrice:', inputSellPrice);
  } else {
    inputBuyPrice = orderbook.asks[0] ? Number(orderbook.asks[0][0]) * (1 + 0.05 / 100) : 0;
    inputSellPrice = orderbook.bids[0] ? Number(orderbook.bids[0][0]) : 0;
  }
  // MulBuy = 1/(Input price * Leverage) + (1/ Mark price - 1/ Input price) * (1 + 1/ Leverage)
  // MulSel = 1/(Input price * Leverage) + (1/ Input price - 1/ Mark price)
  const mulBuy = new BigNumber(new BigNumber(1).dividedBy(new BigNumber(inputBuyPrice).multipliedBy(leverage)))
    .plus(
      new BigNumber(
        new BigNumber(new BigNumber(1).dividedBy(markPrice)).minus(new BigNumber(1).dividedBy(inputBuyPrice)),
      ).multipliedBy(new BigNumber(1).plus(new BigNumber(1).dividedBy(leverage))),
    )
    .toNumber();
  const mulSell = new BigNumber(new BigNumber(1).dividedBy(new BigNumber(inputSellPrice).multipliedBy(leverage)))
    .plus(
      new BigNumber(
        new BigNumber(new BigNumber(1).dividedBy(inputSellPrice)).minus(new BigNumber(1).dividedBy(markPrice)),
      ),
    )
    .toNumber();
  console.log('🚀 ~ file: OrderHelper.ts:529 ~ mulSell:', mulSell);
  console.log('🚀 ~ file: OrderHelper.ts:522 ~ mulBuy:', mulBuy);

  let sellOrderCost = 0;
  let buyOrderCost = 0;
  if (position) {
    // If user holds a Long position
    if (Number(position.currentQty) > 0) {
      // calculate sell order cost
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        // Sell order cost = max (0; Size * Contract Multiplier / (Leverage * Input price) - 2 * Position Margin + MarSel - MarBuy)
        sellOrderCost = Math.max(
          0,
          new BigNumber(
            new BigNumber(sizeSell)
              .multipliedBy(contractMul)
              .dividedBy(new BigNumber(leverage).multipliedBy(inputSellPrice)),
          )
            .minus(new BigNumber(2).multipliedBy(positionMargin))
            .plus(marSell)
            .minus(marBuy)
            .toNumber(),
        );
      } else {
        // Sell order cost = max (0; (Order size - Position size) * Contract Multiplier * MulSel - Position Margin + MarSel - MarBuy)
        sellOrderCost = Math.max(
          0,
          new BigNumber(new BigNumber(sizeSell).minus(position.currentQty))
            .multipliedBy(contractMul)
            .multipliedBy(mulSell)
            .minus(positionMargin)
            .plus(marSell)
            .minus(marBuy)
            .toNumber(),
        );
      }

      // calculate buy order cost
      if (inputBuyPrice > markPrice) {
        // Buy order cost = Size * Contract Multiplier * MulBuy
        buyOrderCost = new BigNumber(sizeBuy).multipliedBy(contractMul).multipliedBy(mulBuy).toNumber();
      } else {
        // Buy order cost = Size *Contract Multiplier / (Leverage * Input price)
        buyOrderCost = new BigNumber(sizeBuy)
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(leverage).multipliedBy(inputBuyPrice))
          .toNumber();
      }
    }
    // If user holds a Short position
    else {
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        // Sell order cost = Size *Contract Multiplier / (Leverage * Input price)
        sellOrderCost = new BigNumber(sizeSell)
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(leverage).multipliedBy(inputSellPrice))
          .toNumber();
      } else {
        // Sell order cost = Size * Contract Multiplier * MulSel
        sellOrderCost = new BigNumber(sizeSell).multipliedBy(contractMul).multipliedBy(mulSell).toNumber();
      }

      if (inputBuyPrice > markPrice) {
        // Buy order cost = max (0; (Order size - Position size) * Contract Multiplier * MulBuy - Position Margin + MarBuy - MarSel)
        buyOrderCost = Math.max(
          0,
          new BigNumber(
            new BigNumber(new BigNumber(sizeBuy).minus(new BigNumber(position.currentQty).abs()))
              .multipliedBy(contractMul)
              .multipliedBy(mulBuy),
          )
            .minus(positionMargin)
            .plus(marBuy)
            .minus(marSell)
            .toNumber(),
        );
      } else {
        // Buy order cost = max (0; Size *Contract Multiplier / (Leverage * Input price) - 2 * Position Margin + MarBuy - MarSel)
        buyOrderCost = Math.max(
          0,
          new BigNumber(
            new BigNumber(sizeBuy)
              .multipliedBy(contractMul)
              .dividedBy(new BigNumber(leverage).multipliedBy(inputBuyPrice)),
          )
            .minus(new BigNumber(2).multipliedBy(positionMargin))
            .plus(marBuy)
            .minus(marSell)
            .toNumber(),
        );
      }
    }
  } else {
    // calculate sell order cost
    if (marBuy > marSell) {
      if (inputSellPrice > markPrice) {
        // Sell order cost = max (0, Size * Contract Multiplier / (Leverage * Input price) - MarBuy + MarSel)
        sellOrderCost = Math.max(
          0,
          new BigNumber(sizeSell)
            .multipliedBy(contractMul)
            .dividedBy(new BigNumber(leverage).multipliedBy(inputSellPrice))
            .minus(marBuy)
            .plus(marSell)
            .toNumber(),
        );
      } else {
        // "Sell order cost = Size * Contract Multiplier * MulSel -
        // min(Size *Contract Multiplier / (Leverage * Input price), MarBuy - MarSel)"
        sellOrderCost = new BigNumber(sizeSell)
          .multipliedBy(contractMul)
          .multipliedBy(mulSell)
          .minus(
            Math.min(
              new BigNumber(sizeSell)
                .multipliedBy(contractMul)
                .dividedBy(new BigNumber(leverage).multipliedBy(inputSellPrice))
                .toNumber(),
              new BigNumber(marBuy).minus(marSell).toNumber(),
            ),
          )
          .toNumber();
      }
    } else if (marBuy < marSell) {
      if (inputSellPrice > markPrice) {
        // Sell order cost = Size * Contract Multiplier / (Leverage * Input price)
        sellOrderCost = new BigNumber(sizeSell)
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(leverage).multipliedBy(inputSellPrice))
          .toNumber();
      } else {
        // Sell order cost = Size * Contract Multiplier * MulSel
        sellOrderCost = new BigNumber(sizeSell).multipliedBy(contractMul).multipliedBy(mulSell).toNumber();
      }
    } else {
      if (inputSellPrice > markPrice) {
        console.log('inputSellPrice > markPrice');
        // Sell order cost = Size * Contract Multiplier / (Leverage * Input price)
        sellOrderCost = new BigNumber(sizeSell)
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(leverage).multipliedBy(inputSellPrice))
          .toNumber();
      } else {
        //  Sell order cost = Size * Contract Multiplier * MulSel
        sellOrderCost = new BigNumber(sizeSell).multipliedBy(contractMul).multipliedBy(mulSell).toNumber();
      }
    }

    // calculate Buy order cost
    if (marBuy > marSell) {
      if (inputBuyPrice > markPrice) {
        //Buy order cost = Size * Contract Multiplier * MulBuy
        buyOrderCost = new BigNumber(sizeBuy).multipliedBy(contractMul).multipliedBy(mulBuy).toNumber();
      } else {
        // Buy order cost = Size *Contract Multiplier / (Leverage * Input price)
        buyOrderCost = new BigNumber(sizeBuy)
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(leverage).multipliedBy(inputBuyPrice))
          .toNumber();
      }
    } else if (marBuy < marSell) {
      if (inputBuyPrice > markPrice) {
        // "Buy order cost = Size * Contract Multiplier * MulBuy -
        // min (Size * Contract Multiplier / (Leverage * Input price), MarSel - MarBuy)"
        buyOrderCost = new BigNumber(sizeBuy)
          .multipliedBy(contractMul)
          .multipliedBy(mulBuy)
          .minus(
            Math.min(
              new BigNumber(sizeBuy)
                .multipliedBy(contractMul)
                .dividedBy(new BigNumber(leverage).multipliedBy(inputBuyPrice))
                .toNumber(),
              new BigNumber(marSell).minus(marBuy).toNumber(),
            ),
          )
          .toNumber();
      } else {
        //Buy order cost = max (0, Size * Contract Multiplier / (Leverage * Input price) - MarSel + MarBuy)
        buyOrderCost = Math.max(
          0,
          new BigNumber(
            new BigNumber(sizeBuy)
              .multipliedBy(contractMul)
              .dividedBy(new BigNumber(leverage).multipliedBy(inputBuyPrice)),
          )
            .minus(marSell)
            .plus(marBuy)
            .toNumber(),
        );
      }
    } else {
      if (inputBuyPrice > markPrice) {
        // Buy order cost = Size * Contract Multiplier * MulBuy
        buyOrderCost = new BigNumber(sizeBuy).multipliedBy(contractMul).multipliedBy(mulBuy).toNumber();
      } else {
        // Buy order cost = Size * Contract Multiplier / (Leverage * Input price)
        buyOrderCost = new BigNumber(sizeBuy)
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(leverage).multipliedBy(inputBuyPrice))
          .toNumber();
      }
    }
  }
  if (sellOrderCost === Number.POSITIVE_INFINITY) sellOrderCost = 0;
  if (buyOrderCost === Number.POSITIVE_INFINITY) buyOrderCost = 0;
  setOrderValueSell(sellOrderCost.toString());
  console.log('🚀 ~ file: OrderHelper.ts:725 ~ sellOrderCost:', sellOrderCost);
  setOrderValueBuy(buyOrderCost.toString());
  console.log('🚀 ~ file: OrderHelper.ts:727 ~ buyOrderCost:', buyOrderCost);
};

export const calculateMaxCoinM = (
  price: string,
  orderType: string,
  currentInstrument: Instrument,
  allOrders: IOrder[],
  position: IPosition | undefined,
  ticker: ITicker | undefined,
  orderMode: any,
  orderbook: IOrderbook,
  availableBalance: string,
  contractMul: number,
  setMaxSellOpen: (value: string) => void,
  setMaxBuyOpen: (value: string) => void,
) => {
  const initialValue = 0;
  const leverage = Number(orderMode.leverage);
  if (
    Number(price) === 0 &&
    orderType !== OrderType.market &&
    orderType !== OrderType.stopMarket &&
    orderType !== OrderType.trailingStop
  ) {
    setMaxSellOpen('0');
    setMaxBuyOpen('0');
    return;
  }
  let marBuy = 0;
  let marSell = 0;
  if (allOrders) {
    // filter stopMarket, stop limit, takeprofit, stoploss, trailing stop
    const filterAllOrders = allOrders
      .filter((item) => item.type === 'LIMIT' && item.symbol === currentInstrument.symbol && !item?.parentOrderId)
      .filter((item) => !item?.tpSLType || (item.tpSLType !== null && item.isTriggered === true));
    const filteredBuyOrders = filterAllOrders
      .filter((item) => item.side === 'BUY')
      .map((item) =>
        new BigNumber(Number(item.remaining))
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(Number(item.price)).multipliedBy(leverage))
          .toNumber(),
      );
    const filteredSellOrders = filterAllOrders
      .filter((item) => item.side === 'SELL')
      .map((item) =>
        new BigNumber(Number(item.remaining))
          .multipliedBy(contractMul)
          .dividedBy(new BigNumber(Number(item.price)).multipliedBy(leverage))
          .toNumber(),
      );
    marBuy = filteredBuyOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
    marSell = filteredSellOrders.reduce((accumulator, currentValue) => accumulator + currentValue, initialValue);
  }

  const positionMargin = Number(PositionCalculator.getAllocatedMargin(position));

  const markPrice = Number(ticker?.oraclePrice || 0);
  const availBalance = Number(availableBalance || 0);

  let inputBuyPrice = 0;
  let inputSellPrice = 0;

  if (orderType === OrderType.limit || orderType === OrderType.stopLimit || orderType === OrderType.postOnly) {
    inputBuyPrice = Number(price);
    inputSellPrice = Number(price);
  } else {
    inputBuyPrice = orderbook.asks[0] ? Number(orderbook.asks[0][0]) * (1 + 0.05 / 100) : 0;
    inputSellPrice = orderbook.bids[0] ? Number(orderbook.bids[0][0]) : 0;
  }
  // MulBuy = 1/(Input price * Leverage) + (1/ Mark price - 1/ Input price) * (1 + 1/ Leverage)
  // MulSel = 1/(Input price * Leverage) + (1/ Input price - 1/ Mark price)
  const mulBuy = new BigNumber(new BigNumber(1).dividedBy(new BigNumber(inputBuyPrice).multipliedBy(leverage)))
    .plus(
      new BigNumber(
        new BigNumber(new BigNumber(1).dividedBy(markPrice)).minus(new BigNumber(1).dividedBy(inputBuyPrice)),
      ).multipliedBy(new BigNumber(1).plus(new BigNumber(1).dividedBy(leverage))),
    )
    .toNumber();
  const mulSell = new BigNumber(new BigNumber(1).dividedBy(new BigNumber(inputSellPrice).multipliedBy(leverage)))
    .plus(
      new BigNumber(
        new BigNumber(new BigNumber(1).dividedBy(inputSellPrice)).minus(new BigNumber(1).dividedBy(markPrice)),
      ),
    )
    .toNumber();

  let maxSell = 0;
  let maxBuy = 0;
  if (position) {
    // If user holds a Long position
    if (Number(position.currentQty) > 0) {
      // calculate max sell open
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        // Max Sell open = (Available balance + MarBuy - MarSel + 2 * Position Margin)
        // * Leverage * Input price / Contract Multiplier
        maxSell = new BigNumber(
          new BigNumber(availBalance).plus(marBuy).minus(marSell).plus(new BigNumber(2).multipliedBy(positionMargin)),
        )
          .multipliedBy(leverage)
          .multipliedBy(inputSellPrice)
          .dividedBy(contractMul)
          .toNumber();
      } else {
        // Max Sell open = (Available balance + MarBuy - MarSel + Position Margin) /
        //  (MulSel * Contract Multiplier) + Position size
        maxSell = new BigNumber(new BigNumber(availBalance).plus(marBuy).minus(marSell).plus(positionMargin))
          .dividedBy(new BigNumber(mulSell).multipliedBy(contractMul))
          .plus(position.currentQty)
          .toNumber();
      }

      // calculate max buy open
      if (inputBuyPrice > markPrice) {
        // Max Buy open = Available balance / (MulBuy * Contract Multiplier)
        maxBuy = new BigNumber(availBalance).dividedBy(new BigNumber(mulBuy).multipliedBy(contractMul)).toNumber();
      } else {
        // Max Buy open = Available balance * Input price * Leverage / Contract Multiplier
        maxBuy = new BigNumber(availBalance)
          .multipliedBy(inputBuyPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      }
    }
    // If user holds a Short position
    else {
      // If Input price > Mark price
      if (inputSellPrice > markPrice) {
        // Max Sell open = Available balance * Input price * Leverage / Contract Multiplier
        maxSell = new BigNumber(availBalance)
          .multipliedBy(inputSellPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      } else {
        // Max Sell open = Available balance/ (MulSel * Contract Multiplier)
        maxSell = new BigNumber(availBalance).dividedBy(new BigNumber(mulSell).multipliedBy(contractMul)).toNumber();
      }

      if (inputBuyPrice > markPrice) {
        // Max Buy open = (Available balance  + MarSel - MarBuy + Position Margin) /
        // (MulBuy * Contract Multiplier) + Position size
        maxBuy = new BigNumber(new BigNumber(availBalance).plus(marSell).minus(marBuy).plus(positionMargin))
          .dividedBy(new BigNumber(mulBuy).multipliedBy(contractMul))
          .plus(new BigNumber(position.currentQty).abs())
          .toNumber();
      } else {
        // Max Buy open = (Available balance + MarSel - MarBuy + 2 * Position Margin) *
        // Leverage * Input price / Contract Multiplier
        maxBuy = new BigNumber(
          new BigNumber(availBalance).plus(marSell).minus(marBuy).plus(new BigNumber(2).multipliedBy(positionMargin)),
        )
          .multipliedBy(leverage)
          .multipliedBy(inputBuyPrice)
          .dividedBy(contractMul)
          .toNumber();
      }
    }
  } else {
    // calculate max sell open
    if (marBuy > marSell) {
      if (inputSellPrice > markPrice) {
        // Max Sell open = int (Available balance - MarSel + MarBuy) * Input price * Leverage / Contract Multiplier
        maxSell = new BigNumber(new BigNumber(availBalance).minus(marSell).plus(marBuy))
          .multipliedBy(inputSellPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      } else {
        // "Max Sell open = int min {(Available balance + MarBuy - MarSel) / (Contract Multiplier * MulSel);
        // Available balance / [Contract Multiplier * MulSel - Contract Multiplier / (Leverage * Input price)]} "
        maxSell = Math.min(
          new BigNumber(new BigNumber(availBalance).plus(marBuy).minus(marSell))
            .dividedBy(new BigNumber(contractMul).multipliedBy(mulSell))
            .toNumber(),
          new BigNumber(availBalance)
            .dividedBy(
              new BigNumber(contractMul)
                .multipliedBy(mulSell)
                .minus(new BigNumber(contractMul).dividedBy(new BigNumber(leverage).multipliedBy(inputSellPrice))),
            )
            .toNumber(),
        );
      }
    } else if (marBuy < marSell) {
      if (inputSellPrice > markPrice) {
        // Max Sell open = int Available Balance * Input price * Leverage / Contract Multiplier
        maxSell = new BigNumber(availBalance)
          .multipliedBy(inputSellPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      } else {
        // Max Sell open = int Available Balance/ (MulSel * Contract Multiplier)
        maxSell = new BigNumber(availBalance).dividedBy(new BigNumber(mulSell).multipliedBy(contractMul)).toNumber();
      }
    } else {
      if (inputSellPrice > markPrice) {
        // Max Sell open = int Available Balance * Input price * Leverage / Contract Multiplier
        maxSell = new BigNumber(availBalance)
          .multipliedBy(inputSellPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      } else {
        // Max Sell open = int Available Balance/ (MulSel * Contract Multiplier)
        maxSell = new BigNumber(availBalance).dividedBy(new BigNumber(mulSell).multipliedBy(contractMul)).toNumber();
      }
    }

    // calculate Buy order cost and max buy open
    if (marBuy > marSell) {
      if (inputBuyPrice > markPrice) {
        // Max Buy open = int Available Balance/ (MulBuy * Contract Multiplier)
        maxBuy = new BigNumber(availBalance).dividedBy(new BigNumber(mulBuy).multipliedBy(contractMul)).toNumber();
      } else {
        // Max Buy open = int Available Balance * Input price * Leverage / Contract Multiplier
        maxBuy = new BigNumber(availBalance)
          .multipliedBy(inputBuyPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      }
    } else if (marBuy < marSell) {
      if (inputBuyPrice > markPrice) {
        //         "Max Buy open = int min {(Available balance + MarSel - MarBuy) / (Contract Multiplier * MulBuy);
        // Available balance / [Contract Multiplier * MulBuy - Contract Multiplier / (Leverage * Input price)]} "
        maxBuy = Math.min(
          new BigNumber(new BigNumber(availBalance).plus(marSell).minus(marBuy))
            .dividedBy(new BigNumber(contractMul).multipliedBy(mulBuy))
            .toNumber(),
          new BigNumber(availBalance)
            .dividedBy(
              new BigNumber(contractMul)
                .multipliedBy(mulBuy)
                .minus(new BigNumber(contractMul).dividedBy(new BigNumber(leverage).multipliedBy(inputBuyPrice))),
            )
            .toNumber(),
        );
      } else {
        // Max Buy open = int (Available Balance + MarSel - MarBuy) * Input price * Leverage / Contract Multiplier
        maxBuy = new BigNumber(new BigNumber(availBalance).plus(marSell).minus(marBuy))
          .multipliedBy(inputBuyPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      }
    } else {
      if (inputBuyPrice > markPrice) {
        // Max Buy open = int Available Balance/ (MulBuy * Contract Multiplier)
        maxBuy = new BigNumber(availBalance).dividedBy(new BigNumber(mulBuy).multipliedBy(contractMul)).toNumber();
      } else {
        // Max Buy open = int Available Balance * Input price * Leverage / Contract Multiplier
        maxBuy = new BigNumber(availBalance)
          .multipliedBy(inputBuyPrice)
          .multipliedBy(leverage)
          .dividedBy(contractMul)
          .toNumber();
      }
    }
  }
  const formattedMaxSell = new BigNumber(maxSell).toFixed(0, BigNumber.ROUND_DOWN).toString();
  // console.log('🚀 ~ file: OrderHelper.ts:986 ~ formattedMaxSell:', formattedMaxSell);
  const formattedMaxBuy = new BigNumber(maxBuy).toFixed(0, BigNumber.ROUND_DOWN).toString();
  // console.log('🚀 ~ file: OrderHelper.ts:988 ~ formattedMaxBuy:', formattedMaxBuy);
  setMaxSellOpen(formattedMaxSell);
  setMaxBuyOpen(formattedMaxBuy);
};
