import InputNumber from "../../Components/ContentHistoryDashboard/PositionHistory/InputNumber";
import { IPositionItem } from "../../PositionRecord";

export default function LimitInput({
  row,
  onChange,
}: {
  row: IPositionItem;
  onChange: (value: string, position: IPositionItem) => void;
}) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 12 }}>
      <div className="container-input">
        <span className="container-title">Close price</span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputNumber
            defaultValue={Number(row?.limitPrice).toFixed(6)}
            position={row}
            type="price"
            onChange={onChange}
          />
          <span className="container-title">{row?.asset}</span>
        </div>
      </div>
    </div>
  );
}
