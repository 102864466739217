// import { Instrument } from 'src/services/instrument';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosInstance from 'src/helpers/config';
import { IOrder } from 'src/interfaces/order';
import { CancelToken } from './transaction-history';

const initialState = {
  error: '',
  loading: false,
  orders: [] as IOrder[],
  firstLoad: true,
  totalPage: 0,
  totalItem: 0,
  currentPage: 1,
  currentSymbol: '',
  allOrders: [] as IOrder[],
};
let cancelOpenOrder: any;

export const getOpenOrder = createAsyncThunk(
  'get-order',
  async ({
    params,
    payload,
  }: {
    params: { page: number; size: number };
    payload?: { side?: string | null; type?: string | null; symbol?: string | null; contractType: string };
  }) => {
    if (cancelOpenOrder != undefined) {
      cancelOpenOrder();
    }
    return await axiosInstance.post(`/order/open`, payload, {
      params: params,
      timeout: 60000,
      cancelToken: new CancelToken(function executor(c) {
        cancelOpenOrder = c;
      }),
    });
  },
);

export const getAllOpenOrder = createAsyncThunk('getAllOpenOrder', async (contractType: string) => {
  return await axiosInstance.post(`/order/open`, { contractType, getAll: true });
});

export const cancelAllOrder = createAsyncThunk(
  '/order/cancel-all-order',
  async (params: { type: string; contractType: string }) => {
    return await axiosInstance.delete('/order/cancel-order', {
      params: params,
    });
  },
);

export const cancelOrder = createAsyncThunk('/order/cancel-order', async (id: number) => {
  return await axiosInstance.delete(`/order/${id}`);
});

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    updateOrders: (state, action) => {
      state.orders = action.payload.orders;
      state.totalItem = action.payload.newTotalItem;
      state.totalPage = action.payload.newTotalPage;
    },
    updateAllOrders: (state, action) => {
      state.allOrders = action.payload;
    },
    setFirstLoadOrder: (state, action) => {
      state.firstLoad = action.payload;
    },
    resetInitStateOrder: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
  extraReducers: {
    [`${getOpenOrder.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getOpenOrder.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.firstLoad = true;
    },
    [`${getOpenOrder.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.orders = action.payload.data;
      state.firstLoad = false;
      state.totalPage = action.payload?.metadata?.totalPage;
      state.totalItem = action.payload?.metadata?.totalItem;
      state.currentPage = Number(action?.payload?.metadata?.query?.page);
      state.currentSymbol = action?.meta?.arg?.payload?.symbol ?? '';
    },
    [`${getAllOpenOrder.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getAllOpenOrder.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [`${getAllOpenOrder.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.allOrders = action.payload.data;
    },

    [`${cancelAllOrder.pending}`]: (state) => {
      // state.loading = true;
    },
    [`${cancelAllOrder.rejected}`]: (state) => {
      // state.loading = false;
    },
    [`${cancelAllOrder.fulfilled}`]: (state) => {
      // state.loading = false;
    },

    [`${cancelOrder.pending}`]: (state) => {
      // state.loading = true;
    },

    [`${cancelOrder.rejected}`]: (state) => {
      // state.loading = false;
    },
    [`${cancelOrder.fulfilled}`]: (state) => {
      // state.loading = false;
    },
  },
});

const { reducer: orderReducer } = orderSlice;

export default orderReducer;

export const { updateOrders, updateAllOrders, setFirstLoadOrder, resetInitStateOrder } = orderSlice.actions;
