import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from 'src/features/Market/components/TradingRule/TradingRule.module.scss';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getTradingRule } from 'src/services/general';
import { formatIntBalance } from 'src/helpers/numberFormatter';
import TooltipText from 'src/components/cores/TooltipText';
import { RootState } from 'src/store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const cx = classNames.bind(styles);

export const TradingRules = (props: any) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();
  const isCoinM = useSelector((state: RootState) => state.typeTrade.isCoinM);
  const tradingRuleValue = useAppSelector((state) => state.general.tradingRule);

  useEffect(() => {
    dispatch(getTradingRule(props?.pairSelect.symbol));
  }, [props.pairSelect]);

  return (
    <div className={cx('order-content')}>
      <div className={cx('option-order')}>
        <div className={cx('tooltip-position')}>
          <TooltipText text={t('tradingRule.min_price_tooltip')}>
            <div className={cx('option-text')}>{t('tradingRule.minimum_price')}</div>
          </TooltipText>
        </div>
        <div className={cx('option-value')}>
          {formatIntBalance(tradingRuleValue?.minPriceMovement)} {props?.pairSelect.quoteCurrency}
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('tooltip-position')}>
          <TooltipText text={t('tradingRule.limit_buy_tooltip')}>
            <div className={cx('option-text')}>{t('tradingRule.limit_buy_ratio')}</div>
          </TooltipText>
        </div>
        <div className={cx('option-value')}>{formatIntBalance(Number(tradingRuleValue?.limitOrderPrice))}%</div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('tooltip-position')}>
          <TooltipText
            // eslint-disable-next-line max-len
            text={t('tradingRule.limit_sell_tooltip')}
          >
            <div className={cx('option-text')}>{t('tradingRule.limit_sell_ratio')}</div>
          </TooltipText>
        </div>
        <div className={cx('option-value')}>{formatIntBalance(Number(tradingRuleValue?.floorRatio))}%</div>
      </div>
      {isCoinM && (
        <div className={cx('option-order')}>
          <div className={cx('tooltip-position')}>
            <TooltipText text={t('tradingRule.tooltip_contract_multiplier')}>
              <div className={cx('option-text')}>{t('tradingRule.contract_multiplier')}</div>
            </TooltipText>
          </div>
          <div className={cx('option-value')}>
            {formatIntBalance(tradingRuleValue?.multiplier)} {props?.pairSelect.quoteCurrency}
          </div>
        </div>
      )}
      <div className={cx('option-order')}>
        <div className={cx('tooltip-position')}>
          <TooltipText text={t('tradingRule.max_order_tooltip')}>
            <div className={cx('option-text')}>{t('tradingRule.max_order_amount')}</div>
          </TooltipText>
        </div>
        <div className={cx('option-value')}>
          {formatIntBalance(tradingRuleValue?.maxOrderAmount)} {isCoinM ? 'Cont' : props?.pairSelect.rootSymbol}
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('tooltip-position')}>
          <TooltipText text={t('tradingRule.min_order_tooltip')}>
            <div className={cx('option-text')}>{t('tradingRule.mini_order_amount')}</div>
          </TooltipText>
        </div>
        <div className={cx('option-value')}>
          {formatIntBalance(tradingRuleValue?.minOrderAmount)} {isCoinM ? 'Cont' : props?.pairSelect.rootSymbol}
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('tooltip-position')}>
          <TooltipText text={t('tradingRule.max_noti_tooltip')}>
            <div className={cx('option-text')}>{t('tradingRule.max_noti_value')}</div>
          </TooltipText>
        </div>
        <div className={cx('option-value')}>
          {formatIntBalance(tradingRuleValue?.maxNotinal)} {props?.pairSelect.quoteCurrency}
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('tooltip-position')}>
          <TooltipText text={t('tradingRule.min_noti_tooltip')}>
            <div className={cx('option-text')}>{t('tradingRule.min_noti_value')}</div>
          </TooltipText>
        </div>
        <div className={cx('option-value')}>
          {formatIntBalance(tradingRuleValue?.minNotional)} {props?.pairSelect.quoteCurrency}
        </div>
      </div>
      <div className={cx('option-order')}>
        <div className={cx('option-text')}>{t('tradingRule.max_leverage')}</div>
        <div className={cx('option-value')}>{formatIntBalance(tradingRuleValue?.maxLeverage)}</div>
      </div>

      <div className={cx('empty-trading')}></div>
    </div>
  );
};
