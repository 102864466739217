import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { OrderType } from "src/features/PositionAndHistory/constant";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { IOrder } from "src/interfaces/order";
import { ITradingRuleCache } from "src/services/masterdataFuture";

export default function TPSLPrice({
  position,
  type,
  tradingRulesCache,
}: {
  position: IPositionItem;
  type: string;
  tradingRulesCache: ITradingRuleCache[];
}) {
  const findTakeProfitOrder = position?.orders?.find(
    (order: IOrder) => order.tpSLType === OrderType.takeProfitMarket
  );
  const findStopLossOrder = position?.orders?.find(
    (order: IOrder) => order.tpSLType === OrderType.stopMarket
  );

  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === position?.symbol
  );
  const maxFiguresForPrice =
    Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

  if (type === "take-profit") {
    return findTakeProfitOrder ? (
      <TooltipNumber
        characters={7}
        decimal={maxFiguresForPrice}
        defaultValue={findTakeProfitOrder?.tpSLPrice}
      />
    ) : (
      <span>--</span>
    );
  } else if (type === "stop-loss") {
    return findStopLossOrder ? (
      <TooltipNumber
        characters={7}
        decimal={maxFiguresForPrice}
        defaultValue={findStopLossOrder?.tpSLPrice}
      />
    ) : (
      <span>--</span>
    );
  } else {
    return <span>--</span>;
  }
}
