import { Box } from "@material-ui/core";
import classnames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
import DropdownMenu from "src/components/cores/Dropdown/Dropdown";
import Loading from "src/components/Loading";
import styles from "src/features/PositionAndHistory/styles/Order.module.scss";
import {
  formatOrderEnum,
  formatPriceRoundDown,
} from "src/helpers/numberFormatter";
import { formatTime } from "src/helpers/stringFormatter";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import DatePicker from "src/components/cores/DatePicker/DatePicker";
import { DateRange } from "@material-ui/pickers";
import {
  listTimeSelect,
  OrderSide,
  OrderStatusText,
  OrderType,
  SelectTimeValue,
} from "./constant";
import moment from "moment";
import { Pagination } from "@mui/material";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";
import { IOrder, OrderStatus } from "src/interfaces/order";
import BigNumber from "bignumber.js";
import { getOrderHistory } from "src/services/order-history";
import { getCookieStorage } from "src/helpers/storage";
// import { arrowDown, reduceIconDark, iconInfoLight, iconInfoDark, noData, noDataDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import { THEME_MODE } from "src/interfaces/theme";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import TooltipText from "src/components/cores/TooltipText";
import { formatOrderText, getContractType, getSymbolName } from "./helper";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "src/hooks/useScreen";
import TextNumber from "src/components/cores/TextNumber";
import {
  BsInboxesFill,
  BsInfoCircleFill,
  BsArrowDownCircle,
  BsArrowUpCircle,
} from "react-icons/bs";
import { handleDirect } from "./HistoryDashboard";

export enum PriceTypeValue {
  last = "LAST",
  mark = "ORACLE",
}

export enum StatusType {
  all = "",
  filled = "FILLED",
  partiallyFilled = "PARTIALLY_FILLED",
  cancelled = "CANCELED",
}

enum StatusLabel {
  all = "order.all",
  filled = "order.filled",
  partiallyFilled = "order.partially_filled",
  cancelled = "order.cancelled",
}

const STATUS_ACTION = [
  { value: StatusType.all, label: StatusLabel.all },
  { value: StatusType.filled, label: StatusLabel.filled },
  { value: StatusType.partiallyFilled, label: StatusLabel.partiallyFilled },
  { value: StatusType.cancelled, label: StatusLabel.cancelled },
];

const cx = classnames.bind(styles);

const OrderHistory = (props: any) => {
  const { hideOtherPairs } = props;
  const dispatch = useAppDispatch();

  const [tab, setTab] = useState<number | null>(0);
  const [tabSelectValue, setTabSelectValue] = useState<string | null>(
    SelectTimeValue.ONE_DAY
  );

  const { ordersHistory, loading } = useAppSelector(
    (state) => state.orderHistory
  );
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const account = useAppSelector((state) => state.account.account);
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const changeCheckboxRef = useRef(hideOtherPairs);
  const changeInstrumentRef = useRef(currentInstrument.symbol);

  const [rows, setRows] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPage = useAppSelector((state) => state.orderHistory.totalPage);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );

  const selectedDatePickerRef = useRef<DateRange<Date>>([null, null]);
  const [refElm, setRefEml] = useState<HTMLImageElement | null>(null);
  const [selectedDatePicker, setSelectedDatePicker] = useState<DateRange<Date>>(
    [null, null]
  );
  const [isChangeSelectDatePicker, setIsChangeSelectDatePicker] =
    useState<boolean>(false);
  const [statusType, setStatusType] = useState<string>(StatusType.all);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const token = getCookieStorage("access_token");
  const isExpired = getCookieStorage("isExpired");
  const isAuthenticated =
    isExpired === "false" && token && token !== "undefined";
  // && account && account[0]?.id;
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;
  const { t } = useTranslation("common");
  const size = useWindowSize();

  const handleFormatTime = () => {
    let formatStartTime;
    let formatEndTime;
    if (tabSelectValue === SelectTimeValue.ONE_DAY) {
      formatStartTime = moment().startOf("day").utc().toDate().getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_WEEK) {
      formatStartTime = moment()
        .subtract(1, "weeks")
        .startOf("day")
        .utc()
        .toDate()
        .getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_MONTH) {
      formatStartTime = moment()
        .subtract(1, "months")
        .startOf("day")
        .utc()
        .toDate()
        .getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.THREE_MONTHS) {
      formatStartTime = moment()
        .subtract(3, "months")
        .startOf("day")
        .utc()
        .toDate()
        .getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    }
    return {
      formatStartTime,
      formatEndTime,
    };
  };

  const fetchData = async (page: number) => {
    let isChooseDatePicker = isChangeSelectDatePicker;
    if (!selectedDatePicker[0] || !selectedDatePicker[1]) {
      isChooseDatePicker = false;
    }
    const { formatStartTime, formatEndTime } = handleFormatTime();
    const formatStartDatePicker = moment(selectedDatePicker[0])
      .startOf("day")
      .utc()
      .toDate()
      .getTime();
    const formatEndDatePicker = moment(selectedDatePicker[1])
      .endOf("day")
      .utc()
      .toDate()
      .getTime();

    const params = { page: page, size: RECORDS_PER_PAGE };

    const payload: any = {
      startTime: "0",
      endTime: "0",
      status: "",
      contractType,
    };

    if (hideOtherPairs) {
      payload.symbol = currentInstrument.symbol;
    }

    if (formatStartTime && !isChooseDatePicker) {
      payload.startTime = formatStartTime;
    }
    if (formatEndTime && !isChooseDatePicker) {
      payload.endTime = formatEndTime;
    }
    if (formatStartDatePicker && isChooseDatePicker) {
      payload.startTime = formatStartDatePicker;
    }
    if (formatEndDatePicker && isChooseDatePicker) {
      payload.endTime = formatEndDatePicker;
    }
    if (statusType) {
      payload.status = statusType;
    }
    await dispatch(getOrderHistory({ params, payload }));
  };

  useEffect(() => {
    if (ordersHistory) {
      setRows(ordersHistory);
    }
  }, [ordersHistory, account[0]?.id]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        changeCheckboxRef.current === hideOtherPairs &&
        changeInstrumentRef.current === currentInstrument.symbol
      ) {
        fetchData(currentPage);
      } else {
        setCurrentPage(1);
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairs;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [tabSelectValue, hideOtherPairs, currentInstrument]);

  useEffect(() => {
    setCurrentPage(1);
    if (isAuthenticated) {
      fetchData(1);
    }
  }, [statusType]);

  useEffect(() => {
    if (
      !isOpenDatePicker &&
      isChangeSelectDatePicker &&
      selectedDatePicker[0] &&
      selectedDatePicker[1]
    ) {
      setTab(null);
      setTabSelectValue(null);
      setCurrentPage(1);
      if (isAuthenticated) {
        fetchData(1);
      }
    }
  }, [isOpenDatePicker, isChangeSelectDatePicker, selectedDatePicker]);

  const renderPrice = (order: IOrder, currentPrice: string) => {
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === order?.symbol
    );
    const maxFiguresForPrice =
      Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={9}
        decimal={maxFiguresForPrice}
        defaultValue={currentPrice}
      />
    );
  };

  const renderQuantity = (order: IOrder) => {
    const currentInstrument = instruments?.find(
      (instrument) => instrument.symbol === order.symbol
    );
    // return (
    //   <TooltipNumber
    //     defaultValue={order?.quantity}
    //     characters={9}
    //     decimal={isCoinM ? 0 : 4}
    //     symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
    //   />
    // );

    return (
      <TextNumber
        defaultValue={order?.quantity ?? "0"}
        symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
        isCoinM={isCoinM}
      />
    );
  };

  const renderFilledQuantity = (order: IOrder) => {
    const fillQuantity = new BigNumber(order.quantity ?? "0").minus(
      order.remaining ?? "0"
    );
    const currentInstrument = instruments?.find(
      (instrument) => instrument.symbol === order.symbol
    );

    // return (
    //   <TooltipNumber
    //     defaultValue={fillQuantity}
    //     characters={9}
    //     decimal={isCoinM ? 0 : 4}
    //     symbol={isCoinM ? 'Cont' : currentInstrument?.rootSymbol}
    //   />
    // );

    return (
      <TextNumber
        defaultValue={fillQuantity.toString()}
        symbol={isCoinM ? "Cont" : currentInstrument?.rootSymbol}
        isCoinM={isCoinM}
      />
    );
  };

  const renderTriggerConditions = (order: IOrder) => {
    if (!order.stopCondition) return "--";
    const currentInstrument = instruments?.find(
      (instrument) => instrument.symbol === order.symbol
    );
    const tickSize = Number(currentInstrument?.tickSize) || "0.01";
    const precision = -Math.ceil(Math.log10(Number(tickSize)));

    const isTrailingStop = order.tpSLType === OrderType.trailingStop;
    const text = `${t("order.activation_price")}: ${formatPriceRoundDown(
      order?.activationPrice,
      precision
    )}. ${t("order.trigger_type")}: ${
      order?.trigger === PriceTypeValue.last
        ? t("order.last_price")
        : t("order.mark_price")
    }, ${t("order.callback_rate")}: ${order?.callbackRate ?? "0"}%.`;

    if (order.trigger === PriceTypeValue.last) {
      if (isTrailingStop) {
        return (
          <Box display="flex" alignItems="center">
            {t("order.activation_price")}{" "}
            {order.stopCondition === "GT" ? ">=" : "<="}&nbsp;
            <TooltipNumber
              defaultValue={order?.activationPrice}
              characters={7}
              decimal={precision}
            />
            &nbsp;
            <TooltipText text={text}>
              <BsInfoCircleFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              />
            </TooltipText>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignItems="center">
            {t("order.last_price")} {order.stopCondition === "GT" ? ">=" : "<="}
            &nbsp;
            <TooltipNumber
              defaultValue={order?.tpSLPrice}
              characters={7}
              decimal={precision}
            />
          </Box>
        );
      }
    } else if (order.trigger === PriceTypeValue.mark) {
      if (isTrailingStop) {
        return (
          <Box display="flex" alignItems="center">
            {t("order.activation_price")}{" "}
            {order.stopCondition === "GT" ? ">=" : "<="}&nbsp;
            <TooltipNumber
              defaultValue={order?.activationPrice}
              characters={7}
              decimal={precision}
            />
            &nbsp;
            <TooltipText text={text}>
              <BsInfoCircleFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
              />
            </TooltipText>
          </Box>
        );
      } else {
        return (
          <Box display="flex" alignItems="center">
            {t("order.mark_price")} {order.stopCondition === "GT" ? ">=" : "<="}
            &nbsp;
            <TooltipNumber
              defaultValue={order?.tpSLPrice}
              characters={7}
              decimal={precision}
            />
          </Box>
        );
      }
    }
  };

  const renderTime = (order: IOrder) => {
    return formatTime(order.updatedAt);
  };

  const renderTypeOrder = (order: IOrder) => {
    if (order.tpSLType === OrderType.takeProfitMarket) {
      return t("order.take_profit");
    }
    if (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder) {
      return t("order.stop_loss");
    }

    if (order.note === "LIQUIDATION") {
      return t(formatOrderText(order.note));
    }

    return t(formatOrderText(order.tpSLType || order.type));
  };

  const renderAmount = (order: IOrder) => {
    if (!order.parentOrderId) {
      if (
        order.tpSLType === OrderType.takeProfitMarket ||
        (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder)
      ) {
        return t("order.close_position");
      }
    }

    return renderQuantity(order);
  };

  const renderReduceOnly = (order: IOrder) => {
    return order.isReduceOnly ? t("order.yes") : t("order.no");
  };

  const handleChangeDatePicker = (value: DateRange<Date>) => {
    setSelectedDatePicker(value);
  };

  const checkUserSelectDatePicker = () => {
    if (
      moment(selectedDatePickerRef.current[0]).format("YYYY-MM-DD") !==
        moment(selectedDatePicker[0]).format("YYYY-MM-DD") ||
      moment(selectedDatePickerRef.current[1]).format("YYYY-MM-DD") !==
        moment(selectedDatePicker[1]).format("YYYY-MM-DD")
    ) {
      setIsChangeSelectDatePicker(true);
    } else {
      setIsChangeSelectDatePicker(false);
    }
  };

  const handleOpenDatePicker = () => {
    selectedDatePickerRef.current = selectedDatePicker;
    setIsOpenDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setIsOpenDatePicker(false);
    checkUserSelectDatePicker();
  };

  useEffect(() => {
    checkUserSelectDatePicker();
  }, [selectedDatePicker]);

  const handleChangeStatusType = (value: string) => {
    setStatusType(value);
  };

  // const handleSearchHistory = () => {
  //   if (isChangeSelectDatePicker) {
  //     setTab(null);
  //     setTabSelectValue(null);
  //   }
  //   setCurrentPage(1);
  //   fetchData(1);
  // };

  const handleReset = () => {
    setTab(0);
    setTabSelectValue(SelectTimeValue.ONE_DAY);
    setIsChangeSelectDatePicker(false);
    setCurrentPage(1);
    fetchData(1);
  };

  const handleAverage = (data: IOrder) => {
    if (
      data?.status === OrderStatus.CANCELED &&
      Number(data?.quantity) === Number(data?.remaining)
    ) {
      return "-";
    }

    return renderPrice(data, data?.average ?? "0");
  };

  return (
    <div className={"height-100"}>
      {!isAuthenticated && (
        <Box>
          <div className={cx("container")}>
            <div className={cx("tab-wrapper")}>
              {listTimeSelect.map(
                (item: { label: string; value: string }, index: number) => {
                  return (
                    <div
                      key={item?.label}
                      onClick={() => {
                        setTab(index);
                        setTabSelectValue(item.value);
                        setIsChangeSelectDatePicker(false);
                        setCurrentPage(1);
                      }}
                      className={
                        tab === index ? cx("tab", "active") : cx("tab")
                      }>
                      {t(item?.label)}
                    </div>
                  );
                }
              )}
              <div className={cx("text-time")}>{t("order.time")}</div>
              <DatePicker
                handleChangeDate={handleChangeDatePicker}
                handleOpenDate={handleOpenDatePicker}
                handleCloseDate={handleCloseDatePicker}
                customInput={cx("input-time")}
                isChangeSelectDatePicker={isChangeSelectDatePicker}
              />
              <div>
                {/* <button className={cx('search-btn')} onClick={handleSearchHistory}>
                  Search
                </button> */}
                <button className={cx("reset-btn")} onClick={handleReset}>
                  {t("order.reset")}
                </button>
              </div>
            </div>
          </div>
        </Box>
      )}

      <div className={cx("tbl_data")}>
        {!isAuthenticated && (
          <div className={cx("no-orders")}>
            <div className={cx("warning-table")}>
              <strong onClick={() => handleDirect("login")}>
                {t("order.login")}
              </strong>{" "}
              {t("order.or")}{" "}
              <strong onClick={() => handleDirect("signup")}>
                {t("order.signup")}
              </strong>{" "}
              {t("order.to_start_trading")}
            </div>
          </div>
        )}

        {isAuthenticated && (
          <div className={"height-100"} style={{ position: "relative" }}>
            <div className={cx("table-content")} style={{ minHeight: "395px" }}>
              <div
                className={cx("no-data")}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "25%",
                  transform: "translate(-50%, -50%)",
                }}>
                {!loading && !rows.length && (
                  <>
                    <BsInboxesFill
                      fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                      style={{ zoom: 5 }}
                    />
                    <div className={cx("no-data-desc")}>
                      {t("order.no_order_history")}
                    </div>
                  </>
                )}

                {loading && <Loading />}
              </div>

              <table className={cx("dex_table", "text-table-xs")}>
                <thead>
                  <tr>
                    <th style={{ minWidth: "160px", width: "11%" }}>
                      {t("order.time")}
                    </th>
                    <th style={{ minWidth: "95px", width: "7%" }}>
                      {t("order.symbol")}
                    </th>
                    <th style={{ minWidth: "95px", width: "7%" }}>
                      {t("order.type")}
                    </th>
                    <th style={{ minWidth: "65px", width: "4%" }}>
                      {t("order.side")}
                    </th>
                    <th style={{ minWidth: "90px", width: "7%" }}>
                      {t("order.average")}
                    </th>
                    <th style={{ minWidth: "90px", width: "7%" }}>
                      {t("order.price")}
                    </th>
                    <th style={{ minWidth: "90px", width: "7%" }}>
                      {t("order.executed")}
                    </th>
                    <th style={{ minWidth: "95px", width: "7%" }}>
                      {t("order.amount")}
                    </th>
                    <th style={{ minWidth: "220px", width: "16%" }}>
                      {t("order.trigger_condition")}
                    </th>
                    <th style={{ minWidth: "120px", width: "9%" }}>
                      {t("order.reduceOnly")}
                    </th>
                    <th style={{ minWidth: "120px", width: "9%" }}>
                      {t("order.postOnly")}
                    </th>
                    <th style={{ minWidth: "110px", width: "8%" }}>
                      <div
                        className={cx("type-cancel-order")}
                        onClick={(
                          event: React.MouseEvent<HTMLImageElement>
                        ) => {
                          setRefEml(event.currentTarget);
                        }}>
                        <span>{t("order.status")}</span>
                        {Boolean(refElm) ? (
                          <BsArrowDownCircle
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                          />
                        ) : (
                          <BsArrowUpCircle
                            fill={
                              theme === THEME_MODE.LIGHT ? "black" : "white"
                            }
                          />
                        )}
                      </div>
                      <DropdownMenu
                        open={Boolean(refElm)}
                        options={STATUS_ACTION}
                        active={statusType}
                        handleClose={() => setRefEml(null)}
                        refElm={refElm}
                        handleChooseOption={handleChangeStatusType}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody
                  className={cx("dex_table__scrollbar", "overflow-tbody")}
                  style={{ maxHeight: 210, verticalAlign: "top" }}>
                  {loading && <div style={{ height: 230 }}></div>}
                  {!loading && !rows.length && (
                    <div style={{ height: 230 }}></div>
                  )}

                  {!loading && !!rows.length && (
                    <>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className={cx("text-value")}>
                            {renderTime(row)}
                          </td>
                          <td>
                            <div className={cx("text-value")}>
                              {getSymbolName(row.symbol)}
                            </div>
                            <div className={cx("text-perpetual")}>
                              {t("order.perpetual")}
                            </div>
                          </td>
                          <td className={cx("text-value")}>
                            {renderTypeOrder(row)}
                          </td>
                          <td
                            className={cx(
                              row.side === OrderSide.buy
                                ? "color-buy"
                                : "color-sell"
                            )}>
                            {t(formatOrderText(row.side))}
                          </td>
                          <td className={cx("text-value")}>
                            {handleAverage(row)}
                          </td>
                          <td className={cx("text-value")}>
                            {row.type === OrderType.limit
                              ? renderPrice(row, row?.price ?? "0")
                              : "--"}
                          </td>
                          <td className={cx("text-value")}>
                            {renderFilledQuantity(row)}
                          </td>
                          <td className={cx("text-value")}>
                            {renderAmount(row)}
                          </td>
                          <td className={cx("text-value")}>
                            {Number(row.tpSLPrice) ||
                            Number(row.activationPrice)
                              ? renderTriggerConditions(row)
                              : "--"}
                          </td>
                          <td className={cx("text-value")}>
                            {renderReduceOnly(row)}
                          </td>
                          <td className={cx("text-value")}>
                            {row?.isPostOnly ? t("order.yes") : t("order.no")}
                          </td>
                          <td className={cx("text-value")}>
                            {t(
                              formatOrderText(
                                Object(OrderStatusText)[row?.status]
                              )
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>

              {!loading && totalPage > 1 && (
                <div className={cx("pagination")}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, page) => {
                      setCurrentPage(page);
                      fetchData(page);
                    }}
                    count={totalPage}
                    variant="outlined"
                    shape="rounded"
                    className={cx("pagination-custom")}
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "var(--color-value-1) !important",
                      },
                      "& .Mui-selected": {
                        backgroundColor:
                          "var(--color-background-pagination) !important",
                        border:
                          "1px solid var(--color-outline-pagination) !important",
                        color: "var(--color-text-pagination) !important",
                        borderRadius: "5px !important",
                      },
                      "& .MuiPagination-ul": {
                        flexWrap: "nowrap",
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderHistory;
