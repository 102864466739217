import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { Instrument } from "src/services/instrument";
import ItemInfo from "./ItemInfo";
import ListBadge from "./ListBadge";
import {
  formatPercent,
  formatPriceRoundDown,
  getValueClassName,
} from "src/helpers/numberFormatter";
import { useTranslation } from "react-i18next";
import { getSymbolName } from "src/features/PositionAndHistory/helper";
import { renderSide } from "./TablePosition";
import PositionCalculator from "src/helpers/PositionCalculator";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import TextNumber from "src/components/cores/TextNumber";
import { ITicker } from "src/services/ticker";
import { IAccount } from "src/services/account";
import { getPosition, IPosition } from "src/services/position";
import TPSLPrice from "./TPSLPrice";
import { BsPencilSquare } from "react-icons/bs";
import { THEME_MODE } from "src/interfaces/theme";
import { ClosePositionDialog } from "src/features/PositionAndHistory/dialog/ClosePositionDialog";
import { useState } from "react";
import { OrderType } from "src/features/PositionAndHistory/constant";
import ClosePositionDialogV2 from "src/features/PositionAndHistory/dialog/ClosePositionDialogV2";
import axiosInstance from "src/helpers/config";
import { noti } from "src/hooks/useNoti";
import { RECORDS_PER_PAGE, TypeTrade } from "src/constants/common.constants";

const handleCharacter = (characters: number, defaultValue: number) => {
  const tickSize = Number("0.01");
  const precision = Math.abs(Math.ceil(Math.log10(Number(tickSize))));
  const numberFinal = formatPriceRoundDown(defaultValue, precision);
  const totalCommas = (numberFinal?.match(/,/g) || []).length;
  const totalDot = (numberFinal?.match(/./g) || []).length;

  const numberSpace = Math.ceil(characters / 3) - 1;
  if (totalCommas >= numberSpace) {
    return numberSpace;
  } else {
    if (totalCommas + totalDot >= numberSpace) return numberSpace;
    return totalCommas + totalDot;
  }
};

const formatNumberDot = (characters: number, defaultValue: number) => {
  const tickSize = Number("0.01");
  const precision = Math.abs(Math.ceil(Math.log10(Number(tickSize))));
  const numberFinal = formatPriceRoundDown(defaultValue, precision);
  const newStr = numberFinal.slice(
    0,
    characters + handleCharacter(characters, defaultValue)
  );
  return newStr.concat("...");
};

export default function ItemMobile({
  row,
  tradingRulesCache,
  isCoinM,
  tickers,
  account,
  allPosition,
  handleClose,
  handleOpenTPSL,
}: {
  row: IPositionItem;
  tradingRulesCache: ITradingRuleCache[];
  isCoinM: boolean;
  tickers: ITicker[];
  account: IAccount[];
  allPosition: IPosition[];
  handleClose: (id: number | string) => void;
  handleOpenTPSL: (pos: IPositionItem) => void;
}) {
  const dispatch = useAppDispatch();
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const { t } = useTranslation("common");
  const instruments: Instrument[] = useAppSelector(
    (state) => state.instrument.instrument
  );
  const textPosition =
    getValueClassName(row.currentQty) === "App-positive-value"
      ? "Long"
      : "Short";
  const curTradingRule = tradingRulesCache?.filter(
    (item) => item.symbol === row?.symbol
  );
  const [loading, setLoading] = useState(false);

  const maxFiguresForPrice =
    Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === row.symbol
  );
  const decimal = -Math.ceil(
    Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
  );
  const currentTicker = tickers?.find((ticker) => ticker.symbol === row.symbol);

  const liquidationPrice = PositionCalculator.getLiquidationPrice(
    row,
    account,
    allPosition,
    row.adjustMargin
  );
  const handleReversePrice = async (row: IPositionItem) => {
    setLoading(true);
    console.log("🚀 ~ handleReversePrice ~ row:", row);
    let params = {
      side: Number(row.currentQty) > 0 ? "SELL" : "BUY",
      symbol: row.symbol,
      type: "MARKET",
      quantity: Math.abs(Number(row.currentQty)) * 2,
      price: "",
      remaining: Math.abs(Number(row.currentQty)) * 2,
      stopPrice: "",
      stopCondition: "",
      takeProfitCondition: "",
      stopLossCondition: "",
      takeProfit: "",
      stopLoss: "",
      trigger: "ORACLE",
      takeProfitTrigger: "",
      stopLossTrigger: "",
      timeInForce: "GTC",
      isReduceOnly: false,
      callbackRate: "",
      activationPrice: "",
      tpSLType: "",
      tpSLPrice: "",
      isPostOnly: false,
      contractType: row.contractType,
      asset: row.asset,
    };
    const res: any = await axiosInstance.post("/order", params);
    if (res?.code === 200 || res?.code === 201) {
      noti.success({ title: `${t("order.noti.order_submitted")}` });
      // setOpenOrderConfirmModal(false);
      const params = { page: 1, size: RECORDS_PER_PAGE };
      const contractType = location.pathname.includes(TypeTrade.COIN_M)
        ? TypeTrade.COIN_M
        : TypeTrade.USD_M;
      const payload = {
        contractType: contractType,
      };
      dispatch(getPosition({ ...params, symbol: undefined, contractType }));
      setLoading(false);
    }
    if (res.status === 400 || res.status === 404) {
      noti.error({
        title: res.data?.info?.message,
      });
      setLoading(false);
    }
  };
  return (
    <div
      className="container-item"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
      }}>
      <div className="section1">
        <span className="title">{getSymbolName(row.symbol)}</span>
        <ListBadge
          items={[
            {
              value: textPosition,
              classCustom: getValueClassName(row.currentQty),
            },
            {
              value: "100x",
              classCustom: getValueClassName(row.currentQty),
            },
            {
              value: row.isCross ? t("order.cross") : t("order.isolated"),
              classCustom: "normal",
            },
            {
              value: getSymbolName(row.symbol),
              classCustom: "normal",
            },
          ]}
        />
      </div>
      <div className="section2">
        <ItemInfo
          title={`Unrealized PnL(${row.asset})`}
          description={`${renderSide(
            PositionCalculator.getUnrealizedPNL(row)
          )} ${Number(PositionCalculator.getUnrealizedPNL(row)).toFixed(2)}`}
          classNameCustom={`${getValueClassName(
            PositionCalculator.getUnrealizedPNL(row)
          )} font-big`}
          textStyle={{ textAlign: "left" }}
        />
        <ItemInfo
          title="ROE"
          description={formatPercent(
            PositionCalculator.getUnrealizedROE(row),
            DECIMAL_TWO
          )}
          textStyle={{ textAlign: "right" }}
          classNameCustom={`${getValueClassName(
            PositionCalculator.getUnrealizedPNL(row)
          )} font-big`}
        />
      </div>
      <div className="section3">
        <ItemInfo
          title={`Position(${
            isCoinM ? "Cont" : currentInstrument?.rootSymbol
          })`}
          description={formatNumberDot(
            7,
            Math.abs(Number(row?.currentQty))
          ).toString()}
          style={{ flex: 1 }}
          textStyle={{ textAlign: "left" }}
        />
        <ItemInfo
          title={`Margin(${row.asset})`}
          style={{ flex: 1 }}
          textStyle={{ textAlign: "left" }}
          node={
            <TextNumber
              defaultValue={Number(
                PositionCalculator.getAllocatedMargin(row)
              ).toFixed(2)}
              symbol={row?.asset}
              isCoinM={false}
              customText={"content"}
            />
          }
        />
        <ItemInfo
          title="Maintenance margin rate (MMR)"
          description={formatPercent(
            PositionCalculator.getMarginRatio(row),
            DECIMAL_TWO
          )}
          style={{ flex: 1 }}
          textStyle={{ textAlign: "right" }}
        />
      </div>
      <div className="section4">
        <ItemInfo
          title="Average price"
          style={{ flex: 1 }}
          textStyle={{ textAlign: "left" }}
          node={
            <TooltipNumber
              characters={11}
              decimal={maxFiguresForPrice}
              defaultValue={row.entryPrice}
              customText={"content"}
            />
          }
        />
        <ItemInfo
          title="Mark price"
          description="63.000"
          style={{ flex: 1 }}
          textStyle={{ textAlign: "left" }}
          node={
            <TooltipNumber
              characters={11}
              decimal={decimal}
              defaultValue={currentTicker?.oraclePrice}
              customText={"content"}
            />
          }
        />
        <ItemInfo
          title="Est.liq.price"
          style={{ flex: 1 }}
          textStyle={{ textAlign: "right" }}
          node={
            <TooltipNumber
              characters={11}
              decimal={decimal}
              defaultValue={liquidationPrice}
              customText={"content text-right"}
            />
          }
        />
      </div>
      <div className="section5">
        {/* <div className="container-info">
          <span className="info-title">Unrealized PnL(USDT)</span>
          <span className="info-content" style={{ color: "#F1493F" }}>
            -8.5 USDT
          </span>
        </div> */}
        <div className="container-info">
          <span className="info-title">Position TP/SL</span>
          <span className="info-content-tpsl">
            <span className={"text-tp"}>
              <TPSLPrice
                position={row}
                type="take-profit"
                tradingRulesCache={tradingRulesCache}
              />
            </span>
            <div className="color-col" />
            <span className={"text-sl"}>
              <TPSLPrice
                position={row}
                type="stop-loss"
                tradingRulesCache={tradingRulesCache}
              />
            </span>
            <BsPencilSquare
              style={{ cursor: "pointer" }}
              fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
              onClick={() => {
                handleOpenTPSL(row);
              }}
            />
          </span>
        </div>
      </div>
      <div className="section6">
        <button
          className="button"
          onClick={() => {
            handleOpenTPSL(row);
          }}>
          TP/SL
        </button>
        <button className="button" onClick={() => handleReversePrice(row)}>
          {loading ? "...loading" : t("order.reverse")}
        </button>
        <button
          className="button"
          onClick={() => {
            handleClose(row.id);
          }}>
          Close
        </button>
      </div>
    </div>
  );
}
