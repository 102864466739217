import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DISPLAY_RECORD_NUMBER } from "src/constants/common.constants";
import axiosInstance from "src/helpers/config";
import { IFill } from "src/interfaces/fill";
import { CancelToken } from "./transaction-history";

const initialState = {
  error: "",
  loading: false,
  fills: [] as IFill[],
  firstLoad: true,
  totalPage: 1,
};

let cancelTradeHistory: any;

export const getFilledTradesBySymbol = createAsyncThunk(
  "get-fill",
  async ({
    params,
    payload,
  }: {
    params: { page: number; size: number };
    payload: {
      startTime: string | number;
      endTime: string | number;
      side?: string | undefined | null;
      symbol?: string | undefined | null;
      contractType: string;
    };
  }) => {
    console.log("fetching 4", cancelTradeHistory);
    if (cancelTradeHistory != undefined) {
      cancelTradeHistory();
    }
    console.log("fetching 5");
    return await axiosInstance.post(`/trade/fill`, payload, {
      params: params,
      timeout: 60000,
      cancelToken: new CancelToken(function executor(c) {
        cancelTradeHistory = c;
      }),
    });
  }
);

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    addTrades: (state, action) => {
      let newTrades = action.payload.concat(state.fills);
      newTrades = newTrades.slice(0, DISPLAY_RECORD_NUMBER);
      // state.fills = newTrades;
    },
    setFirstLoadFill: (state, action) => {
      state.firstLoad = action.payload;
    },
    resetInitStateFill: (state) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      state = initialState;
    },
  },
  extraReducers: {
    [`${getFilledTradesBySymbol.pending}`]: (state) => {
      state.loading = true;
    },
    [`${getFilledTradesBySymbol.rejected}`]: (state, action) => {
      state.loading = false;
      state.error = action.error;
      state.firstLoad = true;
    },
    [`${getFilledTradesBySymbol.fulfilled}`]: (state, action) => {
      state.loading = false;
      state.fills = action.payload.data;
      state.firstLoad = false;
      state.totalPage = action.payload?.metadata?.totalPage;
    },
  },
});

const { reducer: tradeReducer } = tradeSlice;

export default tradeReducer;

export const { addTrades, setFirstLoadFill, resetInitStateFill } =
  tradeSlice.actions;
