import styled from "@emotion/styled";

export const Container = styled.div`
  height: 100%;
  position: relative;
  width: 480px;
  .MuiTypography-root {
    display: flex;
    justify-content: space-between;
  }
  .MuiDialogTitle-root {
    padding-bottom: 0;
  }
  .MuiTabs-flexContainer {
    .MuiButtonBase-root {
      /* color: red; */
    }
    .Mui-selected {
      color: var(--text-tab-active);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .title-modal {
    font-size: 20px;
    font-weight: 600;
    color: var(--text-tab-active);
  }
  .containerGeneralInfo {
    .header {
      display: flex;
      align-items: flex-end;
    }
    .text-h-4 {
      color: var(--text-tab-active);
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }
    .rate {
      color: var(--text-tab-active);
      font-size: 14px;
    }
    .container-list-info {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-top: 12px;
      .container-info-item {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: rgba(142, 142, 146, 1);
        }
        .value {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: var(--text-tab-active);
        }
        .symbol {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: var(--text-tab-active);
        }
      }
    }
  }
  .container-input {
    border: 1px solid rgba(142, 142, 146, 1);
    border-radius: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1px 12px;
    .container-title {
      font-size: 12px;
      font-weight: 400;
      color: rgba(142, 142, 146, 1);
    }
    input {
      border-radius: 50px;
      border: none;
      width: fit-content;
      text-align: right;
    }
  }
  .button {
    border-radius: 63px;
    border: 1px solid rgba(142, 142, 146, 1);
    text-align: center;
    padding: 10px 18px;
    width: 100%;
    max-width: 204px;
    cursor: pointer;
  }
  .confirm-action {
    background-color: rgba(23, 24, 26, 1);
    color: white;
  }
  .cancel-action {
    background-color: rgba(255, 255, 255, 1);
    color: black;
  }

  @media (max-width: 768px) {
    width: 300px;
    .header {
      justify-content: flex-end;
      flex-wrap: wrap;
      max-width: 150px;
    }
    .container-info-item {
      font-size: 12px;
    }
    .text-h-4 {
      font-size: 14px;
    }
  }
`;
