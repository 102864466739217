import React from 'react';
import styled from '@emotion/styled';

type Props = {
  percent: number | string;
};

const MarginItemRatioPercentWrapper = styled.div<Props>`
  color: ${({ percent }) =>
    Number(percent) <= 36 ? '#00b895' : Number(percent) >= 36 && Number(percent) <= 68 ? '#FEC300' : '#E02D3C'};
  flex-shrink: 0;
  display: flex;
  justify-content: right;
  align-items: center;
  font-size: 14px;
  @media only screen and (max-width: 700px) {
    font-size: 12px;
  }
`;

const MarginItemRatioPercent: React.FC<Props> = ({ percent }) => {
  return <MarginItemRatioPercentWrapper percent={percent}>{percent}%</MarginItemRatioPercentWrapper>;
};

export default MarginItemRatioPercent;
