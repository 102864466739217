import { handleDirect } from "src/features/PositionAndHistory/HistoryDashboard";
import * as S from "./style";
import { useTranslation } from "react-i18next";

export default function NotLogin() {
  const { t } = useTranslation("common");
  return (
    <S.Container>
      <div className={"warning-table"}>
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => handleDirect("login")}>
          {t("order.login")}
        </strong>{" "}
        {t("order.or")}{" "}
        <strong
          style={{ cursor: "pointer" }}
          onClick={() => handleDirect("signup")}>
          {t("order.signup")}
        </strong>{" "}
        {t("order.to_start_trading")}
        <br />
      </div>
    </S.Container>
  );
}
