import styled from "@emotion/styled";
import { Dialog, DialogContent } from "@material-ui/core";

export const Container = styled.div``;

export const ModalWrapper = styled(Dialog)`
  width: 100%;
`;

export const ContentModal = styled(DialogContent)`
      max-width: 480px;
      /* min-width: 320px; */
      height: auto;
      max-height: 80vh;
      padding: 0;
      background: var(--bg-order);
      border-radius: 16px;
      -webkit-border-radius: 16px;
      -moz-border-radius: 16px;
      -ms-border-radius: 16px;
      -o-border-radius: 16px;
      .search-title {
        margin: 0 -24px;
        padding: 6px 16px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--color-tab-border-right);
        .search-name {
          font-family: "NotoSan-semiBold";
          color: var(--color-text);
          font-size: 16px;
        }
      }
      .search-children {
        margin: 0 -8px;
      }
    }
    .search-action {
      margin: 0 -24px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 24px 16px 24px;
      font-size: 14px;
      .search-reset {
        width: 50%;
        height: 40px;
        border-radius: 20px;
        border: 1px solid var(--color-tab-border-right);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .search-search {
        width: 50%;
        height: 40px;
        border-radius: 20px;
        /* color: var(--color-background-pagination);
        background: var(--color-slider-ranger);
        display: flex;
        align-items: center;
        justify-content: center; */
        .search-text {
          width: 100%;
          height: 40px;
          border-radius: 20px;
          color: var(--color-background-pagination);
          background: var(--color-slider-ranger);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
`;
