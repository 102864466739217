import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TAB_CALCULATOR } from "./constants";
import styles from "src/features/Market/components/Calculator/Calculator.module.scss";
import classnames from "classnames/bind";
// import { IconNext } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.svg";

const cx = classnames.bind(styles);

type Props = {
  setTabActive: any;
  tabActive: number;
};

export const TabsMobile = ({ setTabActive, tabActive }: Props) => {
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const { t } = useTranslation("common");

  const handleScroll = () => {
    const container = document.getElementById("scrollableContainer");
    if (container) {
      setShowPrev(container.scrollLeft > 0);
      setShowNext(
        container.scrollLeft < container.scrollWidth - container.clientWidth
      );
    }
  };

  useEffect(() => {
    handleScroll();
    const container = document.getElementById("scrollableContainer");
    if (container) {
      container.addEventListener("scroll", handleScroll);

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handlePrevClick = () => {
    const container = document.getElementById("scrollableContainer");
    if (container) {
      container.scrollLeft -= container.clientWidth / 5;
    }
  };

  const handleNextClick = () => {
    const container = document.getElementById("scrollableContainer");
    if (tabActive === TAB_CALCULATOR.length - 1 && container) {
      container.scrollLeft = container.clientWidth;
    } else {
      if (container) {
        container.scrollLeft += container.clientWidth / 5;
      }
    }
  };

  const handleClickItem = (index: number) => {
    const container = document.getElementById("scrollableContainer");
    if (container) {
      if (index === TAB_CALCULATOR.length - 1 || index === 3) {
        container.scrollLeft = container.clientWidth;
        setShowNext(false);
      }
      if (index === 0 || (showPrev && index === 1)) {
        container.scrollLeft = 0;
      }

      if (index === 2) {
        container.scrollLeft = container.clientWidth / 3;
      }
      setTabActive(index);
    }
  };

  return (
    <>
      {showPrev && <span onClick={handlePrevClick}>{Icon}</span>}
      <div id="scrollableContainer" className={cx("tab-calculator-mb")}>
        {TAB_CALCULATOR.map((item, index) => (
          <div
            key={index}
            className={cx("tab-item", tabActive === index && "tab-item-active")}
            onClick={() => handleClickItem(index)}
          >
            {t(item)}
          </div>
        ))}
      </div>
      {showNext && <span onClick={handleNextClick}>{Icon}</span>}
    </>
  );
};
