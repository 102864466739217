export const dataMinutes = [
  { value: "1", label: "tradingView.minute_ops", time: "1" },
  { value: "3", label: "tradingView.minutes_ops", time: "3" },
  { value: "5", label: "tradingView.minutes_ops", time: "5" },
  { value: "15", label: "tradingView.minutes_ops", time: "15" },
  { value: "30", label: "tradingView.minutes_ops", time: "30" },
];

export const dataHours = [
  { value: "60", label: "tradingView.hour_ops", time: "1" },
  { value: "120", label: "tradingView.hours_ops", time: "2" },
  { value: "240", label: "tradingView.hours_ops", time: "4" },
  { value: "360", label: "tradingView.hours_ops", time: "6" },
  { value: "720", label: "tradingView.hours_ops", time: "12" },
];

export const TRADING_VIEW_CHART = "0";
export const DEPTH_CHART = "1";

export const TIME_WEBVIEW = [
  {
    label: "1m",
    value: "1",
  },
  {
    label: "5m",
    value: "5",
  },
  {
    label: "15m",
    value: "15",
  },
  {
    label: "1H",
    value: "60",
  },
  {
    label: "4H",
    value: "240",
  },
  {
    label: "1D",
    value: "1D",
  },
];

export const TIME_LIST = [
  {
    label: "1m",
    value: "1",
  },
  {
    label: "3m",
    value: "3",
  },
  {
    label: "5m",
    value: "5",
  },
  {
    label: "15m",
    value: "15",
  },
  {
    label: "30m",
    value: "30",
  },
  {
    label: "1H",
    value: "60",
  },
  {
    label: "2H",
    value: "120",
  },
  {
    label: "4H",
    value: "240",
  },
  {
    label: "6H",
    value: "360",
  },
  {
    label: "8H",
    value: "480",
  },
  {
    label: "12H",
    value: "720",
  },
  {
    label: "1D",
    value: "1D",
  },
  {
    label: "3D",
    value: "3D",
  },
  {
    label: "1W",
    value: "1W",
  },
  {
    label: "1M",
    value: "1M",
  },
];
