import { useTranslation } from "react-i18next";
import { OrderType } from "src/features/PositionAndHistory/constant";
import { formatOrderText } from "src/features/PositionAndHistory/helper";
import { IOrder, OrderStatus } from "src/interfaces/order";

export default function RenderTypeOrder({ order }: { order: IOrder }) {
  const { t } = useTranslation('common');
  if (
    order.tpSLType === OrderType.stopLimit &&
    order.status === OrderStatus.ACTIVE
  ) {
    return t(formatOrderText(order.type));
  }

  if (order.tpSLType === OrderType.takeProfitMarket) {
    return t("order.take_profit");
  }
  if (order.tpSLType === OrderType.stopMarket && order.isTpSlOrder) {
    return t("order.stop_loss");
  }

  return t(formatOrderText(order.tpSLType || order.type));
}
