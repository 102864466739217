import ItemBadge from "./ItemBadge";

export default function ListBadge({
  items,
}: {
  items: {
    value: string;
    classCustom: string;
  }[];
}) {
  return (
    <div className="list-badges">
      {items.map((item) => (
        <ItemBadge
          key={item.value}
          customClass={item.classCustom}
          value={item.value}
        />
      ))}
    </div>
  );
}
