import React from 'react';
import { Box, IconButton, Typography } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
// import { iconCloseCommon, iconCloseCommonDark } from 'src/assets/icon';
import Icon from "src/assets/icon/icon.png";
import classnames from "classnames/bind";
import styles from 'src/features/Market/components/Calculator/Calculator.module.scss';
import { CalculatorMain } from './CalculatorMain';
import { useAppSelector } from 'src/store/hooks';
import { THEME_MODE } from 'src/interfaces/theme';
import { BsXOctagonFill } from "react-icons/bs";

const cx = classnames.bind(styles);

type CalculatorProps = {
  handleClose: () => void;
};

export const CalculatorOrder = ({ handleClose }: CalculatorProps) => {
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  return (
    <div className={cx("calculator-wrapper")}>
      {/*title*/}
      <DialogTitle className={cx("wrapper-title")}>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography component={"div"}>
            <IconButton size={"small"} onClick={handleClose}>
              <BsXOctagonFill
                fill={theme === THEME_MODE.LIGHT ? "black" : "lightgray"}
                style={{ zoom: 1.2 }}
              />
            </IconButton>
          </Typography>
        </Box>
      </DialogTitle>

      <CalculatorMain />
    </div>
  );
};
