import { IOrder, OrderStatus } from "src/interfaces/order";
import Price from "../OpenOrder/Price";
import { ITradingRuleCache } from "src/services/masterdataFuture";

export default function RenderAverage({
  data,
  tradingRulesCache,
  customText,
}: {
  data: IOrder;
  tradingRulesCache: ITradingRuleCache[];
  customText?: string;
}) {
  if (
    data?.status === OrderStatus.CANCELED &&
    Number(data?.quantity) === Number(data?.remaining)
  ) {
    return <span>-</span>;
  }
  return (
    <Price
      position={data}
      tradingRulesCache={tradingRulesCache}
      currentPrice={data?.price ?? "0"}
      customText={customText}
    />
  );
}
