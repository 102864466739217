import {
  formatPercent,
  formatPrice,
  getValueClassName,
} from "src/helpers/numberFormatter";
import ThHead from "./thHead";
import { useTranslation } from "react-i18next";
import { ITicker } from "src/services/ticker";
import { Instrument } from "src/services/instrument";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import Quantity from "./Quantity";
import { ITradingRuleCache } from "src/services/masterdataFuture";
import Price from "./Price";
import MarketPrice from "./MarketPrice";
import PositionCalculator from "src/helpers/PositionCalculator";
import { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { Box } from "@material-ui/core";
import Margin from "./Margin";
import { THEME_MODE } from "src/interfaces/theme";
import Liq from "./Liq";
import PNL from "./PNL";
import TPSLPrice from "./TPSLPrice";
import { IPosition } from "src/services/position";
import { IAccount } from "src/services/account";
import * as S from "./style";
import SymBolCol from "./Symbol";
import ClosePositionDialogV2 from "src/features/PositionAndHistory/dialog/ClosePositionDialogV2";
import { useState } from "react";
import ReverseAction from "./ReverseAction";
import EntierPositionDialog from "src/features/PositionAndHistory/dialog/EntierPositionDialog";
import AdjustMarginDialog from "src/features/PositionAndHistory/dialog/AdjustMarginDialog";
import { BsPencilSquare } from "react-icons/bs";

export const renderSide = (value: string) => {
  const number = parseFloat(value);
  if (number > 0) {
    return "+";
  } else {
    return "";
  }
};

export default function TablePositionHistory({
  isCoinM,
  ticker,
  tickers,
  currentInstrument,
  loading,
  rows,
  tradingRulesCache,
  instruments,
  account,
  allPosition,
  theme,
  handleChangeLimitPrice,
  handleChangePositionSize,
  handleCloseWithMarketPrice,
  handleCloseWithLimitPrice,
}: {
  isCoinM: boolean;
  ticker?: ITicker;
  tickers: ITicker[];
  currentInstrument: Instrument;
  loading: boolean;
  rows: IPositionItem[];
  tradingRulesCache: ITradingRuleCache[];
  instruments: Instrument[];
  account: IAccount[];
  allPosition: IPosition[];
  theme: THEME_MODE;
  handleChangeLimitPrice: (value: string, position: IPositionItem) => void;
  handleChangePositionSize: (value: string, position: IPositionItem) => void;
  handleCloseWithMarketPrice: (position: IPositionItem) => void;
  handleCloseWithLimitPrice: (position: IPositionItem) => void;
}) {
  const { t } = useTranslation("common");
  const [openDialog, setOpenDialog] = useState(false);
  const [tabActive, setTabActive] = useState<number>(0);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [currentPosition, setCurrentPosition] =
    useState<IPositionItem | undefined>(undefined);
  const [positionSelected, setPositionSelected] =
    useState<number | string | null>(null);
  const [openEntierPositionDialog, setOpenEntierPositionDialog] =
    useState<boolean>(false);
  const [openAdjustMarginDialog, setOpenAdjustMarginDialog] =
    useState<boolean>(false);
  const handleOpenCloseMarket = (row: IPositionItem) => {
    setPositionSelected(row.id);
    setOpenDialog(true);
    setTabActive(1);
  };
  const handleOpenCloseLimit = (row: IPositionItem) => {
    setPositionSelected(row.id);
    setOpenDialog(true);
    setTabActive(0);
  };
  const handleOpenEntierDialog = (position: IPositionItem) => {
    setOpenEntierPositionDialog(true);
    setCurrentPosition(position);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTabActive(0);
  };
  const handleOpenAdjustMarginDialog = (position: IPositionItem) => {
    setOpenAdjustMarginDialog(true);
    setCurrentPosition(position);
  };
  const handleCloseEntierDialog = () => {
    setOpenEntierPositionDialog(false);
  };
  const handleCloseAdjustMarginDialog = () => {
    setOpenAdjustMarginDialog(false);
  };
  return (
    <S.TableStyle>
      <table className={"dex_table text-table-xs"}>
        <thead>
          <tr className={"table-head"}>
            <ThHead text={t("order.symbol")} />
            <ThHead
              isTooltip
              text={t("order.size")}
              textTooltip={t("order.tooltip.size", {
                name: isCoinM ? "COIN-M" : "USDⓈ-M",
                asset: isCoinM ? "BTC" : "USDT",
                symbol: isCoinM ? t("order.cont") : t("order.coin"),
              })}
            />
            <ThHead
              isTooltip
              text={t("order.entry_price")}
              textTooltip={t("order.tooltip.entry_price")}
            />
            <ThHead
              isTooltip
              text={t("order.mark_price")}
              textTooltip={t("order.tooltip_mark_price", {
                number: formatPrice(ticker?.indexPrice, currentInstrument),
              })}
            />
            <ThHead
              isTooltip
              text={t("order.liq_price")}
              textTooltip={t("order.tooltip.liq_price")}
            />
            <ThHead
              isTooltip
              text={t("order.margin_ratio")}
              textTooltip={t("order.tooltip_margin_ratio")}
            />
            <ThHead
              isTooltip
              text={t("order.margin")}
              textTooltip={t("order.tooltip.margin")}
            />
            <ThHead
              isTooltip
              text={t("order.pnl")}
              textTooltip={t("order.tooltip.pnl")}
            />
            <ThHead
              isTooltip
              text={t("order.tpls_for_position")}
              textTooltip={t("order.tooltip_tpls_for_position")}
            />
            <ThHead
              isTooltip
              text={t("order.reverse")}
              textTooltip={t("order.tooltip_reverse")}
            />
            <ThHead text={"Close"} />
          </tr>
        </thead>
        <tbody
          className={"dex_table__scrollbar overflow-tbody"}
          style={{ maxHeight: 210, verticalAlign: "top", marginTop: 16 }}>
          {loading && <div style={{ height: 230 }}></div>}
          {!loading && !rows.length && <div style={{ height: 230 }}></div>}

          {!loading && !!rows.length && (
            <>
              {rows.map((row, index) => (
                <tr key={index}>
                  <SymBolCol row={row} currentInstrument={currentInstrument} />
                  <td className={"text-normal"}>
                    <Quantity
                      position={row}
                      tradingRulesCache={tradingRulesCache}
                      instruments={instruments}
                      isCoinM={isCoinM}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <Price
                      position={row}
                      tradingRulesCache={tradingRulesCache}
                      currentPrice={row.entryPrice}
                    />
                  </td>
                  <td className={"text-normal"}>
                    <MarketPrice
                      position={row}
                      tickers={tickers}
                      instruments={instruments}
                    />
                  </td>
                  <td className={"text-liq"}>
                    {Number(
                      PositionCalculator.getLiquidationPrice(
                        row,
                        account,
                        allPosition,
                        row.adjustMargin
                      )
                    ) ? (
                      <Liq
                        instruments={instruments}
                        position={row}
                        liquidationPrice={PositionCalculator.getLiquidationPrice(
                          row,
                          account,
                          allPosition,
                          row.adjustMargin
                        )}
                      />
                    ) : (
                      "--"
                    )}
                  </td>
                  <td className={"text-normal"}>
                    {formatPercent(
                      PositionCalculator.getMarginRatio(row),
                      DECIMAL_TWO
                    )}
                  </td>
                  <td>
                    <Box display="flex" alignItems="center">
                      <span className={"text-normal"}>
                        <Margin
                          position={row}
                          currentMargin={PositionCalculator.getAllocatedMargin(
                            row
                          )}
                        />
                      </span>
                      {!row.isCross && (
                        <BsPencilSquare
                          style={{ cursor: "pointer", marginLeft: 10 }}
                          fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                          onClick={() => handleOpenAdjustMarginDialog(row)}
                        />
                      )}
                    </Box>
                    <div className={"text-normal"}>
                      {row.isCross
                        ? `(${t("order.cross")})`
                        : `(${t("order.isolated")})`}
                    </div>
                  </td>
                  <td>
                    <div
                      className={`${getValueClassName(
                        PositionCalculator.getUnrealizedPNL(row)
                      )} flex`}>
                      {renderSide(PositionCalculator.getUnrealizedPNL(row))}
                      <PNL
                        position={row}
                        currentPNL={Number(
                          PositionCalculator.getUnrealizedPNL(row)
                        ).toFixed(2)}
                      />
                    </div>
                    <div
                      className={
                        getValueClassName(
                          PositionCalculator.getUnrealizedROE(row)
                        ) || "text-value"
                      }>
                      {`(${renderSide(
                        PositionCalculator.getUnrealizedROE(row)
                      )}${formatPercent(
                        PositionCalculator.getUnrealizedROE(row),
                        DECIMAL_TWO
                      )})`}
                    </div>
                  </td>
                  <td>
                    <Box display="flex" alignItems="center" style={{ gap: 10 }}>
                      <span className={"text-tp"}>
                        <TPSLPrice
                          position={row}
                          type="take-profit"
                          tradingRulesCache={tradingRulesCache}
                        />
                      </span>
                      <div className="color-col" />
                      <span className={"text-sl"}>
                        <TPSLPrice
                          position={row}
                          type="stop-loss"
                          tradingRulesCache={tradingRulesCache}
                        />
                      </span>
                      <BsPencilSquare
                        style={{ cursor: "pointer" }}
                        fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                        onClick={() => {
                          handleOpenEntierDialog(row);
                        }}
                      />
                    </Box>
                  </td>
                  <td>
                    <div style={{ height: 24, display: "flex" }}>
                      <ReverseAction row={row} />
                    </div>
                  </td>
                  <td>
                    <div style={{ height: 24, display: "flex", gap: 8 }}>
                      <div
                        className={"button-reverse text-normal"}
                        style={{
                          textAlign: "center",
                          width: "100%",
                          maxWidth: 66,
                        }}
                        onClick={() => handleOpenCloseMarket(row)}>
                        <span>{t("order.market")}</span>
                      </div>{" "}
                      <div
                        className={"button-reverse text-normal"}
                        style={{
                          textAlign: "center",
                          width: "100%",
                          maxWidth: 66,
                        }}
                        onClick={() => handleOpenCloseLimit(row)}>
                        {t("order.limit")}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
      {openDialog && (
        <ClosePositionDialogV2
          loading={loadingCancel}
          generalData={rows.find((row) => row.id === positionSelected)}
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          tabActive={tabActive}
          handleChange={(e, newValue) => {
            setTabActive(newValue);
          }}
          tradingRulesCache={tradingRulesCache}
          isCoinM={isCoinM}
          handleChangeLimitPrice={handleChangeLimitPrice}
          handleChangePositionSize={handleChangePositionSize}
          handleConfirm={async (type, position) => {
            setLoadingCancel(true);
            if (type === 1) {
              await handleCloseWithMarketPrice(position as IPositionItem);
            } else {
              await handleCloseWithLimitPrice(position as IPositionItem);
            }
            setLoadingCancel(false);
            handleCloseDialog();
          }}
        />
      )}
      {openEntierPositionDialog && (
        <EntierPositionDialog
          data={currentPosition}
          handleCloseDialog={handleCloseEntierDialog}
          openDialog={openEntierPositionDialog}
          listPosition={rows}
        />
      )}
      <AdjustMarginDialog
        data={currentPosition}
        handleCloseDialog={handleCloseAdjustMarginDialog}
        openDialog={openAdjustMarginDialog}
      />
    </S.TableStyle>
  );
}
