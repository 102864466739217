export enum SocketEvent {
  TradesCreated = 'TradesCreated',
}

export enum ErrorCode {
  E001 = 'Insufficient balance.',
  E002 = 'Risk limit exceed',
  E003 = 'Cancel post only order',
  E004 = 'Cross over liquidation price',
  E005 = 'Cross over bankrupt price',
  E006 = 'Reduce Only Order',
  E007 = 'Cannot lock order',
  E008 = 'Cannot fill FOK order',
}
