import styled from "@emotion/styled";

export const Container = styled.div`
  width: 100%;
  max-width: 450px;
  padding: 8px 4px;
  height: auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
`;
