import { useAppSelector } from "src/store/hooks";
import { createTheme } from "@mui/material/styles";
import { lightTheme, darkTheme } from "src/styles/theme";
import { THEME_MODE } from "src/interfaces/theme";

const useAppTheme = () => {
  const themeMode = useAppSelector((state) => state.theme.themeMode);
  const emotionTheme = themeMode === THEME_MODE.LIGHT ? lightTheme : darkTheme;

  const muiTheme = createTheme({
    palette: {
      mode: themeMode === THEME_MODE.LIGHT ? "light" : "dark",
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent", // backgroundColor Tabs
          },
          indicator: {
            backgroundColor:
              themeMode === THEME_MODE.LIGHT ? "#17181A" : "#FFF",
            height: "1px",
            borderRadius: "4px",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            height: 40,
            color: themeMode === THEME_MODE.LIGHT ? "#8E8E92" : "#8E8E92", // Màu chữ cho từng mode
            "&.Mui-selected": {
              color: themeMode === THEME_MODE.LIGHT ? "#17181A" : "#FFF", // Màu chữ khi được chọn cho từng mode
            },
          },
        },
      },
    },
  });

  return { themeMode, emotionTheme, muiTheme };
};

export default useAppTheme;
