import styled from "@emotion/styled";

export const MobileStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  .container-des {
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    .text-des {
      font-size: 10px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.text1};
    }
    .action-close-all {
      font-size: 12px;
      font-weight: normal;
      color: ${({ theme }) => theme.colors.colorTabActive};
    }
  }
  .container-info {
    display: flex;
    justify-content: space-between;
  }
  .color-buy {
    color: ${({ theme }) => theme.colors.greenChart};
  }
  .color-sell {
    color: ${({ theme }) => theme.colors.errorChart};
  }
  .container-item-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .title {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.text1};
      font-weight: 400;
    }
    .content {
      font-size: 12px;
      color: ${({ theme }) => theme.colors.colorTab};
      font-weight: 400;
      text-align: left;
    }
    .text-right {
      text-align: right;
    }
    .text-left {
      text-align: left !important;
    }
  }

  .list-badges {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 4px;

    .badge {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 2px 4px;
      border-radius: 4px;
      min-width: 31px;
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: white;
        margin: 0 auto;
      }
    }

    .App-positive-value {
      background-color: ${({ theme }) => theme.colors.success100};
      span {
        color: ${({ theme }) => theme.colors.greenChart};
      }
    }
    .App-negative-value {
      background-color: ${({ theme }) => theme.colors.error100};
      span {
        color: ${({ theme }) => theme.colors.errorChart};
      }
    }
    .normal {
      background-color: ${({ theme }) => theme.colors.gray100};
      span {
        color: ${({ theme }) => theme.colors.grayChart};
      }
    }
  }
  .container-list {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    gap: 16px;
    .container-item {
      border-bottom: 1px solid ${({ theme }) => theme.colors.border1};
      padding-bottom: 32px;
      margin-bottom: 24px;
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }
`;
