export const lightTheme = {
  colors: {
    bgPrimary: "#FFF",
    neutral: "#8E8E92",
    colorTab: "#8E8E92",
    colorTabActive: "#03AAC7",
    colorTabActiveDate: "#17181A",
    actionCloseAll: "#F2F4F7",
    success100: "#D1FADF",
    greenChart: "#01BC8D",
    error100: "#FFE2DD",
    errorChart: "#F1493F",
    gray100: "#DFDFDF",
    grayChart: "#17181A",
    border1: "#DFDFDF",
    text1: "#17181A",
    green1: "#00ddb3",
  },
  // nodule chart
  chart: {
    bgContainer: "#FFF",
    colorTime: "#8E8E92",
    colorTimeActive: "#17181A",
    bgTime: "#F2F4F7",
    colorSelectTime: "#17181A",
    bgSelectTime: "#E6E6E6",
    bgSelectTimeActive: "#DBF5F9",
    bgTabTrading: "#F4F5F7",
    colorTabTrading: "#8E8E92",
    colorTabTradingActive: "#17181A",
    bgTabTradingActive: "#FFF",
    bgLine: "#E5E5E5",
  },
  // module orderbook
  orderBook: {
    colorPositive: "#DC2626",
    colorNegative: "#01BC8D",
    bgPriceAsk: "#FDECEB",
    bgPriceBid: "#CCF2E8",
    colorPrice: "#0F172A",
    colorSub: "#8E8E92",
    colorLastPrice: "#17181A",
  },
  orderFrom: {},
};

export const darkTheme = {
  colors: {
    bgPrimary: "#151717",
    neutral: "#8E8E92",
    colorTab: "#8E8E92",
    colorTabActive: "#FFFFFF",
    colorTabActiveDate: "#FFFFFF",
    actionCloseAll: "#252629",
    success100: "#D1FADF",
    greenChart: "#01BC8D",
    error100: "#FFE2DD",
    errorChart: "#F1493F",
    gray100: "#DFDFDF",
    grayChart: "#17181A",
    border1: "#2D2E2F",
    text1: "#FFF",
    green1: "#00ddb3",
  }, // nodule chart
  chart: {
    bgContainer: "#151717",
    colorTime: "#8E8E92",
    colorTimeActive: "#FFF",
    bgTime: "#232323",
    colorSelectTime: "#FFF",
    bgSelectTime: "#2D2E2F",
    bgSelectTimeActive: "#014450",
    bgTabTrading: "#252629",
    colorTabTrading: "#8E8E92",
    colorTabTradingActive: "#FFF",
    bgTabTradingActive: "#38393D",
    bgLine: "#8E8E92",
  },
  // module orderbook
  orderBook: {
    colorPositive: "#01BC8D",
    colorNegative: "#F1493F",
    bgPriceAsk: "#2B1C1B",
    bgPriceBid: "#193A31",
    colorPrice: "#FFF",
    colorSub: "#FFF",
    colorLastPrice: "#FFF",
  },
  orderFrom: {},
};
