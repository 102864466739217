import { useTranslation } from "react-i18next";
import * as S from "./style";
import { CloseAllPosition } from "../../dialog/ClosePositionDialog";
import { useState } from "react";

import { getContractType } from "../../helper";
import { MOBILE_DIMENSION, TypeTrade } from "src/constants/common.constants";
import TooltipText from "src/components/cores/TooltipText";
import { IPositionItem } from "../../PositionRecord";
import useIsMobile from "src/hooks/useIsMobile";

export default function HeaderHistoryDashBoard({
  listTabs,
  rows,
  tab,
  setTab,
  renderValue,
  hideAssetChecked,
  hideOtherPairsChecked,
  handleChange,
}: {
  listTabs: { label: string; value?: number }[];
  tab: number;
  rows: IPositionItem[];
  setTab: (index: number) => void;
  renderValue: (value: number | undefined) => number | "" | undefined;
  hideAssetChecked: boolean;
  hideOtherPairsChecked: boolean;
  handleChange: (event: any) => void;
}) {
  const isMobile = useIsMobile(MOBILE_DIMENSION);
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;

  const [openCloseAllPositionDialog, setOpenCloseAllPositionDialog] =
    useState<boolean>(false);
  const { t } = useTranslation("common");
  const handleCloseAllPositonDialog = () => {
    setOpenCloseAllPositionDialog(false);
  };
  const handleOpenAllPositonDialog = () => {
    if (!rows.length) return;
    setOpenCloseAllPositionDialog(true);
  };

  return (
    <S.BoxContainer>
      <div className={"tab-wrapper"}>
        {listTabs.map(
          (item: { label: string; value?: number }, index: number) => {
            return (
              <div
                key={item?.label}
                onClick={() => setTab(index)}
                className={tab === index ? `tab active` : "tab"}>
                <span>
                  {`${t(item?.label)} ${
                    renderValue(item?.value) !== ""
                      ? "(" + renderValue(item?.value) + ")"
                      : ""
                  }`}
                </span>
              </div>
            );
          }
        )}
      </div>
      {tab !== 4 && !isMobile ? (
        <div style={{ display: "flex", gap: 16 }}>
          <div className={"hidden-btn"}>
            <label className={"container"}>
              <input
                type="checkbox"
                checked={tab === 5 ? hideAssetChecked : hideOtherPairsChecked}
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
            <span className={"hidden-text"}>
              {tab === 5 ? t("order.hide_asset") : t("order.hide_other_pair")}
            </span>
          </div>
          <TooltipText
            text={t("order.tooltip.close_all_position", {
              name: isCoinM ? "COIN-M" : "USDⓈ-M",
            })}>
            <div
              className="action-close-all"
              onClick={handleOpenAllPositonDialog}>
              {t("order.close_all_position")}
            </div>
          </TooltipText>
        </div>
      ) : (
        <div style={{ height: 35 }}></div>
      )}
      <CloseAllPosition
        handleCloseDialog={handleCloseAllPositonDialog}
        openDialog={openCloseAllPositionDialog}
      />
    </S.BoxContainer>
  );
}
