import { formatTime } from "src/helpers/stringFormatter";
import ItemInfo from "../PositionHistory/ItemInfo";
import { ITransactionHistory } from ".";
import RenderTypeOrder from "./RenderType";
import RenderAmount from "./RenderAmount";
import RenderSymbol from "./RenderSymbol";

export default function ItemMobile({
  transaction,
}: {
  transaction: ITransactionHistory;
}) {
  return (
    <div className="container-item">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <ItemInfo
          title={formatTime(transaction.time)}
          description={`Time`}
          textStyle={{ textAlign: "left" }}
        />
        <ItemInfo
          nodeTitle={<RenderTypeOrder transaction={transaction} />}
          description={`Type`}
          textStyle={{ textAlign: "right" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <ItemInfo
          nodeTitle={<RenderAmount transaction={transaction} />}
          description={`Amount`}
          textStyle={{ textAlign: "left" }}
        />
        <ItemInfo
          title={transaction.asset ?? "--"}
          description={`Asset`}
          textStyle={{ textAlign: "right" }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <ItemInfo
          nodeTitle={<RenderSymbol transaction={transaction} />}
          description={`Symbol`}
          textStyle={{ textAlign: "left" }}
        />
      </div>
    </div>
  );
}
