import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TypeTrade, RECORDS_PER_PAGE } from "src/constants/common.constants";
import {
  listTimeSelect,
  SelectTimeValue,
} from "src/features/PositionAndHistory/constant";
import { getContractType } from "src/features/PositionAndHistory/helper";
import { getCookieStorage } from "src/helpers/storage";
import { IFill } from "src/interfaces/fill";
import { getFilledTradesBySymbol } from "src/services/trade";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import * as S from "../PositionHistory/style";
import DatePicker from "src/components/cores/DatePicker/DatePicker";
import { DateRange } from "@material-ui/pickers";
import TableTradeHistory from "./TableTradeHistory";
import { Pagination } from "@mui/material";
import NoData from "../NoData";

export default function TradeHistoryPage({
  hideOtherPairs,
}: {
  hideOtherPairs: boolean;
}) {
  const dispatch = useAppDispatch();
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const currentInstrument = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const [tab, setTab] = useState<number | null>(0);
  const [tabSelectValue, setTabSelectValue] = useState<string | null>(
    SelectTimeValue.ONE_DAY
  );

  const { fills, loading } = useAppSelector((state) => state.tradeHistory);
  const account = useAppSelector((state) => state.account.account);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );

  const [rows, setRows] = useState<IFill[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalPage = useAppSelector((state) => state.tradeHistory.totalPage);
  const changeCheckboxRef = useRef(hideOtherPairs);
  const changeInstrumentRef = useRef(currentInstrument.symbol);

  const selectedDatePickerRef = useRef<DateRange<Date>>([null, null]);
  const [selectedDatePicker, setSelectedDatePicker] = useState<DateRange<Date>>(
    [null, null]
  );
  const [isChangeSelectDatePicker, setIsChangeSelectDatePicker] =
    useState<boolean>(false);
  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const token = getCookieStorage("access_token");
  const isExpired = getCookieStorage("isExpired");
  const isAuthenticated =
    isExpired === "false" && token && token !== "undefined";
  // && account && account[0]?.id;
  const contractType = getContractType();
  const isCoinM = contractType === TypeTrade.COIN_M;
  const { t } = useTranslation("common");

  const handleFormatTime = () => {
    let formatStartTime;
    let formatEndTime;
    if (tabSelectValue === SelectTimeValue.ONE_DAY) {
      formatStartTime = moment().startOf("day").utc().toDate().getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_WEEK) {
      formatStartTime = moment()
        .subtract(1, "weeks")
        .startOf("day")
        .utc()
        .toDate()
        .getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.ONE_MONTH) {
      formatStartTime = moment()
        .subtract(1, "months")
        .startOf("day")
        .utc()
        .toDate()
        .getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    } else if (tabSelectValue === SelectTimeValue.THREE_MONTHS) {
      formatStartTime = moment()
        .subtract(3, "months")
        .startOf("day")
        .utc()
        .toDate()
        .getTime();
      formatEndTime = moment().endOf("day").utc().toDate().getTime();
    }
    return {
      formatStartTime,
      formatEndTime,
    };
  };

  const fetchData = async (page: number) => {
    let isChooseDatePicker = isChangeSelectDatePicker;
    if (!selectedDatePicker[0] || !selectedDatePicker[1]) {
      isChooseDatePicker = false;
    }
    const { formatStartTime, formatEndTime } = handleFormatTime();
    const formatStartDatePicker = moment(selectedDatePicker[0])
      .startOf("day")
      .utc()
      .toDate()
      .getTime();
    const formatEndDatePicker = moment(selectedDatePicker[1])
      .endOf("day")
      .utc()
      .toDate()
      .getTime();

    const params = { page: page, size: RECORDS_PER_PAGE };

    const payload: any = {
      startTime: 0,
      endTime: 0,
      side: "ALL",
      contractType,
    };

    if (hideOtherPairs) {
      payload.symbol = currentInstrument.symbol;
    }

    if (formatStartTime && !isChooseDatePicker) {
      payload.startTime = formatStartTime;
    }
    if (formatEndTime && !isChooseDatePicker) {
      payload.endTime = formatEndTime;
    }
    if (formatStartDatePicker && isChooseDatePicker) {
      payload.startTime = formatStartDatePicker;
    }
    if (formatEndDatePicker && isChooseDatePicker) {
      payload.endTime = formatEndDatePicker;
    }

    await dispatch(getFilledTradesBySymbol({ params, payload }));
  };

  useEffect(() => {
    if (fills) {
      setRows(fills);
    }
  }, [fills, account[0]?.id]);

  useEffect(() => {
    if (isAuthenticated) {
      if (
        changeCheckboxRef.current === hideOtherPairs &&
        changeInstrumentRef.current === currentInstrument.symbol
      ) {
        fetchData(currentPage);
      } else {
        setCurrentPage(1);
        fetchData(1);
      }
      changeCheckboxRef.current = hideOtherPairs;
      changeInstrumentRef.current = currentInstrument.symbol;
    }
  }, [tabSelectValue, hideOtherPairs, currentInstrument]);

  useEffect(() => {
    if (
      !isOpenDatePicker &&
      isChangeSelectDatePicker &&
      selectedDatePicker[0] &&
      selectedDatePicker[1] &&
      isAuthenticated
    ) {
      setTab(null);
      setTabSelectValue(null);
      setCurrentPage(1);
      fetchData(1);
    }
  }, [isOpenDatePicker, isChangeSelectDatePicker, selectedDatePicker]);

  const handleChangeDatePicker = (value: DateRange<Date>) => {
    setSelectedDatePicker(value);
  };

  const checkUserSelectDatePicker = () => {
    if (
      moment(selectedDatePickerRef.current[0]).format("YYYY-MM-DD") !==
        moment(selectedDatePicker[0]).format("YYYY-MM-DD") ||
      moment(selectedDatePickerRef.current[1]).format("YYYY-MM-DD") !==
        moment(selectedDatePicker[1]).format("YYYY-MM-DD")
    ) {
      setIsChangeSelectDatePicker(true);
    } else {
      setIsChangeSelectDatePicker(false);
    }
  };

  const handleOpenDatePicker = () => {
    selectedDatePickerRef.current = selectedDatePicker;
    setIsOpenDatePicker(true);
  };

  const handleCloseDatePicker = () => {
    setIsOpenDatePicker(false);
    checkUserSelectDatePicker();
  };

  useEffect(() => {
    checkUserSelectDatePicker();
  }, [selectedDatePicker]);

  // const handleSearchHistory = () => {
  //   if (isChangeSelectDatePicker) {
  //     setTab(null);
  //     setTabSelectValue(null);
  //   }
  //   setCurrentPage(1);
  //   fetchData(1);
  // };

  const handleReset = () => {
    setTab(0);
    setTabSelectValue(SelectTimeValue.ONE_DAY);
    setIsChangeSelectDatePicker(false);
    setCurrentPage(1);
    fetchData(1);
  };

  return (
    <S.Container>
      <div className="tab-wrapper">
        <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
          {listTimeSelect.map(
            (item: { label: string; value: string }, index: number) => {
              return (
                <div
                  key={item?.label}
                  onClick={() => {
                    setTab(index);
                    setTabSelectValue(item.value);
                    setIsChangeSelectDatePicker(false);
                    setCurrentPage(1);
                  }}
                  className={tab === index ? "tab active" : "tab"}>
                  {t(item?.label)}
                </div>
              );
            }
          )}
          <div className={"text-time"}>{t("order.time")}</div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <DatePicker
            handleChangeDate={handleChangeDatePicker}
            handleOpenDate={handleOpenDatePicker}
            handleCloseDate={handleCloseDatePicker}
            customInput={"input-custom-style"}
            isChangeSelectDatePicker={isChangeSelectDatePicker}
          />
          <div>
            {/* <button className={cx('search-btn')} onClick={handleSearchHistory}>
                  Search
                </button> */}
            <button className={"reset-btn"} onClick={handleReset}>
              {t("order.reset")}
            </button>
          </div>
        </div>
      </div>
      <TableTradeHistory
        instruments={instruments}
        isCoinM={isCoinM}
        loading={loading}
        rows={rows}
        tradingRulesCache={tradingRulesCache}
      />
      {!loading && !rows.length && <NoData />}
      {!loading && totalPage > 1 && (
        <div className={"pagination"}>
          <Pagination
            page={currentPage}
            onChange={(e, page) => {
              setCurrentPage(page);
              fetchData(page);
            }}
            count={totalPage}
            variant="outlined"
            shape="rounded"
            className={"pagination-custom"}
            sx={{
              "& .MuiPaginationItem-root": {
                color: "var(--color-value-1) !important",
              },
              "& .Mui-selected": {
                backgroundColor:
                  "var(--color-background-pagination) !important",
                border: "1px solid var(--color-outline-pagination) !important",
                color: "var(--color-text-pagination) !important",
                borderRadius: "5px !important",
              },
              "& .MuiPagination-ul": {
                flexWrap: "nowrap",
              },
            }}
          />
        </div>
      )}
    </S.Container>
  );
}
