import TooltipNumber from "src/components/cores/Tooltip";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { Instrument } from "src/services/instrument";

export default function Liq({
  instruments,
  position,
  liquidationPrice,
}: {
  position: IPositionItem;
  instruments: Instrument[];
  liquidationPrice: string;
}) {
  const currentInstrument = instruments?.find(
    (instrument) => instrument.symbol === position.symbol
  );
  const decimal = -Math.ceil(
    Math.log10(Number(currentInstrument?.minPriceMovement ?? "0.01"))
  );

  return (
    <TooltipNumber
      characters={11}
      decimal={decimal}
      defaultValue={liquidationPrice}
    />
  );
}
