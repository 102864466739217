import { DateRange } from "@material-ui/pickers";
import { Pagination } from "@mui/material";
import classNames from "classnames/bind";
import React, { useEffect, useRef, useState } from "react";
// import { noData, noDataDark, usdmProfile, usdmProfileDark } from 'src/assets/icon';
import IconSVG from "src/assets/icon/icon.svg";
import Icon from "src/assets/icon/icon.png";
import DatePicker from "src/features/Orders/DatePickerCustom/DatePicker";
import { fakeDataSide, TRADE_TABLE_HEAD } from "../constant";
import { formatOrderEnum } from "src/helpers/numberFormatter";
import OrderTitle from "../OrderTitle";
import styles from "./index.module.scss";
import FilterMobile from "../FilterMobile";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { Instrument } from "src/services/instrument";
import { getFilledTradesBySymbol } from "src/services/trade";
import { IFill } from "src/interfaces/fill";
// import { formatTime } from "src/helpers/stringFormatter";
import { formatTimeDay, formatTimeHour } from "src/helpers/stringFormatter";
import moment from "moment";
import { SelectFilterSymbolOrder } from "../SelectOrderSymbol/index";
import { SelectFilterSideOrder } from "src/features/Orders/SelectOrderSide";
import { useWindowSize } from "src/hooks/useScreen";
import Loading from "src/components/Loading";
import { THEME_MODE } from "src/interfaces/theme";
import { RECORDS_PER_PAGE } from "../OpenOrder/OpenOrder";
import { TradeSide, TypeTrade } from "src/constants/common.constants";
import TooltipNumber, { DECIMAL_TWO } from "src/components/cores/Tooltip";
import { RootState } from "src/store/store";
import { useSelector } from "react-redux";
import {
  formatOrderText,
  getSymbolName,
} from "src/features/PositionAndHistory/helper";
import { useTranslation } from "react-i18next";
import TextNumber from "src/components/cores/TextNumber";
import { BsFillInboxesFill } from "react-icons/bs";

const cx = classNames.bind(styles);

const TradeHistoryPage: React.FC = () => {
  const { t } = useTranslation("common");
  const [theme] = useAppSelector((state) => [state.theme.themeMode]);
  const [tabActive, setTabActive] = useState(0);
  const [dataTable, setDataTable] = useState<IFill[]>([]);
  const [selectedDate, setSelectedDate] = useState<DateRange<Date>>([
    null,
    null,
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const currentInstrument: Instrument | undefined = useAppSelector(
    (state) => state.instrument.currentInstrument
  );
  const currentUser = useAppSelector((state) => state.account.account);
  const { fills, totalPage, loading } = useAppSelector(
    (state) => state.tradeHistory
  );
  const [isReset, setIsReset] = useState<number>(0);
  const [valueSymbolFilter, setValueSymbolFilter] = useState<string>("");
  const [valueSideFilter, setValueSideFilter] = useState<string>("");
  const size = useWindowSize();
  const instruments = useAppSelector((state) => state.instrument.instrument);
  const [listCoin, setListCoin] = useState(instruments);
  const [totalItem, setTotalItem] = useState<number>(1);
  const tradingRulesCache = useAppSelector(
    (state) => state.masterdataFuture.tradingRulesCache
  );
  const [tab, setTab] = useState(TypeTrade.USD_M);

  const [isOpenDatePicker, setIsOpenDatePicker] = useState<boolean>(false);
  const [isChangeSelectData, setIsChangeSelectData] = useState<boolean>(false);
  const selectedDateRef = useRef<DateRange<Date>>([null, null]);

  const checkUserSelectDatePicker = () => {
    if (
      moment(selectedDateRef.current[0]).format("YYYY-MM-DD") !==
        moment(selectedDate[0]).format("YYYY-MM-DD") ||
      moment(selectedDateRef.current[1]).format("YYYY-MM-DD") !==
        moment(selectedDate[1]).format("YYYY-MM-DD")
    ) {
      if (selectedDate[0] && selectedDate[1]) {
        setIsChangeSelectData(true);
      } else {
        setIsChangeSelectData(false);
      }
    } else {
      setIsChangeSelectData(false);
    }
  };

  const handleOpenDatePicker = () => {
    selectedDateRef.current = selectedDate;
  };

  const handleCloseDatePicker = () => {
    checkUserSelectDatePicker();
  };

  useEffect(() => {
    checkUserSelectDatePicker();
  }, [selectedDate]);

  useEffect(() => {
    const list = instruments?.filter((item) =>
      tab === TypeTrade.COIN_M
        ? item.contractType === TypeTrade.COIN_M
        : item.contractType === TypeTrade.USD_M
    );
    setListCoin(list);
  }, [instruments, tab]);

  useEffect(() => {
    // if (fills.length !== 0) {
    //   setTotalItem(totalPage);
    // }
    setTotalItem(totalPage);
  }, [fills, currentUser[0]?.id]);

  useEffect(() => {
    if (selectedDate) {
      setCurrentPage(1);
      setIsChangeSelectData(true);
    }
  }, [selectedDate, tab]);

  const handleFilter = () => {
    setIsChangeSelectData(true);
    setCurrentPage(1);
    setIsReset(0);
  };

  const getDataTradeHistory = () => {
    if (currentInstrument?.symbol && currentUser[0]?.id) {
      if (isOpenDatePicker) return;
      if (!isOpenDatePicker && !isChangeSelectData) return;

      dispatch(
        getFilledTradesBySymbol({
          params: {
            page: currentPage,
            size: RECORDS_PER_PAGE,
          },
          payload: {
            startTime: selectedDate[0]
              ? moment(selectedDate[0]).startOf("day").utc().toDate().getTime()
              : moment()
                  .subtract(3, "months")
                  .startOf("day")
                  .utc()
                  .toDate()
                  .getTime(),
            endTime: selectedDate[1]
              ? moment(selectedDate[1]).endOf("day").utc().toDate().getTime()
              : moment().endOf("day").utc().toDate().getTime(),
            side: valueSideFilter && !isReset ? valueSideFilter : "ALL",
            symbol: valueSymbolFilter && !isReset ? valueSymbolFilter : null,
            contractType: tab,
          },
        })
      );
    }
  };

  const searchFilterMobile = () => {
    setCurrentPage(1);
    setIsReset(0);
    setIsChangeSelectData(true);
    getDataTradeHistory();
  };

  useEffect(() => {
    getDataTradeHistory();
    // if (currentInstrument?.symbol && currentUser[0]?.id) {
    //   if (isOpenDatePicker) return;
    //   if (!isOpenDatePicker && !isChangeSelectData) return;

    //   dispatch(
    //     getFilledTradesBySymbol({
    //       params: {
    //         page: currentPage,
    //         size: RECORDS_PER_PAGE,
    //       },
    //       payload: {
    //         startTime: selectedDate[0]
    //           ? moment(selectedDate[0]).startOf('day').utc().toDate().getTime()
    //           : moment().subtract(3, 'months').startOf('day').utc().toDate().getTime(),
    //         endTime: selectedDate[1]
    //           ? moment(selectedDate[1]).endOf('day').utc().toDate().getTime()
    //           : moment().endOf('day').utc().toDate().getTime(),
    //         side: valueSideFilter && !isReset ? valueSideFilter : 'ALL',
    //         symbol: valueSymbolFilter && !isReset ? valueSymbolFilter : null,
    //         contractType: tab,
    //       },
    //     }),
    //   );
    // }
  }, [
    isOpenDatePicker,
    isChangeSelectData,
    currentInstrument?.symbol,
    currentUser[0]?.id,
    selectedDate,
    valueSymbolFilter,
    valueSideFilter,
    // isReset,
    dispatch,
    currentPage,
    tab,
  ]);

  useEffect(() => {
    if (Object.keys(fills).length === 0) {
      setDataTable([]);
    }
    setDataTable(fills);
  }, [fills, currentUser]);

  useEffect(() => {
    history.pushState(tab, "");
  }, [tab]);

  const renderPrice = (fill: IFill, currentPrice: string) => {
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === fill?.symbol
    );
    const maxFiguresForPrice =
      Number(curTradingRule[0]?.maxFiguresForPrice) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={11}
        decimal={maxFiguresForPrice}
        defaultValue={currentPrice}
      />
    );
  };

  const renderQuantity = (fill: IFill) => {
    const currentIns = listCoin?.find(
      (instrument) => instrument.symbol === fill.symbol
    );
    const curTradingRule = tradingRulesCache?.filter(
      (item) => item.symbol === fill?.symbol
    );
    const maxFiguresForSize =
      Number(curTradingRule[0]?.maxFiguresForSize) || DECIMAL_TWO;

    return (
      <TooltipNumber
        characters={9}
        decimal={tab === TypeTrade.COIN_M ? 0 : maxFiguresForSize}
        symbol={tab === TypeTrade.COIN_M ? "Cont" : currentIns?.rootSymbol}
        defaultValue={fill?.quantity}
      />
    );
  };

  const renderTime = (order: any) => {
    if (!order.createdAt) return "--";
    return (
      <div>
        <div>{formatTimeDay(order.createdAt)}</div>
        <div className={cx("describe")}>{formatTimeHour(order.createdAt)}</div>
      </div>
    );
  };

  const renderFee = (fill: IFill) => {
    const currentInstrument = listCoin?.find(
      (instrument) => instrument?.symbol === fill?.symbol
    );

    // return (
    //   <TooltipNumber
    //     defaultValue={fill.fee}
    //     characters={13}
    //     decimal={8}
    //     symbol={tab === TypeTrade.COIN_M ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
    //   />
    // );

    return (
      <TextNumber
        defaultValue={fill.fee ?? "0"}
        symbol={
          tab === TypeTrade.COIN_M
            ? currentInstrument?.rootSymbol
            : currentInstrument?.quoteCurrency
        }
        isCoinM={false}
      />
    );
  };

  const renderRealizedProfit = (fill: IFill) => {
    const currentInstrument = listCoin?.find(
      (instrument) => instrument?.symbol === fill?.symbol
    );

    if (fill?.tradeSide === TradeSide.BUY) {
      if (Number(fill?.realizedPnlOrderBuy)) {
        // return (
        //   <TooltipNumber
        //     defaultValue={fill.realizedPnlOrderBuy}
        //     characters={20}
        //     decimal={8}
        //     symbol={tab === TypeTrade.COIN_M ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
        //   />
        // );

        return (
          <TextNumber
            defaultValue={fill.realizedPnlOrderBuy ?? "0"}
            symbol={
              tab === TypeTrade.COIN_M
                ? currentInstrument?.rootSymbol
                : currentInstrument?.quoteCurrency
            }
            isCoinM={false}
          />
        );
      } else {
        return "-";
      }
    } else {
      if (Number(fill?.realizedPnlOrderSell)) {
        // return (
        //   <TooltipNumber
        //     defaultValue={fill.realizedPnlOrderSell}
        //     characters={20}
        //     decimal={8}
        //     symbol={tab === TypeTrade.COIN_M ? currentInstrument?.rootSymbol : currentInstrument?.quoteCurrency}
        //   />
        // );

        return (
          <TextNumber
            defaultValue={fill.realizedPnlOrderSell ?? "0"}
            symbol={
              tab === TypeTrade.COIN_M
                ? currentInstrument?.rootSymbol
                : currentInstrument?.quoteCurrency
            }
            isCoinM={false}
          />
        );
      } else {
        return "-";
      }
    }
  };

  const handleChangeDate = (value: DateRange<Date>) => {
    setSelectedDate(value);
  };

  return (
    <div className={cx("orders-wrapper")}>
      <div className={cx("open-order-details")}>
        {/* {TAB_DETAILS.map((item, i) => (
          <div
            key={i}
            className={cx('tab-details', tabActive === i && 'tab-details-active')}
            onClick={() => setTabActive(i)}
          >
            {item}
          </div>
        ))} */}
        <div className={cx("tabs-area")}>
          <div
            onClick={() => {
              setTab(TypeTrade.USD_M);
              setIsReset(1);
            }}
            className={cx(
              "tab-details",
              tab === TypeTrade.USD_M && "tab-details-active"
            )}
          >
            USDⓈ-M
          </div>
          <div
            onClick={() => {
              setTab(TypeTrade.COIN_M);
              setIsReset(1);
            }}
            className={cx(
              "tab-details",
              tab === TypeTrade.COIN_M && "tab-details-active"
            )}
          >
            COIN-M
          </div>
        </div>

        {tabActive === 0 && (
          <>
            <div className={cx("filter-area")}>
              <div
                className={cx(
                  size[0] <= 1440 ? "filter-row-responsive" : "filter-row"
                )}
              >
                <SelectFilterSymbolOrder
                  isReset={isReset}
                  handleFilter={handleFilter}
                  placeHolder={t("order.symbol")}
                  customSelect={cx("width")}
                  className={cx("select-filter")}
                  isSearch
                  setValueSymbolFilter={setValueSymbolFilter}
                  label={""}
                  dataList={listCoin}
                  width={200}
                />
                <SelectFilterSideOrder
                  isReset={isReset}
                  handleFilter={handleFilter}
                  dataList={fakeDataSide}
                  placeHolder={t("order.side")}
                  customSelect={cx("width")}
                  className={cx("select-filter1")}
                  setValueSideFilter={setValueSideFilter}
                  width={200}
                />
              </div>

              <div
                className={cx(
                  size[0] <= 1440 ? "filter-date-responsive" : "filter-date"
                )}
              >
                <DatePicker
                  isReset={isReset}
                  setIsReset={setIsReset}
                  handleChangeDate={handleChangeDate}
                  setIsOpenDatePicker={setIsOpenDatePicker}
                  handleOpenDate={handleOpenDatePicker}
                  handleCloseDate={handleCloseDatePicker}
                />
              </div>
              <div className={cx("filter-reset")}>
                <button
                  className={cx("btn-reset")}
                  onClick={() => {
                    setIsReset(1);
                    setCurrentPage(1);
                  }}
                >
                  {t("order.reset")}
                </button>
              </div>
            </div>
            <div className={cx("filter-area-mobile")}>
              <SelectFilterSymbolOrder
                isReset={isReset}
                handleFilter={handleFilter}
                placeHolder={t("order.symbol")}
                customSelect={cx("width")}
                className={cx("select-filter")}
                isSearch
                setValueSymbolFilter={setValueSymbolFilter}
                label={""}
                dataList={listCoin}
                width={200}
              />
              <FilterMobile
                onReset={() => {
                  setIsReset(2);
                }}
                onSearch={searchFilterMobile}
              >
                <div className={cx("filter-mobile-content")}>
                  <SelectFilterSymbolOrder
                    isReset={isReset}
                    handleFilter={handleFilter}
                    placeHolder={t("order.symbol")}
                    customSelect={cx("width")}
                    className={cx("select-filter")}
                    isSearch
                    setValueSymbolFilter={setValueSymbolFilter}
                    label={""}
                    dataList={listCoin}
                    width={200}
                  />
                  <SelectFilterSideOrder
                    isReset={isReset}
                    handleFilter={handleFilter}
                    dataList={fakeDataSide}
                    placeHolder={t("order.side")}
                    customSelect={cx("width")}
                    className={cx("select-filter1")}
                    setValueSideFilter={setValueSideFilter}
                    width={200}
                  />
                  <DatePicker
                    isReset={isReset}
                    setIsReset={setIsReset}
                    handleChangeDate={handleChangeDate}
                    setIsOpenDatePicker={setIsOpenDatePicker}
                    handleOpenDate={handleOpenDatePicker}
                    handleCloseDate={handleCloseDatePicker}
                  />
                </div>
              </FilterMobile>
            </div>
            {
              <div style={{ position: "relative" }}>
                <div className={cx("table-content")}>
                  <div
                    className={cx("no-data")}
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "40%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {!loading && dataTable.length === 0 && (
                      <>
                        <BsFillInboxesFill
                          fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                          style={{ zoom: 5 }}
                        />
                        <div className={cx("no-data-desc")}>
                          {t("order.no_data")}
                        </div>
                      </>
                    )}

                    {loading && <Loading />}
                  </div>

                  <table className={cx("dex_table")}>
                    <thead>
                      <tr>
                        {TRADE_TABLE_HEAD.map((head, index) => {
                          return (
                            <th key={index}>
                              {t(head.label)}
                              {head.icon && <img src={head.icon} />}
                            </th>
                          );
                        })}
                      </tr>
                    </thead>
                    <tbody
                      className={cx("dex_table__scrollbar", "overflow-tbody")}
                      style={{ maxHeight: 210 }}
                    >
                      {loading && <div style={{ height: 340 }}></div>}
                      {!loading && dataTable.length === 0 && (
                        <div style={{ height: 340 }}></div>
                      )}

                      {!loading && dataTable.length !== 0 && (
                        <>
                          {dataTable.map((data, index) => (
                            <tr className={cx("row-content")} key={index}>
                              <td className={cx("text-value")}>
                                {renderTime(data)}
                              </td>
                              <td className={cx("text-value")}>
                                <div className={cx("symbol")}>
                                  <div>
                                    {data.symbol
                                      ? getSymbolName(data.symbol)
                                      : "-"}
                                  </div>
                                  <div className={cx("describe")}>
                                    {t("order.perpetual")}
                                  </div>
                                </div>
                              </td>
                              <td
                                className={cx(
                                  data.tradeSide == TradeSide.BUY
                                    ? "value-buy"
                                    : "value-sell"
                                )}
                              >
                                {data.tradeSide
                                  ? t(formatOrderText(data.tradeSide))
                                  : "-"}
                              </td>
                              <td className={cx("text-value")}>
                                {data.price
                                  ? renderPrice(data, data.price ?? "0")
                                  : "-"}
                              </td>
                              <td className={cx("text-value")}>
                                {data.quantity ? renderQuantity(data) : "-"}
                              </td>
                              <td className={cx("text-value")}>
                                {renderFee(data)}
                              </td>
                              <td className={cx("text-value")}>
                                {data.liquidity
                                  ? t(formatOrderText(data.liquidity))
                                  : "-"}
                              </td>
                              <td className={cx("text-value")}>
                                {renderRealizedProfit(data)}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className={cx("table-content-mobile")}>
                  <div
                    className={cx("no-data")}
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "40%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {!loading && dataTable.length === 0 && (
                      <>
                        <BsFillInboxesFill
                          fill={theme === THEME_MODE.LIGHT ? "black" : "white"}
                          style={{ zoom: 2 }}
                        />
                        <div className={cx("no-data-desc")}>
                          {t("order.no_data")}
                        </div>
                      </>
                    )}

                    {loading && <Loading />}
                  </div>
                  {dataTable.map((data: any, index: number) => (
                    <div className={cx("content-item-mobile")} key={index}>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[0].label)}
                        </div>
                        <div className={cx("text-value")}>
                          {renderTime(data)}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[1].label)}
                        </div>
                        <div className={cx("text-value")}>
                          <div className={cx("symbol")}>
                            <div>{getSymbolName(data.symbol ?? "")}</div>
                            <div className={cx("describe")}>
                              {t("order.perpetual")}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[2].label)}
                        </div>
                        <div
                          className={cx(
                            data.tradeSide == TradeSide.BUY
                              ? "value-buy"
                              : "value-sell"
                          )}
                        >
                          {data.tradeSide
                            ? t(formatOrderText(data.tradeSide))
                            : "-"}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[3].label)}
                        </div>
                        <div className={cx("text-value")}>
                          {data.price
                            ? renderPrice(data, data.price ?? "0")
                            : "-"}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[4].label)}
                        </div>
                        <div className={cx("text-value")}>
                          {data.quantity ? renderQuantity(data) : "-"}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[5].label)}
                        </div>
                        <div className={cx("text-value")}>
                          {renderFee(data)}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[6].label)}
                        </div>
                        <div className={cx("text-value")}>
                          {data.liquidity
                            ? t(formatOrderText(data.liquidity))
                            : "-"}
                        </div>
                      </div>
                      <div className={cx("item-box")}>
                        <div className={cx("label")}>
                          {t(TRADE_TABLE_HEAD[7].label)}
                        </div>
                        <div className={cx("text-value")}>
                          {renderRealizedProfit(data)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {!loading && totalItem > 1 && (
                  <div className={cx("pagination")}>
                    <Pagination
                      page={currentPage}
                      onChange={(e, page) => setCurrentPage(page)}
                      count={totalItem}
                      variant="outlined"
                      shape="rounded"
                      className={cx("pagination-custom")}
                      sx={{
                        "& .MuiPaginationItem-root": {
                          color: "var(--color-value-1) !important",
                        },
                        "& .Mui-selected": {
                          backgroundColor:
                            "var(--color-background-pagination) !important",
                          border:
                            "1px solid var(--color-outline-pagination) !important",
                          color: "var(--color-text-pagination) !important",
                          borderRadius: "5px !important",
                        },
                        "& .MuiPagination-ul": {
                          flexWrap: "nowrap",
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            }
          </>
        )}
      </div>
    </div>
  );
};
export default TradeHistoryPage;
