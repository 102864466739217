import { useTranslation } from "react-i18next";
import { getSymbolName } from "src/features/PositionAndHistory/helper";
import { IPositionItem } from "src/features/PositionAndHistory/PositionRecord";
import { getValueClassName } from "src/helpers/numberFormatter";
import { Instrument } from "src/services/instrument";

export default function SymBolCol({
  row,
  currentInstrument,
}: {
  row: IPositionItem;
  currentInstrument: Instrument;
}) {
  const { t } = useTranslation("common");
  const textPosition =
    getValueClassName(row.currentQty) === "App-positive-value"
      ? "Long"
      : "Short";
  return (
    <td
      className={"column-symbol"}
      onClick={() => {
        if (currentInstrument.symbol === row.symbol) return;
        window.location.replace(`/futures/${row?.contractType}/${row.symbol}`);
      }}>
      <div
        className={`column-color ${getValueClassName(row.currentQty)}`}></div>
      <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <div>
          <span className={"text-coin"}>{getSymbolName(row.symbol)}</span>
        </div>
        <div
          className={`text-position ${getValueClassName(row.currentQty).replace(
            "App",
            "Text"
          )}`}>
          {textPosition + " "}
          {(row.leverage && Number(row.leverage).toFixed().concat("x")) || "-"}
          {row.isCross
            ? ` - (${t("order.cross")})`
            : ` - (${t("order.isolated")})`}
        </div>
      </div>
    </td>
  );
}
